<template>
    <div>
        <div class="container-top">
            <div class="box1">
                <p class="heading">{{ programme_details.category_name}}</p>
                <!-- <p class="subtitle">{{ programme_details.category_name}}</p> -->
            </div>
        </div>
        <div class="container-middle" v-if="programme_details.application_request_status == 1">
            <!-- <img class="side-img" :src="img_url + programme_details.image" alt=""> -->
            <!-- <div class="content">
                <div v-html="programme_details.description"></div>
            </div> -->
        </div>
        <div class="container-middlem" v-if="programme_details.application_request_status == 0">
          <center>
            <button class="btn btn-default" style="text-transform:uppercase">application closed </button>
          </center>
      </div>
      <div class="container-down">
          <router-link to="/dashboard"><button class="back"><i class="fa fa-arrow-left"></i> back</button></router-link> 
          <div v-if="programme_details.application_request_status == 1">
            <span v-if="app_status == 4">
               <button style="text-transform: capitalize;">you have completed your application process </button>
            </span>
            <span v-else-if="app_status < 4 && app_status > 1">
              <router-link :to="'/application/' + programme_details.programme_categoryid"> <button class="get-started">continue  application <i class="fa fa-arrow-right"></i></button></router-link>
           </span>
            <span v-else-if="app_status == 10">
                <router-link :to="'/application/' + programme_details.programme_categoryid"> <button class="get-started">start application <i class="fa fa-arrow-right"></i></button></router-link>
            </span>
          </div>
          <div v-if="programme_details.application_request_status == 0">
            <span v-if="fee_status == 1">
              <div v-if="admission_status >= 1 && admission_status < 3">
                <router-link to="#"><button style="text-transform: capitalize;">admission in progress </button></router-link>
              </div>
              <div v-if="admission_status == 3">
                <router-link :to="'/programmes/course/' + programme_details.programme_categoryid"> <button class="get-started">start course <i class="fa fa-arrow-right"></i></button></router-link>
              </div>
              <div v-if="admission_status == 4">
                <button style="text-transform: capitalize;">you have been accepted into the programme, <router-link to="/fees">click to accept admission </router-link></button>
              </div>
            </span>
            <span v-if="fee_status == 0">
               <router-link :to="'/application/' + programme_details.programme_categoryid"><button style="text-transform: capitalize;">you have not paid your application fee. please click here to pay. </button></router-link>
            </span>
          </div>
      </div>
        <page-loader :is-loading="isLoading"></page-loader>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        
    </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "Programme",
    components: {
      PageLoader
    },

    data(){
        return{
            email_address: '',
            phone_number: '',
            user_id: '',
            firstname: '',
            lastname: '',

            isLoading: false,
            mba_programme: '',
            certificate_programme: '',
            advance_programme: '',

            fee_status: '',
            app_status: '',
            school_fee_status: '',
            admission_status: '',

            programme_details: '',
            img_url: process.env.IMG_URL,

        };
    },

    beforeMount(){

    },
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");

        const appID = this.$route.params.appid
        this.fetch_programme_details(appID)
        this.check_application_feepayment_status(this.user_id)
    },
    created(){

    },
    computed:{
        
    },

    methods:{

         async fetch_programme_details(application_id){
          this.isLoading = true
           const response = await this.$http.post('/fetch-category-byid', {
            categoryid: application_id,
          })
          this.isLoading = false
          this.programme_details = response.data.categories[0]
        },

        async check_application_feepayment_status(userid){
           const response = await this.$http.post('/fetch-admission-status', {
            user_id: userid,
          })

          // if(response.data.fee_status == 2){
          //   alert("                          This programme has been closed!")
          //   this.$router.push("/dashboard")
          //   return
          // }

          this.fee_status = response.data.fee_status
          this.app_status = response.data.app_status
          this.admission_status = response.data.admissionStatus
          this.school_fee_status = response.data.school_fee_status

        },
    }

}
</script>

<style lang="scss" scoped>
:root {
  --font-family: 'Poppins', 'DM Sans';
}
.side-img{
  height: 300px;
  margin-right: 1.5rem;
}
.container-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
}
.box1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
    border-radius: 4px;
    font-family: var(--font-family);

}

.heading{
  padding-right: 0.4rem;
  font-size: 40px;
  font-weight: 700;
  color: #ff4d63;
}
.subtitle{
    padding-right: 0.4rem;
    font-size: 14px;
    color: #ff4d63;
    line-height: 1.3px;
  }

.container-middle{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    
    img{
        width: 100%;
        padding: 0.4rem;
    }
}
.content{
    display: flex;
    flex-direction: column;
    //align-items: center;
}
.admission-process{
    font-size: 16px;
    font-family: var(--font-family);
    text-align: justify;
}
.qualification{
    font-size: 16px;
    font-family: var(--font-family);
    text-align: justify;
}
.container-down{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 10px;
}
.back{
    width: 250px;
    height: 50px;
    background: #170e0fac;
    margin-right: 6px;
    font-family: var(--font-family);
    color: #fff;
    text-transform: uppercase;
}
.back:hover{
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
}
.btn-danger{
    width: auto;
    height: 50px;
    background: green;
    font-family: var(--font-family);
    text-transform: uppercase;
    color: #fff;
}
.get-started{
    width: 220px;
    height: 50px;
    background: #ff4d63;
    font-family: var(--font-family);
    text-transform: uppercase;
    color: #fff;
}
.get-started:hover{
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
}

@media screen and (max-width: 768px) {
  .side-img{
    display: none;
  }
  .heading{
    margin-top: -50px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
  }
  .get-started{
    width: 170px;
    font-size: 12px;
   // margin-right: 10px;
}
.back{
  width: 150px;
  font-size: 12px;
  //margin-left: 10px;
}
  .welcome{
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #170e0fac;
  }
  .content-center {
    margin: 20px;
  }
  .container-down{
    width: 100%;
    padding: 1.2rem;
}

  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: var(--font-family);
      font-weight: 550;
      font-size: 8px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: var(--font-family);
      font-size: 12px;
    }
  }

  .box1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .box2 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .box3 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    margin-top: -10px;
    color: rgb(237, 138, 108);
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}
</style>