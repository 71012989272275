import Vue from 'vue'
import VueRouter from 'vue-router'
import SignupPage from '../views/SignupPage.vue'
import SigninPage from '../views/SigninPage.vue'
import Dashboard from '../views/DashboardPage.vue'
import HomeView from '../views/HomeView.vue'
import Programme from '../views/ProgrammePage.vue'
import MbaApplication from '../views/MbaApplicationPage.vue'
import PaymentPlan from '../views/PaymentPlanPage.vue'
import FeesPage from '../views/FeesPage.vue'
import CoursePage from '../views/CoursesPage.vue'
import CourseTitlePage from '../views/CourseTitlePage.vue'
import ViewCoursePage from '../views/ViewCoursePage.vue'
import CourseLessons from '../views/CourseLessonsPage.vue'
import TakeCoursePage from '../views/TakeCoursePage.vue'
import LessonDashboard from '../views/LessonDashboard.vue'
import CourseDetailPage from '../views/CourseDetailPage.vue'
import CertificateCourse from '../views/CertificateCourse.vue'
import ViewApplicationPage from '../views/ViewApplicationPage.vue'
import CategoryPage from '../views/ProgrammeCategory.vue'
import ApplicationRequest from '../views/ApplicationRequest.vue'
import ApptitudeTestType from '../views/ApptitudeTestType.vue'
import TestQuestions from '../views/TestQuestions.vue'
import TestAnswers from '../views/TestAnswers.vue'
import EssayQuestions from '../views/EssayQuestions.vue'
import CreateCourseCategory from '../views/CreateCourseCategory.vue'
import CreateCourseModule from '../views/CreateCourseModule.vue'
import CreateCourseLesson from '../views/CreateCourseLessons.vue'
import CreateLessonObjective from '../views/CreateLessonObjective.vue'
import ViewUsersCourse from '../views/ViewUsersCourse.vue'
import ViewEssayWriting from '../views/ViewEssayWriting.vue'
import ViewAcademicRecord from '../views/ViewAcademicRecord.vue'
import ViewProfessionalCertificate from '../views/ViewProfessionalCertificate.vue'
import ViewProfessionalMembership from '../views/ViewProfessionalMembership.vue'
import ViewWorkExperience from '../views/ViewWorkExperience.vue'
import ViewLeadershipExperience from '../views/ViewLeadershipExperience.vue'
import ViewAwards from '../views/ViewAwards.vue'
import SendAdmissionLetter from '../views/SendAdmissionLetter.vue'
import SendRejectionLetter from '../views/SendRejectionLetter.vue'
import PaymentSuccessful from '../views/PaymentSuccessful.vue'
import PaymentSuccessful2 from '../views/PaymentSuccessful2.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import EditCourseCategory from '../views/EditCourseCategory.vue'
import EditCourseModule from '../views/EditCourseModule.vue'
import EditCourseLessons from '../views/EditCourseLessons.vue'

Vue.use(VueRouter)

// parse jwt token
function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function beforeRouteEnter (to, from, next) {
  try{
  if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
    const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
    if (jwtPayload.exp < Date.now()/1000) {
        // token expired
        //deleteTokenFromLocalStorage();
        next("/");
    }
    next();
  } else {
    next("/");
  }
  }catch (err) {
    console.log(err);
  }
}

const routes = [
  {
    path: '/',
    name: 'SigninPage',
    component: SigninPage
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/dashboard',
        name: 'HomeView',
        beforeEnter : beforeRouteEnter,
        component: HomeView,
        meta: {
          secure:true,
        }
      },    
      {
        path: '/programme/:title/:appid/:category_name',
        name: 'Programme',
        beforeEnter : beforeRouteEnter,
        component: Programme,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/programme/:appid/:category_name',
        name: 'CertificateCourse',
        beforeEnter : beforeRouteEnter,
        component: CertificateCourse,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/application/:title',
        name: 'MbaApplication',
        beforeEnter : beforeRouteEnter,
        component: MbaApplication,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/fees',
        name: 'FeesPage',
        beforeEnter : beforeRouteEnter,
        component: FeesPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/pay-fees',
        name: 'PaymentPlan',
        beforeEnter : beforeRouteEnter,
        component: PaymentPlan,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/payment-successful',
        name: 'PaymentSuccessful',
        beforeEnter : beforeRouteEnter,
        component: PaymentSuccessful,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/fee-payment-successful',
        name: 'PaymentSuccessful2',
        beforeEnter : beforeRouteEnter,
        component: PaymentSuccessful2,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/programmes/course/:title',
        name: 'CourseTitlePage',
        beforeEnter : beforeRouteEnter,
        component: CourseTitlePage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/programmes/module/:title',
        name: 'CoursePage',
        beforeEnter : beforeRouteEnter,
        component: CoursePage,
        meta: {
          secure:true,
        }
      },  
      {
        path: '/view/course/:title',
        name: 'ViewCoursePage',
        beforeEnter : beforeRouteEnter,
        component: ViewCoursePage,
        meta: {
          secure:true,
        }
      },    
      {
        path: '/course-detail/:title',
        name: 'CourseDetailPage',
        beforeEnter : beforeRouteEnter,
        component: CourseDetailPage,
        meta: {
          secure:true,
        }
      },   
      {
        path: '/course/:title',
        name: 'CourseLessons',
        beforeEnter : beforeRouteEnter,
        component: CourseLessons,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/view-applications',
        name: 'ViewApplicationPage',
        beforeEnter : beforeRouteEnter,
        component: ViewApplicationPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/programme-category',
        name: 'CategoryPage',
        beforeEnter : beforeRouteEnter,
        component: CategoryPage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/app-request',
        name: 'ApplicationRequest',
        beforeEnter : beforeRouteEnter,
        component: ApplicationRequest,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/test-type',
        name: 'ApptitudeTestType',
        beforeEnter : beforeRouteEnter,
        component: ApptitudeTestType,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/test-questions',
        name: 'TestQuestions',
        beforeEnter : beforeRouteEnter,
        component: TestQuestions,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/test-answers/:id',
        name: 'TestAnswers',
        beforeEnter : beforeRouteEnter,
        component: TestAnswers,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/essay-questions',
        name: 'EssayQuestions',
        beforeEnter : beforeRouteEnter,
        component: EssayQuestions,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/create-course-category',
        name: 'CreateCourseCategory',
        beforeEnter : beforeRouteEnter,
        component: CreateCourseCategory,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/create-course-module',
        name: 'CreateCourseModule',
        beforeEnter : beforeRouteEnter,
        component: CreateCourseModule,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/add-lessons',
        name: 'CreateCourseLesson',
        beforeEnter : beforeRouteEnter,
        component: CreateCourseLesson,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/add-lessons-objective',
        name: 'CreateLessonObjective',
        beforeEnter : beforeRouteEnter,
        component: CreateLessonObjective,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/students-courses',
        name: 'ViewUsersCourse',
        beforeEnter : beforeRouteEnter,
        component: ViewUsersCourse,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/essay-writing',
        name: 'ViewEssayWriting',
        beforeEnter : beforeRouteEnter,
        component: ViewEssayWriting,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/academic-record/:appid',
        name: 'ViewAcademicRecord',
        beforeEnter : beforeRouteEnter,
        component: ViewAcademicRecord,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/professional-certificate/:appid',
        name: 'ViewProfessionalCertificate',
        beforeEnter : beforeRouteEnter,
        component: ViewProfessionalCertificate,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/professional-membership/:appid',
        name: 'ViewProfessionalMembership',
        beforeEnter : beforeRouteEnter,
        component: ViewProfessionalMembership,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/work-experience/:appid',
        name: 'ViewWorkExperience',
        beforeEnter : beforeRouteEnter,
        component: ViewWorkExperience,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/leadership-experience/:appid',
        name: 'ViewLeadershipExperience',
        beforeEnter : beforeRouteEnter,
        component: ViewLeadershipExperience,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/awards/:appid',
        name: 'ViewAwards',
        beforeEnter : beforeRouteEnter,
        component: ViewAwards,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/send-admission-letter/:appid',
        name: 'SendAdmissionLetter',
        beforeEnter : beforeRouteEnter,
        component: SendAdmissionLetter,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/send-rejection-letter/:appid',
        name: 'SendRejectionLetter',
        beforeEnter : beforeRouteEnter,
        component: SendRejectionLetter,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/edit-course-category/:categoryid',
        name: 'EditCourseCategory',
        beforeEnter : beforeRouteEnter,
        component: EditCourseCategory,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/edit-course-module/:moduleid',
        name: 'EditCourseModule',
        beforeEnter : beforeRouteEnter,
        component: EditCourseModule,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/edit-lessons/:lessonid',
        name: 'EditCourseLessons',
        beforeEnter : beforeRouteEnter,
        component: EditCourseLessons,
        meta: {
          secure:true,
        }
      }, 
      
    ]
  },

  {
    path: '/course/start-lesson/:title',
    name: 'LessonDashboard',
    component: LessonDashboard,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/course/start-lesson/:title',
        name: 'TakeCoursePage',
        beforeEnter : beforeRouteEnter,
        component: TakeCoursePage,
        meta: {
          secure:true,
        }
      }, 
      {
        path: '/course/lesson/:completed/:title',
        name: 'TakeCoursePage',
        beforeEnter : beforeRouteEnter,
        component: TakeCoursePage,
        meta: {
          secure:true,
        }
      }, 
    ]
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
