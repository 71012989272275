<template>
    <div style="background: #e0dfdf11">
        <p>&nbsp;</p>
               
        <div class="top-header"><hr width="29%"> LESSONS <hr width="29%"></div>
        <div class="row course-module">
            <div class="col-sm-8">
                <router-link :to="'/programmes/module/' + programme_moduleid">
                    <div>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i> BACK
                    </div>
                </router-link>
                <br>
                <div class="modules">
                    <div class="module-top"> 
                        <div class="module-lessons">
                           {{course_module.course_module_title}}
                        </div>                       
                    </div>
                    <div class="module-container" v-for="(data, index) in course_lessons" :key="index">
                        <div class="module-subcontainer">
                            <div class="module-left">
                                <div class="module-center"> 
                                    <div class="module-lessons-center">
                                     LESSON {{ data.course_lesson_number }}:  {{ data.course_lesson_title}}
                                    </div>        
                                    <div class="module-lessons-center">
                                      <!-- {{ data.course_lesson_title}} -->
                                    </div>              
                                </div>
                                <!-- <div class="module-footer"> 
                                    <div class="course-object-title">Course Objectives</div>
                                    <div class="course-object-content">
                                        <ol>
                                            <l v-for="(i, index) in lessonObjectives" :key="index">
                                                <span v-if="i.course_lesson_id == data.course_lessonid ">
                                                    {{ i.course_objectives }} <br>
                                                </span>
                                            </l>
                                        </ol>
                                    </div>
                                </div>   -->
                            </div>
                            <router-link :to="'/course/start-lesson/' + data.course_lessonid" v-if="data.start_course == 1">
                                <div class="module-right">
                                    <button class="resume-start">resume <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </div>
                            </router-link>
                            <router-link :to="'/course/lesson/completed/' + data.course_lessonid" v-if="data.start_course == 2">
                                <div class="module-right">
                                    <button class="end-course"> completed </button>
                                </div>
                            </router-link>
                            <router-link :to="'/course/start-lesson/' + data.course_lessonid" v-else-if="data.start_course != 1">
                                <div class="module-right">
                                    <button class="btn-start">start <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                                </div>
                            </router-link>
                        </div>
                    </div>                    
                </div>
            </div>

            <div class="col-sm-4">
                <div class="module-container-left">
                    <div class="top-header2"><hr width="29%"> related courses  <hr width="29%"></div>
                    <div class="related-course">
                        <ul>
                            <li>
                                Course1
                            </li>
                            <li>
                                Course2
                            </li>
                            <li>
                                Course3
                            </li>
                        </ul>
                    </div>

                    <div class="top-header2"><hr width="29%"> resources  <hr width="29%"></div>
                    <div class="related-course">
                        <ul>
                            <li>
                                Resource1
                            </li>
                            <li>
                                Resource2
                            </li>
                            <li>
                                Resource3
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "TakeCoursePage",
    components: {
        PageLoader,
    },

    data(){
        return{
            isLoading: false,
            course_lessons: '',
            course_module: '',
            user_id: '',
            userLessons: '',
            lessonObjectives: [],
            programme_moduleid: '',
        }
    },
    beforeMount(){

    },
    mounted() {
        this.user_id = localStorage.getItem("userid");
        this.programme_moduleid = localStorage.getItem("programme_moduleid");
        this.fetch_programme_lesson(this.$route.params.title, this.user_id)
    },
    computed: {

    },
    created(){

    },
    methods: {
        async fetch_programme_lesson(title, userid){
        this.isLoading = true;
        const response = await this.$http.post('/fetch-lessons-byid', {
            programme_id: title,
            userid: userid
        })
        this.isLoading = false;
        this.course_lessons = response.data.lessons;
        this.course_module = response.data.moduleHead[0];
        this.userLessons = response.data.userLessons;
        this.lessonObjectives = response.data.obj
        },
    }
}
</script>
<style lang="scss" scoped>
:root {
    //--font-family: 'Poppins', 'DM Sans';
    --font-family: 'DM Sans';
  }
  
a{
    text-decoration: none;
    color: #ff4d63;
}
.top-header{
    display: flex; 
    flex-direction:row; 
    align-items: center; 
    justify-content: space-between;
    font-size: 20px; 
    color:#ccc; 
    font-weight:800; 
    width: 100%;
    padding: 0.9rem;
}
.course-module{
    display: flex;
    flex-direction: row;
    justify-content: left;
    //align-items: center;
    padding: 0.9rem;

    a{
        color: #ff4d63;
    }
}
.modules{
    width: 100%;
    //height: 100%;
    border-radius: 4px;
    border-top-right-radius: solid 1px #ff4d63;;
    border-top-left-radius: solid 1px #ff4d63;;
    border: solid 1px #ccc;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
    margin-bottom: 10px;
}
.module-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    background: #ff4d63;
    border: solid 0px #ff4d63;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    //height: 40px;
    padding: 10px;
}
.module-container{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    background: #fff;
    width: 100%;
    height: auto;
    padding: 0.4rem;
    border-bottom: solid 2px #ccc;
}
.module-subcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.btn-start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    height: 30px;
    padding: 0.4rem;
    border: solid 1px #ff4d63;
    border-radius: 4px;
}
.resume-start {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    height: 30px;
    padding: 0.4rem;
    border: solid 1px #2b741f;
    color: #2b741f !important;
    border-radius: 4px;
}
.end-course{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    height: 30px;
    padding: 0.4rem;
    border: solid 1px #1f2274;
    color: #1f2274 !important;
    border-radius: 4px;
}
.module-center{
    display: flex;
    flex-direction: column;
    justify-content: left;
    //align-items: center;
    font-family: var(--font-family);
    width: 100%;
    padding: 0.4rem;
}
.module-footer{
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-family: var(--font-family);
    width: 100%;
    padding: 0.4rem;
}
.module-lessons{
    font-size: 18px;
    color: #fff;
    font-weight: 800;
}
.module-lessons-center{
    font-size: 17px;
    color: #646161;
    font-weight: 600;
    width: auto;
    font-family: var(--font-family);
}
.course-object-title{
    font-family: var(--font-family);
    font-size: 10px;
    margin-top: -10px;
    color: #221f1fa2;
    text-transform: uppercase;
}
.course-object-content{
    font-family: var(--font-family);
    font-size: 10px;
    color: #221f1fa2;
}
.module-lessons-footer{
    font-size: 16px;
    color: #646161;
    font-weight: 800;
}
.module-container-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.9rem;
    font-family: var(--font-family);
    font-size: 15px;

    a{
        color: #ff4d63;
    }
}
.top-header2{
    display: flex; 
    flex-direction:row; 
    align-items: center; 
    justify-content: left;
    font-size: 14px; 
    text-transform: capitalize;
    color:#716a6a; 
    font-weight:800; 
    width: 100%;
    padding: 0.9rem;
    font-family: var(--font-family);
    font-size: 15px;
}
.related-course{
    font-family: var(--font-family);
    font-size: 13px;
}
@media screen and (max-width: 768px) {
    a{
        text-decoration: none;
        color: #ff4d63;
        font-size: 12px;
    }
    .module-container{
        display: flex;
        flex-direction: column;
        font-family: var(--font-family);
        background: #fff;
        width: 100%;
        height: auto;
        padding: 0.4rem;
        border-bottom: solid 2px #ccc;
        font-size: 12px;
    }
    .module-lessons{
        font-size: 15px;
        color: #fff;
        font-weight: 800;
        padding-right: 0.1rem;
    }
    .module-lessons-center{
        font-size: 16px;
        color: #646161;
        font-weight: 500;
        width: auto;
        font-family: var(--font-family);
    }
    .module-left{
        font-size: 14px; 
        width: 100%;
    }
    .module-right{
        width: 100%;
        font-size: 10px;
    }
    .btn-start{
        font-size: 10px;
    }
    .end-course{
        font-size: 10px;
    }
}
</style>