<template>
    <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
               
        <div style="display: flex; flex-direction:row; font-size: 20px; font-weight:800; color:#ccc; align-items: center; justify-content: center"> COURSES DETAILS  </div>
        <hr>
        <!-- <router-link to="/dashboard">
            <div>
                &nbsp;&nbsp;<i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> BACK
            </div>
        </router-link> -->
        <div class="course-container">
            <div class="course-content">
                <div class="course-title">
                    {{ programme_modules.course_module_title }}
                </div>
                <div class="course-detail">
                    <img class="course-img" src="../assets/cert.jpg" alt="">
                    <p>&nbsp;</p>
                    <h2>Course Details</h2>
                    {{ programme_modules.course_module_desc }}
                    <p>&nbsp;</p>
                    <h2>Lesson</h2>
                    
                    <p>&nbsp;</p>
                    <h2>Entry Requirements</h2>
                    There are no strict entry requirements for this course. Work experience will be added advantage to understanding the content of the course.

                    The certificate is designed to enhance the learner's knowledge in the field. This certificate is for everyone eager to know more and get updated on current ideas in their respective field. We recommend this certificate for the following audience,<br>
                        Hospitality professionals looking to improve their marketing skills<br>
                        Hotel managers seeking to increase revenue and guest satisfaction<br>
                        Marketing professionals interested in the hospitality industry<br>
                        Students and recent graduates pursuing careers in hospitality<br>
                        Entrepreneurs starting their own hospitality businesses<br>
                        Professionals seeking to enhance their resume and career prospects in hospitality<br>

                </div>
            </div>
            <div class="course-enrol">
                <div class="enrol">
                    <p>{{new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(programme_modules.course_price)}}</p>
                    Access to this course.<br>
                    Access to learning community.<br>
                    Access to library.<br>
                    Learn at your own pace.<br>
                    Tests to boost your learning.<br>
                    Digital certificate when eligible.
                    <div class="btn-enrol-container">
                        <button class="btn-enrol">Enrol Now</button>
                    </div>
                </div>
            </div>
        </div>

        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "CourseDetailPage",
    components: {
        PageLoader,
    },

    data(){
        return{
            isLoading: false,
            programme_modules: '',
            programmeId: '',
        }
    },
    mounted() {
        this.programmeId = this.$route.params.title
        this.fetch_module_detail(this.$route.params.title)
    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
        async fetch_module_detail(programmeid){
            this.isLoading = true;
            const response = await this.$http.post('/fetch-modules-details', {
                programme_id: programmeid
            })
            this.isLoading = false;
            this.programme_modules = response.data.modules[0];

        },
    }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }

    a{
        text-decoration: none;
        color: #ff4d63;
    }
    .course-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }
    .course-content{
        display: flex;
        flex-direction: column;
        width: 80%;
        padding: 0.8rem;
        font-family: var(--font-family);
        font-size: 14px;
    }
    .course-title{
        font-size: 30px;
        font-family: var(--font-family);
        font-weight: 550;
    }
    .course-img{
        width: 100%;
        height: 350px;
    }
    .course-detail{
        text-align: justify;
        padding: 0.4rem;
    }
    .course-enrol{
        display: flex;
        flex-direction: column;
        width: 20%;
        font-family: var(--font-family);
        font-size: 14px;
    }
    .enrol{
        padding: 0.8rem;
        border: solid 1px #ebe7e7;
        p{
            margin-top: 10px;
            color: #ff4d63;
            font-size: 18px;
            text-align: center;
            font-weight: bold;
        }
    }
    .btn-enrol-container{
        padding: 0.4rem;
    }
    .btn-enrol{
        border: solid 1px #ebe7e7;
        width: 100%;
        font-family: var(--font-family);
        font-size: 16px;
        padding: 0.4rem;
    }
    @media screen and (max-width: 768px) {

        .course-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 10px;
        }
        .course-content{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0.8rem;
            font-family: var(--font-family);
            font-size: 14px;
        }
        .course-enrol{
            display: flex;
            flex-direction: column;
            width: 100%;
            font-family: var(--font-family);
            font-size: 14px;
        }
    }
   
</style>