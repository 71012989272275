<template>
    <div style="background: #e0dfdf11">
        <div class="row course-module">
            <div class="col-sm-1"></div>
            <div class="col-sm-10">
                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top:10px"  @mouseover="showTitle()" v-if="isExit">
                    <div style="display: flex; flex-direction: row; align-items: center;" >
                        <router-link :to="'/course/' + course_lessons.course_module_id"> <i class="fa fa-arrow-left" aria-hidden="true"></i> EXIT </router-link> &nbsp;| &nbsp; 
                        <div class="settings" style="background: #fff; border-radius: 5px">
                            <button @click="show()" class="dropbtn2" style="font-size: 12px"><i class="fa fa-cog"></i> </button>
                        </div>
                    </div>
                    
                     <span v-if="start_speech">
                        <button style="font-size: 12px; color: #000" @click="start" v-if="startSpeech"><i class="fa fa-volume-off" aria-hidden="true"></i>
                            Start
                        </button>
                    </span>
                    <button style="font-size: 12px; color: #000" @click="stop" v-if="stopSpeech"><i class="fa fa-volume-up" aria-hidden="true"></i>
                        Stop
                    </button>
                </div>
                <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
                    <div class="top-header" v-if="isSettings">
                        <!-- <div class="dropdown back-color">
                            <button class="dropbtn"><i class="fa fa-adjust" aria-hidden="true"></i>
                                background color
                            </button>
                            <div class="dropdown-content">
                              <v-color-picker
                               hide-inputs v-model="backcolors"
                                ></v-color-picker>
                            </div>
                        </div> -->
                        
                        <!-- <div class="dropdown text-color">
                            <button class="dropbtn"><i class="fa fa-font" aria-hidden="true"></i>
                                 text color</button>
                            <div class="dropdown-content">
                              <v-color-picker
                              hide-inputs v-model="textcolors"
                                ></v-color-picker>
                            </div>
                        </div> -->
                        <div class="dropdown text-size">
                            <button class="dropbtn"><i class="fa fa-text-height" aria-hidden="true"></i>
                                 text size</button>
                            <div class="dropdown-content">
                                <a  @click="textSize(15)">15</a>
                                <a  @click="textSize(16)">16</a>
                                <a  @click="textSize(17)">17</a>
                                <a  @click="textSize(18)">18</a>
                                <a  @click="textSize(19)">19</a>
                                <a  @click="textSize(20)">20</a>
                                <a  @click="textSize(21)">21</a>
                                <a  @click="textSize(22)">22</a>
                                <a  @click="textSize(23)">23</a>
                                <a  @click="textSize(24)">24</a>
                                <a  @click="textSize(25)">25</a>
                                <a  @click="textSize(26)">26</a>
                            </div>
                        </div>
                    </div>
                </div>
                <br>               
                <div class="progress" style="height: 10px; font-size: 8px" v-if="isProgress">
                    <div class="progress-bar bg-danger" role="progressbar" :style="{ width: step_value }" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">
                       
                    </div>
                </div>
                <div class="modules" v-if="isLessonContent">
                    <div class="module-top" v-if="isTitle"> 
                        <div class="module-lessons">
                           {{course_module.course_module_title}}<br>                          
                        </div>      
                        <div class="course-object-title">
                            LESSON {{course_lessons.course_lesson_number}}: {{course_lessons.course_lesson_title}}. 
                        </div>                 
                    </div>
                    <div class="module-container" @mouseover="isTitle = false">
                        <div class="module-subcontainer">
                            <div class="module-left">
                                <div class="module-center">      
                                    <div class="module-lessons-center"  ref="scrollContainer" @scroll="handleScroll"  v-if="endLessons" >
                                        <div v-if="currentChunk !== null">
                                            <div class="content-style" id="textToRead" v-html="currentChunk" :style="{fontSize: text_size}"></div> 
                                            <!-- <div id="textToRead" v-html="currentChunk" :style="{background: backcolors, color: textcolors, fontSize: text_size}"></div>  -->
                                        </div>
                                    </div>              
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div v-if="showContent" >
                    <h3><center>You have completed this lesson. Click "NEXT" to move to the next lesson.</center></h3>
                </div>
                <div class="navbar fixed-bottom pre-next" v-if="isPreNext">
                    <div style="display: flex; flex-direction: row; justify-content: left; align-items: center">
                        <button @click="showPreviousChunk" v-if="isPrevious">
                            <i class="fa fa-arrow-left"></i> Previous
                        </button> 
                    </div>
                    <button @click="showNextChunk()" v-if="isNext">
                        Continue <i class="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
            <div class="col-sm-1"></div>
        </div>
        <div class="last-button navbar fixed-bottom" v-if="isLastButton">
            <button class="btn-next-lesson" @click="next_lessons()"> next lesson <i class="fa fa-arrow-right"></i></button>
        </div>

        <div v-if="play_audio">
            <transition name="modal" >
                <div class="modal-mask">
                    <div class="modal-wrapper" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                              
                            </div>
                            <div class="col-md-10 player-container">
                                <div class="player-content">
                                    <div class="row">
                                        <audio ref="myAudio"  controls>
                                            <source src="" type="audio/ogg">
                                            <source :src="mp3_file" type="audio/mpeg">
                                          Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                </div>
                                <div class="player-button">
                                    <button @click="stop">Close</button>
                                </div>
                            </div>
                            <div class="col-md-1">
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div> 
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
import swal from 'sweetalert2';
import VueSpeech from 'vue-speech';
export default {
    plugins: [VueSpeech],
    name: "CourseLessons",
    components: {
       PageLoader,
    },
    data(){
        return{
            isLoading: false,
            play_audio: false,
            isPreNext: true,
            course_lessons: '',
            course_module: '',
            items: [],  // Array to store your items
            hasMore: true,  // Flag to determine if there are more items to load
            content: '',
            contentChunks: [],
            currentChunkIndex: null,
            currentChunk: null,
            isLastButton: false,
            backcolors: `#fff`,
            textcolors: `#000`,
            text_size: `16px`,
            isSettings: false,
            step_value: 0,

            stepValues: [],
            totalSteps: 0,
            isTitle: false,
            textToSpeak: '',
            startSpeech: true,
            stopSpeech: false,
            isPrevious: false,
            isPrevious2: true,
            endLessons: true,
            start_speech: true,
            moduleId: '',
            isNext: true,
            complete_course: '',
            mp3_file: '',
            ogg_file: '',
            isExit: true,
            isProgress: true,
            isLessonContent: true,
            showContent: false
        }
    },
    beforeMount(){
        //this.step_value = 10
    },
    mounted() {

        //this.$refs.myAudio.playbackRate = 1.5;

        // Assuming you are using Vue.js
        const container = this.$refs.scrollContainer;
        
        // Scroll to the top of the page
        container.scrollTop = 0;

        // Add scroll event listener if you want to handle scrolling in your component
        container.addEventListener('scroll', this.handleScroll);

        this.user_id = localStorage.getItem("userid");
        this.complete_course = this.$route.params.completed
        if (this.complete_course == "completed") {
            this.fetch_lessons_lesson(this.$route.params.title, this.user_id, '/fetch-completed-lessonsby-lessonid')
        }
        else{
            this.fetch_lessons_lesson(this.$route.params.title, this.user_id, '/fetch-lessonsby-lessonid')
        }
       
    },
    computed: {

    },
    created(){

    },
    methods: {

        async fetch_lessons_lesson(title, userid, url){
            this.isLoading = true;
            const response = await this.$http.post(url, {
                lessons_id: title,
                userid: userid
            })

            this.isLoading = false;
            this.course_lessons = response.data.lessons[0];
            this.course_module = response.data.moduleHead;
            this.moduleId = this.course_module[0].course_moduleid
            this.mp3_file = this.course_lessons.course_lesson_audio
            this.loadContent();
            this.splitContent();
            this.showNextChunk();
        },

        splitContent() {
           
            const words = this.content.split(' ');
            const chunkSize = 150;
            for (let i = 0; i < words.length; i += chunkSize) {
                const chunk = words.slice(i, i + chunkSize).join(' ');
                this.contentChunks.push(chunk);
                // Push an object with the chunk value into the stepValues array
            }
        },

        removeHtmlTags(html) {
            // Replace HTML tags with an empty string
            return html.replace(/<[^>]*>/g, '');
        },

        showNextChunk() {
            if (this.currentChunkIndex === null) {
                this.currentChunkIndex = 0;
                this.isPrevious = false
            } else {
                this.currentChunkIndex += 1;
                const per = (this.currentChunkIndex / this.contentChunks.length) * 100;
                this.step_value = per +'%';
                this.isPrevious = true
                //this.stop()
            }

            // Check if it's the last chunk
            if (this.currentChunkIndex === this.contentChunks.length - 1) {
                this.isLastButton = true
                this.isExit = false
                this.isProgress = false
                this.isLessonContent = false
                this.showContent = true
                this.endLessons = false
                this.start_speech = false
                this.isPrevious = true
                this.isNext = false
                this.isPreNext = false;
                
                this.update_user_lessons(this.$route.params.title, this.user_id)
            }
          
            this.currentChunk = this.contentChunks[this.currentChunkIndex];
           
        },

        showPreviousChunk() {
            if (this.currentChunkIndex === null || this.currentChunkIndex === 0) {
                // If it's the first chunk or no chunk is selected, do nothing or handle it as needed
                 this.isPrevious = false
                 this.isNext = true
                
                
                return;
            } else {
                this.currentChunkIndex -= 1;
                const per = (this.currentChunkIndex / this.contentChunks.length) * 100;
                this.step_value = per + '%';
                this.endLessons = true
                this.start_speech = true
                this.isNext = true
                
                
            }

            // Check if it's not the last chunk anymore
            
            this.isLastButton = false
            this.isExit = true
            this.isProgress = true
            this.isLessonContent = true
            this.showContent = false
            

            this.currentChunk = this.contentChunks[this.currentChunkIndex];
        },

        handleScroll() {
            const container = this.$refs.scrollContainer;
            const scrollPosition = container.scrollTop;
            const containerHeight = container.clientHeight;
            const contentHeight = container.scrollHeight;

            if (scrollPosition + containerHeight >= contentHeight) {
                // User has scrolled to the bottom
                //this.showNextChunk();
                //alert("End of lesson")
                return
            }
        },

        loadContent(){
            this.content = `${this.course_lessons.course_lesson_content}`
        },

        textSize(x){
            this.text_size = x+'px'
        },
        show(){
            if (this.isSettings==false) {
                this.isSettings=true
            }
            else{
                this.isSettings=false
            }
        },
        calculateAriaValueNow(index) {
            // Calculate the aria-valuenow based on the current step and the total number of steps
            return ((index + 1) / this.stepValues.length) * this.totalSteps;
        },
        showTitle(){
            if (this.isTitle==false) {
                this.isTitle=true
            }
            else{
                this.isTitle=false
            }
        },
        start() {
            
            //this.speakWithHighlight()
            // var textContent = this.removeHtmlTags(this.currentChunk);
            // if ('speechSynthesis' in window) {
            //     const synth = window.speechSynthesis;
            //     const utterance = new SpeechSynthesisUtterance(textContent);
            //     synth.speak(utterance);
            // } else {
            //     console.error('Speech synthesis not supported in this browser.');
            // }
            this.play_audio = true
            this.startSpeech =false
            this.stopSpeech = true
        },

        stop() {
            //if ('speechSynthesis' in window && this.currentSpeechUtterance) {
                // Stop the speech synthesis
               // window.speechSynthesis.cancel();

                // Optionally, you can also reset the currentSpeechUtterance variable
               // this.currentSpeechUtterance = null;
           // }
            this.play_audio = false
            this.startSpeech =true
            this.stopSpeech = false
        },

        speakWithHighlight() {
            const synth = window.speechSynthesis;
            const contentElement = document.getElementById('textToRead');
            const words = contentElement.innerText.split(' ');

            let currentIndex = 0;

            function highlightWord(index) {
                this.$set(words, index, `<span class="highlight">${words[index]}</span>`);
            }

            function speakNextWord() {
                if (currentIndex < words.length) {
                const utterance = new SpeechSynthesisUtterance(words[currentIndex]);
                utterance.onstart = () => highlightWord.call(this, currentIndex);
                utterance.onend = () => {
                    currentIndex++;
                    this.$set(words, currentIndex - 1, words[currentIndex - 1].replace('<span class="highlight">', '').replace('</span>', ''));
                    speakNextWord.call(this);
                };
                synth.speak(utterance);
                }
            }

            speakNextWord.call(this);
        },

        async update_user_lessons(title, userid){
            await this.$http.post('/update-user-lessons', {
                userid: userid,
                lessons_id: title,
            })
        },

        async next_lessons(){
            this.isPrevious = false
            this.isNext = true
           const response = await this.$http.post('/next-user-lessons', {
                userid: this.user_id,
                module_id: this.moduleId,
            })

            if (response.data.success) {
                this.step_value = 0
                this.stepValues= []
                this.totalSteps= 0
                this.endLessons = true
                this.start_speech = true
                this.isLastButton = false
                this.$router.push('/course/start-lesson/'+response.data.lessonid)
                this.loadContent();
                this.splitContent();    
                this.showNextChunk();
                window.location.reload();
            }
            else{
                swal.fire({
                    icon: 'warning',
                    title: 'Module Completed',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.$router.push('/course/'+this.moduleId)
                return;
            }
        }

    }
}
</script>
<style lang="scss" scoped>

.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;

    & > div {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
       // border: 0.1px solid #2b0c17;
        background: transparent;
        backdrop-filter: blur(13px);
        //border-radius: 20px;
        box-sizing: border-box;
    }

}

.player-container{
    display: flex; 
    flex-direction: column; 
    padding: 0.5rem
}

.player-content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0.5rem
}
.player-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.highlight {
    background-color: yellow;
    display: inline;
  }
:root {
   // --font-family: 'Poppins', 'DM Sans';
    --font-family: 'DM Sans';
  }
.infinite-scroll {
    height: 300px;  /* Set the height of your scroll container */
    overflow-y: auto;  /* Enable vertical scrollbar */
  }
  
  .item {
    border: 1px solid #ccc;
    margin: 5px;
    padding: 10px;
  }
a{
    text-decoration: none;
    color: #ff4d63;
}
.top-header{
    display: flex; 
    flex-direction:row; 
    align-items: center; 
    justify-content: space-evenly;
    color:#000; 
    font-weight:500; 
    width: 100%;
    padding: 0.4rem;
    font-family: var(--font-family);
    font-size: 12px;
    //background: #000;
    border-radius: 4px;
    text-transform: capitalize;
}

.dropbtn2 {
    //background-color: #4CAF50;
    text-transform: capitalize;
    color: #ff4d63;
    padding: 2px;
    font-size: 10px;
    border: none;
    cursor: pointer;
}

/* Style The Dropdown Button */
.dropbtn {
    //background-color: #4CAF50;
    text-transform: capitalize;
    color: #000;
    padding: 4px;
    font-size: 10px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: #fff;
    padding: 3px 4px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    //background-color: #3e8e41;
  }
.back-color{
    height: auto;
}
.text-color{
    height: auto;
}
.course-module{
    display: flex;
    flex-direction: row;
    justify-content: left;
    bottom: 0;
    padding: 0.9rem;

    a{
        color: #ff4d63;
    }
}
.modules{
    width: 100%;
    border-radius: 4px;
    border-top-right-radius: solid 0px #ff4d63;;
    border-top-left-radius: solid 0px #ff4d63;;
    //border: solid 1px #ccc;
    font-family: var(--font-family);
    margin-bottom: 10px;
    margin-top: 10px;
}
.module-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    background: #ff4d63;
    border: solid 1px #ff4d63;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    height: 40px;
    padding: 0.4rem;
}

.module-container{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    background: #fff;
    width: 100%;
    height: auto;
    padding: 0.4rem;
    border-bottom: solid 2px #ccc;
}
.module-subcontainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.btn-start {
    text-transform: uppercase;
    font-family: var(--font-family);
    font-size: 12px;
    width: 100%;
    height: 30px;
    padding: 0.4rem;
    border: solid 1px #ff4d63;
    border-radius: 4px;
}
.content-style{
    padding: 10px;
    margin-bottom: 25px;
}
.module-center{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-family: var(--font-family);
    width: 100%;
    padding: 0.4rem;
    border-radius: 10px;
}
.module-footer{
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-family: var(--font-family);
    width: 100%;
    padding: 0.4rem;
}
.module-lessons{
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}
.module-lessons-center{
    font-size: 15px;
    width: auto;
    text-align: justify;
    padding-right: 0.9rem;
}
.course-object-title{
    font-family: var(--font-family);
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
}
.course-object-content{
    font-family: var(--font-family);
    font-size: 10px;
    color: #221f1fa2;
}
.module-lessons-footer{
    font-size: 16px;
    color: #646161;
    font-weight: 800;
}
.module-container-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.9rem;
    font-family: var(--font-family);
    font-size: 15px;

    a{
        color: #ff4d63;
    }
}
.top-header2{
    display: flex; 
    flex-direction:row; 
    align-items: center; 
    justify-content: left;
    font-size: 14px; 
    text-transform: capitalize;
    color:#716a6a; 
    font-weight:800; 
    width: 100%;
    padding: 0.9rem;
    font-family: var(--font-family);
    font-size: 15px;
}
.related-course{
    font-family: var(--font-family);
    font-size: 13px;
}
.last-button{
    display: flex; 
    flex-direction:row; 
    align-items: center; 
    justify-content: center;
}
.pre-next{
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    background: #ff4d63; 
    color: #fff;
    font-family: var(--font-family);
    font-size: 18px;
    padding: 20px;
}
.btn-next-lesson{
    text-transform: uppercase;
    font-family: var(--font-family);
    font-size: 12px;
    height: 50px;
    width: 50%;
    padding: 0.4rem;
    border-radius: 4px;
    background: #ff4d63;
    color: #fff;
}
@media screen and (max-width: 768px) {
    .player-container{
        display: flex; 
        flex-direction: column; 
       // padding: 10rem
    }
    .player-content{
       
    }

    a{
        text-decoration: none;
        color: #ff4d63;
        font-size: 12px;
    }
    .course-module{
        display: flex;
        flex-direction: row;
        padding: 0px;
        bottom: 0;
    }
    .last-button{
        display: flex; 
        flex-direction:row; 
        align-items: center; 
        justify-content: center;
        position: absolute;
        bottom: 0;
        flex-shrink: 0;
        width: 100%;
        background: #ff4d63;
    }
    .content-style{
        padding: 10px;
        margin-bottom: 25px;
    }
    .btn-next-lesson{
        text-transform: uppercase;
        font-family: var(--font-family);
        font-size: 12px;
        height: 50px;
        width: 100%;
        padding: 0.4rem;
        border-radius: 4px;
        background: #ff4d63;
        color: #fff;
    }
    .pre-next{
        font-family: var(--font-family);
        font-size: 16px;
    }
    .module-container{
        display: flex;
        flex-direction: column;
        font-family: var(--font-family);
        background: #fff;
        width: 100%;
        height: auto;
        padding: 0.4rem;
        border-bottom: solid 2px #ccc;
        font-size: 12px;
        padding: 0px;
    }
    .module-subcontainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
    }
    .module-lessons-center{
        font-size: 12px;
        width: auto;
        padding: 0px;
    }
    .module-left{
       
        font-size: 14px; 
        width: 100%;
    }
    .module-right{
        width: 100%;
        font-size: 12px;
    }
    .btn-start{
        font-size: 12px;
    }
}
</style>