<template>
    <div>
        <p>&nbsp;</p>
        <div class="plan-title">
            <p><router-link to="/fees"><i class="fa fa-arrow-left"></i> Back</router-link></p>
            <p class="title">&nbsp;&nbsp;Payment Plan</p>
            <p class="choose-plan">&nbsp;&nbsp;&nbsp;&nbsp;choose a payment plan</p>
        </div>
        <div class="row plan-board">
            <div class="col-sm-4" v-for="(data, index) in fee_plans" :key="index">
                <div class="plan1" :style="{background: data.back_color}">
                    <div class="plan-text">
                        <p class="pay-type" v-if="data.is_onetime == 1">{{ "ONE TIME"}}</p>
                        <p class="pay-type" v-else>{{ data.duration }}</p>
                        <div class="amount">${{ data.amount }} </div>
                        <p class="dollar" v-if="data.is_onetime == 1">${{ data.per_month }}</p>
                        <p class="dollar" v-else>${{ data.per_month }}/<span>per month</span></p>
                    </div>
                    <div class="plan-button">
                        <div class="pay-now" @click="pay_now(data.payment_planid, data.is_onetime, data.amount, data.duration, data.category, data.per_month, data.days)">PAY NOW</div>
                    </div>
                </div>
            </div>
        </div>
         <!-- Fee Modal -->
         <transition name="modal-fade">
            <div v-if="fee_modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-2">
                            </div>
                            <div class="col-md-10">
                                <div class="row margin-b">
                                     <div>
                                        <br>
                                        <input style="margin-top:4px" type="hidden" class="form-control input-control" v-model="plan_id">
                                        <div>
                                           <center> YOU'RE ABOUT TO PAY YOUR SCHOOL FEE </center>
                                        </div>
                                        <hr>
                                        <div class="p-style" v-if="onetime_p == 1">
                                            PAYMENT PLAN: <strong>{{ 'ONE TIME' }}</strong>
                                        </div>
                                        <div class="p-style" v-else>
                                            PAYMENT PLAN: <strong>{{ this.duration }}</strong>
                                        </div>
                                        <div class="p-style">
                                            AMOUNT: <strong>${{ this.amount }}</strong> 
                                        </div>
                                        <hr>    
                                        <div class="btn btn-danger btn-block get-started">
                                            <flutterwave-pay-button 
                                              :tx_ref="generateReference()"
                                              :amount= amount
                                              currency="USD"
                                              payment_options="card,ussd"
                                              redirect_url="https://app.fordaxbschool.com/payment-successful"
                                              class="class-name"
                                              style=""
                                              :meta="{
                                                counsumer_id: '7898',
                                                consumer_mac: 'kjs9s8ss7dd'
                                              }"
                                              :customer="{
                                                name: firstname,
                                                email: email_address,
                                                phone_number: phone_number
                                              }"
                                              :customizations="{
                                                title: 'Fordax Business School',
                                                description: 'School Fee Payments',
                                                logo : 'https://app.fordaxbschool.com/logox.png'
                                              }"
                                              :callback="makePaymentCallback()"
                                              :onclose="closedPaymentModal"
                                            >
                                              Pay
                                            </flutterwave-pay-button>
                                        </div>                        
                                        <button class="form-control btn btn-default btn-block" @click="fee_modal= false" >Exit</button>
                                       <br>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="col-md-2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition> 
        <page-loader :is-loading="isLoading"></page-loader>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
</template>
<script>
import swal from 'sweetalert2';
import {FlutterwavePayButton} from "flutterwave-vue-v3";
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "PaymentPlan",
    components: {
        PageLoader,
        FlutterwavePayButton
    },

    data(){
        return {
            isLoading: false,
            fee_modal: false,
            loading: false,
            email_address: '',
            phone_number: '',
            user_id: '',
            firstname: '',
            lastname: '',
            appid: '',
            fee_plans: '',
            plan_id: '',
            amount: '',
            duration: '',
            onetime_p: '',
            p_category: '',
            p_permonth: '',
            payment_payload: [],
            recordid: '',
        }
    },
    beforeMount(){
        
    },
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.appid = localStorage.getItem("appid");
        this.fetch_school_fee_details()
    },
    computed: {

    },

    methods:{
        async fetch_school_fee_details(){
          this.isLoading = true;
          const response = await this.$http.get('/fetch-payment-plan', {
          })
          this.isLoading = false;
          this.fee_plans = response.data.fee_plans;
        },

        pay_now(payment_planid, onetime_payment, payment_amount, payment_duration, payment_category, payment_permonth, days){
            this.fee_modal = true
            this.plan_id = payment_planid
            this.amount = payment_amount
            this.duration = payment_duration
            this.onetime_p = onetime_payment
            this.p_category = payment_category
            this.p_permonth = payment_permonth

            this.payment_payload = {
                userid: this.user_id,
                appid: this.appid,
                planid:  this.plan_id,
                amount: this.amount,
                duration: this.duration,
                payment_category: this.p_category,
                payment_permonth: this.p_permonth,
                onetime_payment: this.onetime_p,
                days: days,
                transaction_ref: this.generateReference()
            }

            // trigger callback
            this.updateFeePayment()
        },
        
        pay(){
            
        },

        closeMenu(){
            this.fee_modal = false
        },
       
        generateReference(){
        let date = new Date();
        return date.getTime().toString();
        },
        getFirstname(){
            return this.firstname
        },
        getEmaladdress(){
            return this.email_address
        },
        getPhonenumber(){
            return this.phone_number
        },
        getAmount(){
            return this.amount
        },
        makePaymentCallback(response) {
            console.log("Payment callback", response);
           // this.updateFeePayment(response)
        },
        closedPaymentModal(){
            this.fee_modal = false
        },
         
        // update fee payment
        async updateFeePayment(){
            const response = await this.$http.post('/make-fee-payment', {
            payment_data: this.payment_payload
          })

            if (!response.data.success) {
                    swal.fire({
                    icon: 'error',
                    width:450,
                    height:5,
                    title: "Error",
                    text: response.data.message,
                });
                return;
            }
            else {
                this.recordid = response.data.record_id
                await localStorage.setItem("recordid", this.recordid)
            }
        }

    }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }
.modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //border: 0.5px solid #00802f;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: var(--font-family);
    }

    .p-style{
        text-transform: uppercase; 
        font-weight: bold;
        font-family: var(--font-family);
        font-size: 16px;
    }
}
.btn-default{
   border: solid 1px #d80d0d;
}
.plan-title{
    padding: 1rem;
}
.choose-plan{
    font-family: var(--font-family);
    font-size: 10px;
    line-height: 1px;
    text-transform: capitalize;
}
.plan-board{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    height: auto;
    margin: 0.4rem;
}
.plan1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 1.5rem;
    color: #000
}

.plan-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        line-height: 0.8px
    }
    .pay-type{
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .amount{
        font-family: var(--font-family);
        font-size: 20px;
        font-weight: bold;
    }
    span{
        font-family: var(--font-family);
        font-size: 10px;
        font-weight: 400;
    }
}
.plan-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.pay-now{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:30px;
    background: #d80d0d;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    padding:10px;
    color: #fff !important;
    cursor: pointer;
}
.next-payment{
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: bold;
    line-height: 0.8px;
}
.next-payment-date{
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 400;
    line-height: 1.8px;
}
@media screen and (max-width: 768px) {
    .plan1{
        margin: 4px;
    }

    .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f5f9f711;
        display: table;
        transition: opacity .5s ease;
        margin: 10px;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #f2f3f2;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: var(--font-family);
            margin: 10px;
        }
    
        .p-style{
            text-transform: uppercase; 
            font-weight: bold;
            font-family: var(--font-family);
            font-size: 16px;
        }
    }
    .margin-b{
        padding: 10px;
    }
}
</style>