<template>
    <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <router-link to="/dashboard">
            <div>
                &nbsp;&nbsp;<i class="fa fa-arrow-left" aria-hidden="true"></i> BACK
            </div>
        </router-link>
        <div class="app-container">
          <div class="search-input">
            <div class="inputs">
              <v-autocomplete
                required
                v-model="programme_year"
                label="Programme year *"
                :items="programmeYears"
                variant="outlined"
              ></v-autocomplete>
            </div>
            <!-- <div class="inputs">
              <v-autocomplete
                required
                v-model="student_data"
                label="Student name *"
                :items="studentData"
                variant="outlined"
              ></v-autocomplete>
            </div>
            <div class="inputs">
              <v-autocomplete
                required
                v-model="category_name"
                label="Programme category *"
                :items="categorys"
                @change="getCourse()"
                variant="outlined"
              ></v-autocomplete>
            </div>
            <div class="inputs">
                <v-autocomplete
                  required
                  v-model="course_title"
                  label="Course title *"
                  :items="courseTitle"
                  @change="getModule()"
                  variant="outlined"
                ></v-autocomplete>
            </div>
            <div class="inputs">
                <v-autocomplete
                  required
                  v-model="module_title"
                  label="Module title *"
                  :items="moduleTitle"
                  variant="outlined"
                ></v-autocomplete>
            </div> -->
          
          </div>
          <div class="search-input">
            <button id="submit2" class="btn btn-danger"  @click="fetch_mba_applications()" v-if="!loading">Search</button>
            <button class="btn btn-danger" type="button" disabled v-if="loading">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
          <p>&nbsp;</p>
            <h6>Applications</h6>
            <div class="app-row">
                <table class="data-detail table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>Date applied</th>
                        <th>Reg number</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Application fee</th>
                        <th>School fee</th>
                        <th>Admission status</th>
                        <th>Documents</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in application_list" :key="index">
                        <td>{{ index + 1}}</td>
                        <td> 
                          <span class="data-name">{{ new Date(data.dateApplied).toDateString()}}</span>
                        </td>
                        <td> 
                          <span class="data-name" v-if="data.student_regno == ''">N/A</span>
                          <span class="data-name" v-else>{{ data.student_regno }}</span>
                        </td>
                        <td> 
                            <span class="data-name">{{ data.firstname}}</span>
                        </td>
                         <td> 
                            <span class="data-name">{{ data.lastname}}</span>
                          </td>
                          <td> 
                              <span class="data-name">{{ data.phone}}</span>
                          </td>
                          <td> 
                              <span class="data-name">{{ data.email}}</span>
                          </td>
                          <td> 
                              <span class="data-name">{{ data.address}}</span>
                          </td>
                          <td>
                            <div v-if="data.application_fee_status == 1" class="text-white badge badge-success"> Paid</div>
                            <div v-if="data.application_fee_status == 0" class="text-white badge badge-danger"> Not paid</div>                                
                          </td>
                          <td>
                            <div v-if="data.school_fee_status == 1" class="text-white badge badge-success"> Paid</div>
                            <div v-if="data.school_fee_status == 0" class="text-white badge badge-danger"> Not paid</div>                                
                          </td>
                          <td>
                                <div v-if="data.admission_status == 1" class="text-white badge badge-warning"> In-Progress</div>
                                <div v-if="data.admission_status == 3" class="text-white badge badge-primary"> Admitted</div>                                
                                <div v-if="data.admission_status == 4" class="text-white badge badge-success"> Accepted</div>
                          </td>
                          <td> 
                            <div class="dropdown">
                              <button style="font-size: 10px" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                Action
                              </button>
                              <div class="dropdown-menu" style="font-size: 10px">
                                <router-link class="dropdown-item"  :to="'academic-record/' + data.mba_applicationsid" target="_blank">Academic record</router-link>
                                <router-link class="dropdown-item" :to="'professional-certificate/' + data.mba_applicationsid">Professional certificate</router-link>
                                <router-link class="dropdown-item" :to="'professional-membership/' + data.mba_applicationsid">Professional membership</router-link>
                                <router-link class="dropdown-item" :to="'work-experience/' + data.mba_applicationsid">Work experience</router-link>
                                <router-link class="dropdown-item" :to="'leadership-experience/' + data.mba_applicationsid">Leadership experience</router-link>
                                <router-link class="dropdown-item" :to="'awards/' + data.mba_applicationsid">Awards</router-link>
                              </div>
                            </div> 
                          </td>
                          <td> 
                            <div class="dropdown">
                              <button style="font-size: 10px" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                                Action
                              </button>
                              <div class="dropdown-menu" style="font-size: 10px">
                                <a class="dropdown-item" @click="accept_students(data.mba_applicationsid)">Accept student</a>
                                <router-link class="dropdown-item" :to="'send-rejection-letter/' + data.mba_applicationsid">Send rejection letter</router-link>
                                <span v-if="data.school_fee_status == 1">
                                  <a class="dropdown-item" @click="admit_students(data.mba_applicationsid)">Admit student</a>
                                </span>
                                <span v-else></span>
                               
                              </div>
                            </div> 
                          </td>
                      </tr>
                      </tbody>
                  </table>
            </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
</template>
<script>
import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "ViewApplicationPage",
    components: {
        PageLoader,
    },

    data(){
        return{
            isLoading: false,
            application_list: '',
            user_id: '',
            studentsLists: [],
            courseTitle: [],
            moduleTitle: [],
            student_data: '',
            studentData: [],
            programmeYears: [],
            programme_year: '',
            loading: false
        }
    },
    mounted() {
        this.user_id = localStorage.getItem("userid");
        this.generateYearList()
    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
      generateYearList() { 
            const currentYear = new Date().getFullYear();
            const startYear = 1970;

            for (let year = currentYear; year >= startYear; year--) {
               // years.push(year);
                this.programmeYears.push({
                    text: year,
                    value: year
                })
            }
        },
        async fetch_mba_applications(){
          this.loading = true;
          const response = await this.$http.post('/fetch-mba-applications', {
            programme_year: this.programme_year,
          })
          this.loading = false;
          this.application_list = response.data.applications;
        },

        async accept_students(appid){
        var msg = confirm("               You are about to accept this student. Are you sure?")

        if (msg == true) {
          const response = await this.$http.post('/accept-student', {
           appid: appid,
          })

          this.fetch_mba_applications()

          swal.fire({
              icon: 'success',
              width:450,
              height:5,
              title: "Success",
              text: response.data.message,
          });

          this.$router.push("/send-admission-letter/" + appid)
          return;
        }
       
        },

        async admit_students(appid){
        var msg = confirm("               You are about to admit this student. Are you sure?")

        if (msg == true) {
          const response = await this.$http.post('/admit-student', {
           appid: appid,
          })

          this.fetch_mba_applications()

          swal.fire({
              icon: 'success',
              width:450,
              height:5,
              title: "Success",
              text: response.data.message,
          });
          return;
        }
       
        },
    }
}
</script>
<style lang="scss" scoped>
:root {
  --font-family: 'Poppins', 'DM Sans';
}

    a{
        text-decoration: none;
        color: #ff4d63;
    }

    .app-container{
        display: flex;
        flex-direction: column;
    }
    .app-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:100%;
        height:auto;
    }
    .data-detail{
        font-size: 10px;
        font-family: var(--font-family);
    }
    .data-name{
        font-size: 10px;
        font-family: var(--font-family);
    }
    .btn-other{
        background: #ccc;
        color: #000;
        width: auto;
        height: auto;
        padding: 0.2rem;
        margin: 4px;
        border-radius: 4px;
        font-size: 10px;
    }
    .btn-adm{
       // background: #742020;
        color: #000;
        width: auto;
        height: auto;
        padding: 0.2rem;
        margin: 4px;
        border-radius: 4px;
        font-size: 10px;
    }
   
</style>