<template>
  <div>
      <p>&nbsp;</p>
      
      <p class="welcome" v-if="user_role == 1">Admin Dashboard</p>
      <p class="welcome" v-else-if="user_role == 2">Welcome to Fordax Business School</p>

      <!-- ADMIN DASHBOARD-->
      <div class="row content-center" v-if="user_role == 1">
        <div class="col-sm-4">
          <div class="dashboard1">
            <div class="title">
                total applications
            </div>
            <div class="title-content">
              <i class="fa fa-user-alt"></i>
              <span>150</span>
            </div>
          </div>
        </div>
        <!-- CERTIFICATE PROGRAMME-->
        <div class="col-sm-4">
          <div class="dashboard2">
            <div class="title">
              total courses
            </div>
            <div class="title-content">
              <i class="fa fa-anchor"></i>
              <span>124</span>
            </div>
          </div>
        </div>
        
        <!-- ADVANCE COURSES-->
        <div class="col-sm-4">
          <div class="dashboard3">
            <div class="title">
             total admitted
            </div>
            <div class="title-content">
              <i class="fa fa-user-alt"></i>
              <span>160</span>
            </div>
          </div> 
        </div>
      </div>
      <!-- USERS DASHBOARD-->
      <div class="row content-center" v-if="user_role == 2">
        <!-- MBA PROGRAMME-->
        <div class="col-sm-12" v-for="(data, index) in fordax_programme" :key="index">
          <div class="box1" v-if="data.is_mba == 2">
                <img
                  class="img"
                  :src="img_url + data.image"
                >
              <div class="box-details">
                <p class="title">{{ data.category_name }}</p>
                <p class="description" v-html="data.description"> </p>
                <hr>
                <div class="course-options">
                    <div class="programme-d"> <a href="https://fordaxbschool.com/edba/"> programme details </a></div>
                    <div class="view-cat"><router-link :to="'/view/course/' + data.programme_categoryid"> view courses </router-link> </div>
                </div>
                <router-link :to="'/programme/'+ data.category_name + '/' + data.programme_categoryid + '/' + data.category_name">
                  <div class="continue">
                    <button class="btn btn-primary"> PROCEED <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div>
                </router-link>
              </div>
          </div>
          <div class="box1" v-if="data.is_mba == 1">      
                <img
                  class="img"
                  :src="img_url + data.image"
                >
              <div class="box-details">
                <p class="title">{{ data.category_name }}</p>
                <p class="description" v-html="data.description"></p>
                <hr>
                <div class="course-options">
                  <div class="programme-d"> programme details </div>
                  <div class="view-cat"><router-link :to="'/view/course/' + data.programme_categoryid"> view courses </router-link> </div>
                </div>
                <router-link :to="'/programme/' + data.programme_categoryid + '/' + data.category_name">
                  <div class="continue">
                    <button class="btn btn-primary"> PROCEED <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div>
                </router-link>
              </div>
          </div>
        </div>
      </div>
    
      <page-loader :is-loading="isLoading"></page-loader>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
  </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
export default {
  
  name: "HomeVew",
  components: {
    PageLoader,
  },

  data(){
    return {
        isLoading: false,
        user_id: '',
        admission_status: '',
        mba_programme: '',
        certificate_programme: '',
        advance_programme: '',

        app_progress_status: '',
        app_progress_status2: '',
        user_role: '',
        fordax_programme: [],
        img_url: 'https://api.fordaxbschool.com/assets/programme_cat/',
    };
  },

  beforeMount(){
   
  },

  mounted(){
    this.user_id = localStorage.getItem("userid");
    this.user_role = localStorage.getItem("user_role");

    this.fetch_admission_status(this.user_id)
    this.fetch_mba(1)
    this.fetch_certificate(2)
    this.fetch_advance(3)
    this.fetch_programmes()
  },

  created(){
        
  },

  computed: {

  },

  methods: {
    async fetch_admission_status(user_id){

      this.isLoading = true;
      const response = await this.$http.post('/fetch-admission-status', {
          user_id: user_id
      })
      this.isLoading = false;
      this.admission_status = response.data.admissionStatus;
      this.app_progress_status = response.data.appProgress
      this.app_progress_status2 = response.data.appProgress2
    },

    async fetch_programmes(){
      this.isLoading = true;
        const response = await this.$http.get('/fetch-all-categories', {
      })
      this.isLoading = false;
      this.fordax_programme = response.data.categories;
    },

    async fetch_mba(categoryid){
      this.isLoading = true;
      const response = await this.$http.post('/fetch-mba', {
        categoryid: categoryid
      })
      this.isLoading = false;
      this.mba_programme = response.data.categories[0];
    },

    async fetch_certificate(categoryid){
      this.isLoading = true;
      const response = await this.$http.post('/fetch-certificate', {
        categoryid: categoryid
      })
      this.isLoading = false;
      this.certificate_programme = response.data.categories[0];
    },

    async fetch_advance(categoryid){
      this.isLoading = true;
      const response = await this.$http.post('/fetch-advance', {
        categoryid: categoryid
      })
      this.isLoading = false;
      this.advance_programme = response.data.categories[0];
    },

  }

}
</script>
<style lang="scss" scoped>
:root {
  --font-family: 'Poppins', 'DM Sans';
}
.welcome{
  font-family: var(--font-family);
  font-size: 24px;
  font-weight: 700;
  color: #170e0fac;
}
.content-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding: 0.9rem;
}
.box1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: var(--font-family);

  a{
    text-decoration: none;
    color: #000;
  }
}
.btn-danger{
  padding: 0.3rem;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}
.btn-primary{
  padding: 0.3rem;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
  text-transform: uppercase;
  background: #ff4d63;
  border: solid 0px;
}
.btn-warning{
  padding: 0.3rem;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}
.btn-secondary{
  padding: 0.3rem;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 4px;
}
.box1:hover{
 // transform: scale(1.1); 
  //transition: transform 0.3s ease; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
  //cursor: pointer;
}
.box2 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: var(--font-family);

  a{
    text-decoration: none;
    color: #000;
  }
}
.box2:hover{
  // transform: scale(1.1); 
   //transition: transform 0.3s ease; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
   cursor: pointer;
 }
.box3 {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: var(--font-family);

  a{
    text-decoration: none;
    color: #000;
  }
}
.box3:hover{
  // transform: scale(1.1); 
   //transition: transform 0.3s ease; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
   cursor: pointer;
 }
.img{
  width: 150px;
  height: 150px;
  padding: 0.9rem;
  margin: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.box-details{
  padding: 0.4rem;
  width: 100%;
  .title{
    font-family: var(--font-family);
    font-weight: 550;
    font-size: 10px;
    text-transform: capitalize;
  }
  .description{
    font-family: var(--font-family);
    font-size: 12px;
    text-align: justify;
  }
}
.course-options{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ccc !important;
}
.programme-d{
  border-right: solid 1px #000;
  padding: 0.2rem;
  cursor: pointer;
}
.programme-d:hover{
  color:darkred
}
.view-cat{
  padding: 0.2rem;
  cursor: pointer;
}
.view-cat:hover{
  color:darkred
}

.continue{
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding-right: 0.4rem;
  font-size: 30px;
  color: rgb(237, 138, 108);
  margin-right: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}
.dashboard1{
  background: #31c6be;
  height: 120px;
  border: solid 1px #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.dashboard2{
  background: #08502ac4;
  height: 120px;
  border: solid 1px #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.dashboard3{
  background: #4d85ffb9;
  height: 120px;
  border: solid 1px #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.title{
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #898686;
}
.title-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .fa-user-alt{
    font-size: 35px;
    color: #fff;
  }
  .fa-anchor{
    font-size: 35px;
    color: #fff;
  }
  span{
    font-size: 20px;
    font-weight: 400;
    margin: 10px;
    padding: 0.4rem;
    border-radius: 55px;
    height: auto;
    width: auto;
    background: #fff;
  }
}

@media screen and (max-width: 768px) {
  .img{
    display: none;
  }
  .welcome{
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #170e0fac;
  }
  .content-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.9rem;
  }

  .box-details{
    padding: 0.4rem;
    //line-height: 1.4px;
    .title{
      font-family: var(--font-family);
      font-weight: 550;
      font-size: 8px;
      text-transform: capitalize;
      color: #fff;
    }
    .description{
      font-family: var(--font-family);
      font-size: 12px;
      text-align: justify;
    }
  }
  .course-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 10px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .box1 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .dashboard1{
    margin-bottom: 10px;
  }
  .dashboard2{
    margin-bottom: 10px;
  }

  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    margin-top: -10px;
    color: rgb(237, 138, 108);
  }
}

</style>