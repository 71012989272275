<template>
    <div class="container-background">
        <p>&nbsp;</p>
        <div class="plan-title">
            <p class="title">&nbsp;&nbsp;PAYMENT DASHBOARD</p>
            <p class="choose-plan text-danger" v-if="admission_status == 4">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-exclamation-circle"></i> Congratulations! You have been offered admission to Diploma In Business Administration.</p>
            <p class="choose-plan text-success" v-else-if="admission_status == 3">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-exclamation-circle"></i> Congratulations! You have been admitted to study Diploma In Business Administration.</p>
            <p class="choose-plan text-primary" v-else>&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-exclamation-circle"></i> In Progress</p>
        </div>
        <div class="row plan-board">
            <div class="col-sm-4">
                <div class="plan1">
                    <div class="plan-text" v-if="school_fee_payment_status == 0">
                        <p class="pay-type">TAKE THE OFFER</p>
                        <div class="amount bg" v-if="offer_deadline_status == 1">
                            <span v-if="reject_offers == 2">
                                <router-link to="/pay-fees"><i class="fa fa-credit-card"></i> Accept Offer?</router-link> 
                            </span>
                            <span v-else>
                                <router-link to="/pay-fees"><i class="fa fa-credit-card"></i> Pay School Fee</router-link> 
                            </span>
                        </div>
                        <div class="amount bg bg-secondary text-white" v-if="offer_deadline_status == 2">
                            <router-link to="#"><i class="fa fa-credit-card"></i> Pay School Fee</router-link> 
                        </div>
                        <div v-if="reject_offers == 0">
                            <p class="dollar reject text-success" @click="reject_offer()"><i class="fa fa-star"></i> Reject the offer</p>
                        </div>
                        <div v-else-if="reject_offers == 1">
                            <p class="dollar text-primary" @click="reject_offer()"><i class="fa fa-star"></i> Offer accepted</p>
                        </div>
                        <div v-else-if="reject_offers == 2">
                            <p class="dollar"><i class="fa fa-star"></i> Offer rejected</p>
                        </div>
                    </div>
                    <div class="plan-text" v-if="school_fee_payment_status == 1">
                        <p class="pay-type">TAKE THE OFFER</p>
                       
                        <div class="amount bg bg-secondary text-white">
                            <i class="fa fa-credit-card"></i> School Fee Paid
                        </div>
                      
                    </div>
                    <div class="plan-button">
                        <div class="pay-now reject"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="plan2">
                    <div class="plan-text" v-if="school_fee_payment_status == 0">
                        <p class="pay-type">OFFER DEADLINE</p>
                        <div class="deadline text-danger">{{ offer_deadline}}</div>
                        <p class="dollar"></p>
                    </div>
                    <div class="plan-text" v-if="school_fee_payment_status == 1">
                        <p class="pay-type">OFFER DEADLINE</p>
                        <div class="deadline text-success">OFFER ACCEPTED</div>
                        <p class="dollar"></p>
                    </div>
                  
                </div>
            </div>
            <div class="col-sm-4">
                <div class="plan3" v-if="school_fee_payment_status == 0">
                    <div class="plan-text">
                        <p class="pay-type">NEXT DUE PAYMENT</p>
                        <div class="next-due-payment text-primary">N/A</div>
                        <p class="dollar reject">Renew Now</p>
                    </div>
                    <div class="plan-button">
                        <p class="next-payment">Cancel Payment</p>
                        <p class="next-payment-date"></p>
                    </div>
                </div>
                <div class="plan3" v-if="school_fee_payment_status == 1">
                    <div class="plan-text">
                        <p class="pay-type">NEXT DUE PAYMENT</p>
                        <div class="next-due-payment text-primary">{{ next_due_date }}</div>
                        <p class="dollar reject" @click="renew_now()">Renew Now</p>
                    </div>
                    <div class="plan-button">
                        <p class="next-payment">Cancel Payment</p>
                        <p class="next-payment-date"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row payment-history-section">
            <div class="col-sm-4">
                <h5 class="history-head">Payment history</h5>
                <div class="payment-history">
                    <div class="history-container" v-for="(data, index) in payment_details" :key="index">
                        <div class="history-content">
                            <i class="fa fa-calendar-alt"></i> &nbsp;Payment for {{ data.programme_year }} {{ data.category_name }} for {{ data.plan_duration }}, refno {{ data.payment_ref }} amount: ${{ data.amount }} on {{ new Date(data.payment_date).toDateString() }}
                        </div>
                        <div class="print">
                            <i class="fa fa-eye"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <h5 class="history-head">Payment receipts</h5>
                <div class="payment-receipts">
                    <div class="receipts-container" v-for="(data, index) in payment_details" :key="index">
                        <div class="receipts-content">
                            <i class="fa fa-receipt"></i> &nbsp;School fees payment on {{ new Date(data.payment_date).toDateString() }}
                        </div>
                        <div class="print">
                            <i class="fa fa-print"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <h5 class="history-head">Drop out/Cancel Payments</h5>
                <div class="payment-receipts">
                    <div class="receipts-container" v-for="(data, index) in payment_details" :key="index">
                        <div class="receipts-content">
                            <i class="fa fa-receipt"></i> &nbsp;School fees payment on {{ new Date(data.payment_date).toDateString() }}
                        </div>
                        <div class="print">
                            <i class="fa fa-print"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "FeesPage",
    components: {
        PageLoader,
    },

    data(){
        return {
            isLoading: false,
            email_address: '',
            phone_number: '',
            user_id: '',
            firstname: '',
            lastname: '',
            appid: '',
            app_details: '',
            offer_deadline: '',
            reject_offers: '',
            offer_deadline_status: '',
            admission_status: '',
            payment_details: '',
            current_payment: '',
            next_due_date: '',
            school_fee_payment_status: '',
        }
    },
    beforeMount(){
       
    },
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.appid = localStorage.getItem("appid");
        this.fetch_user_details();
        this.fetch_payment_details();
    },
    computed: {

    },

    methods:{

        async fetch_user_details(){
          this.isLoading = true;
          const response = await this.$http.post('/get-offer-deadline', {
            userid: this.user_id,
            appid: this.appid
          })

          this.isLoading = false;
          this.app_details = response.data.offer_deadline;
          this.reject_offers = this.app_details.user_offer_status
          let currentDate = new Date();
          let cdate = this.app_details.app_end_date
          this.admission_status = this.app_details.admission_status

          // Specify the end date (in this case, December 7, 2023)
          let endDate = new Date(`${cdate}`);
          if (currentDate > endDate) {
                this.offer_deadline_status = 2 // offer expired
                this.offer_deadline = 'Offer Expired'
           } else if (currentDate < endDate) {
                this.offer_deadline_status = 1 // offer active
                this.offer_deadline = new Date(endDate).toDateString()
           }       
        },

        async reject_offer(){
            let message = confirm("                     You're rejecting this offer, Are you sure?")
            if (message == true) {
              const response = await this.$http.post('/reject-offer', {
                userid: this.user_id,
                appid: this.appid
            })
            if (response.data.success) {
                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: response.data.message,
                    });
                }
                this.fetch_user_details()
            }
        },

        async fetch_payment_details(){
          this.isLoading = true;
          const response = await this.$http.post('/fetch-payment-details', {
            userid: this.user_id,
            appid: this.appid
          })

          this.isLoading = false;
          this.payment_details = response.data.payment_details;
          this.current_payment = response.data.current_payment;
          this.next_due_date = new Date(this.current_payment.payment_due_date).toDateString()
          if (this.current_payment == null) {
            this.school_fee_payment_status = 0
          }
          else{
            this.school_fee_payment_status = this.current_payment.payment_status
          }   
        },

        renew_now(){
            let message = confirm("                     You're about to renew your payment, Proceed?")
            if (message == true) {
                this.$router.push("/pay-fees")
            }
        }
    }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }
.container-background{
    margin: 2px;
    background: #cccccc2d;
    height: auto;
}
.plan-title{
    background: #fff;
    padding: 1rem;
    margin: 10px;
    border-radius: 10px;
}
.title{
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: bold !important;
    color: #ff4d63;
}
.choose-plan{
    font-family: var(--font-family);
    font-size: 14px;
    text-transform: capitalize;
}
.plan-board{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    height: auto;
    background: #fff;
    padding: 1rem;
    margin: 10px;
    border-radius: 10px;
}
.plan1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 1.5rem;
    color: #000;
    border: solid 1px #ff4d63;

}
.plan2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 1.5rem;
    color: #000;
    border: solid 1px #ff4d63;

}
.plan3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 1.5rem;
    color: #000;
    border: solid 1px #ff4d63;
}

.plan-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        line-height: 0.8px
    }
    .pay-type{
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
    }
    .amount{
        font-family: var(--font-family);
        font-size: 13px;
        font-weight: bold;
        border-radius: 4px;
        padding: 0.3rem;
        border: solid 1px #ff4d63;
    }
    .bg{
        cursor: pointer;
        background: #fff;
    }
    .deadline{
        font-size: 18px;
        text-align: left;
        font-weight: bold;
    }
    .next-due-payment{
        font-size: 18px;
        text-align: left;
        font-weight: bold;
    }
    span{
        font-family: var(--font-family);
        font-size: 10px;
        font-weight: 400;
    }
    .dollar{
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: 400;
        margin-top: 10px;
        color: #ff4d63;
        //cursor: pointer;
    }
}
.plan-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  
}
.pay-now{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:30px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    padding:10px;
    color: #fff !important;
}
.reject{
    cursor: pointer;
}
.next-payment{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}
.next-payment-date{
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 400;
    font-weight: bold;
}
.btn-secondary{
    background: #ff4d63;
    border: solid 0px;
}

.payment-history-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    height: auto;
    background: #fff;
    padding: 1.5rem;
    border-radius: 10px;
    font-size: 10px !important;
}
.payment-history{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    height: 100px;
    overflow-y: auto;
}
.history-head{
    font-size: 14px;
    font-weight: bold;
    background: #ff4d63;
    padding: 0.4rem;
    border-radius: 5px;
}
.history-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
}
.history-content{
    font-family: var(--font-family);
    font-size: 12px;
    padding: 0.3rem;
}
.payment-receipts{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    height: 100px;
    overflow-y: auto;
}
.receipts-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
}
.receipts-content{
    font-family: var(--font-family);
    font-size: 13px;
    padding: 0.3rem;
}
.fa-calendar-alt{
    color: #ccc;
}
.fa-receipt{
    color: #ccc;
}
.print{
    color: #ff4d63;
    cursor: pointer
}

@media screen and (max-width: 768px) {
    .choose-plan{
        font-family: var(--font-family);
        font-size: 12px;
        text-transform: capitalize;
        text-align: center;
    }
    .plan1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 120px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 1.5rem;
        margin-bottom: 10px;
    }
    .plan2{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 120px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 1.5rem;
        margin-bottom: 10px;
    }
    .plan3{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 120px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 1.5rem;
        color: #000
    }

    .plan-text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{
            line-height: 1px !important
        }
        .pay-type{
            font-size: 12px;
        }
        .amount{
            font-family: var(--font-family);
            font-size: 12px;
            font-weight: 500;
            border-radius: 4px;
            padding: 0.3rem;
            text-align: center;
        }
        span{
            font-family: var(--font-family);
            font-size: 10px;
            font-weight: 400;
        }
       
    }
    .history-head{
        margin: 10px;
        font-size: 14px;
        font-weight: bold;
        background: #ff4d63;
        padding: 0.4rem;
        border-radius: 5px;
    }
    .pay-now{
        display: flex;
        flex-direction: row;
        width: 100%;
        height:30px;
        border-radius: 5px;
        font-size: 10px;
        font-weight: 500;
        padding:10px;
        color: #fff !important;
        margin-left: 5px;
        text-align: center;
    }

   
}
</style>