<template>
    <div class="backgound">
        <div class="container">
            <p>&nbsp;</p>
            <div class="header">
                <div class="logo"><img src="logo.png" alt=""></div>
                <div class="login"></div>
            </div>
            <div class="get-started">Reset your password</div>
            <div class="login-form">
                <div class="login-form-inner">
                   
                    <div class="inputs"> 
                        
                        <div style="display: flex; flex-direction:row; font-size: 13px; justify-content: center; padding:1.5rem">Create new password</div>
                        <label for="">Password *</label>
                        <input type="password" class="form-control inputs-field" placeholder="Enter password" v-model="password">
                        <label for="">Confirm Password *</label>
                        <input type="password" class="form-control inputs-field" placeholder="Confirm password" v-model="cpassword">
                    </div>
                    
                    <div class="inputs">
                        <button id="submit4" class="btn btn-danger btn-block" :disabled="isSubmitButtonDisabled" @click="login()" v-if="!loading">Create</button>
                        <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                    
                </div>
                <!-- <div class="footer">
                    <div class="copyright">
                        <p>Don't have an account? <span><router-link to="/signup">Get Started</router-link></span></p>
                    </div>
                </div> -->
            </div>
        </div>
       
    </div>
    </template>
    <script>
    import Vue from 'vue'
    import VueCountryCode from "vue-country-code";
    import swal from 'sweetalert2';
    
    Vue.use(VueCountryCode);
    
    export default {
        
        name: 'ResetPassword',
        components: {
    
        },
        
        data() {
        return {
          appTitle: "Fordax Business School",
         
          submit_4: false,
          password: "",
          cpassword: "",
          loading: false,
          token: '',
        };
      },
    
      beforeMount(){
        document.title = this.appTitle
      },
      mounted() {
       this.token = this.$route.params.token
      },
      created(){
        
      },
      computed: {
        isSubmitButtonDisabled() {
        return (
          this.password === '' ||
          this.cpassword === ''
        );
      }
      },
    
      methods:{
    
        async login() {
                try {
    
                    if (this.password != this.cpassword) {
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: 'Password does not match!',
                        })
                        return;
                    }

                    this.loading = true
    
                    const response =  await this.$http.post('/reset-password', {
                        token: this.token,
                        password: this.password,
                        cpassword: this.cpassword
                    })
    
                    this.loading = false
    
                    if( !response.data.success ){
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        return;
                    }
                       
                    swal.fire({
                        icon: 'success',
                        title: 'Success',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })

                    this.$router.push('/')
                    return;
                   
                } catch (err) {
                  console.log(err);
            }
        },
    
      }
    }
    </script>
    <style lang="scss" scoped>
    :root {
        --font-family: 'Poppins', 'DM Sans';
      }
        .backgound{
            //background: url(../assets/background.jpeg) no-repeat;
            background-image: linear-gradient(to bottom, #ff4d63, rgba(237, 221, 221, 0.044));
            background-size: cover;
            font-family: var(--font-family) !important;
        }
      
        .container{
            display: flex;
            flex-direction: column;
        }
        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            padding: 10px;
            text-transform:uppercase;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            font-family: var(--font-family);
    
            .logo{
                img{
                    height: 50px;
                    width: auto;
                }
            }
    
            a{
                color: #504e4e;
            }
        }
        .get-started{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 20px;
            font-family: var(--font-family);
            font-size: 25px;
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            color: #fff;
        }
        .login-form{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .socials{
            display: flex;
            flex-direction: row;
        }
        .forgot-password{
            a {
                color: red;
                text-decoration: none;
            }
        }
        .google{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 60%;
            text-transform: capitalize;
            background: #4478f2;
            color:#fff;
            font-size: 9px;
            font-weight: 620;
            margin-bottom: 4px;
    
            img{
                width:20px
            }
        }
        .facebook{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 60%;
            text-transform: capitalize;
            background: #4478f2;
            color:#fff;
            font-size: 9px;
            font-weight: 620;
            margin-bottom: 4px;
            
            img{
                width:20px
            }
        }
        .apple{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 60%;
            text-transform: capitalize;
            background: #000;
            color:#fff;
            font-size: 9px;
            font-weight: 620;
            margin-bottom: 10px;
    
            img{
                width:20px
            }
        }
        .login-form-inner{
            display: flex;
            flex-direction: column;
            background: #fff;
            border-radius: 5px;
            padding: 2rem;
            width: 40%;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            font-family: var(--font-family);
            font-size: 13px;
            font-weight: 500;
            color: #504e4e !important;
            //height: 370px;
        }
    
        .counter{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            border-bottom: 1px solid #ccc;
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-top: 1px solid #ccc;
            padding: 4px;
            height: 30px;
            border-radius: 3px;
    
            span{
                color: #ccc;
            }
            .step-title{
                font-weight: 700;
            }
        }
        .inputs{
            margin-top: 20px;
            border-radius: 3px;
        }
        .inputs-field{
            font-size: 13px;
            font-family: var(--font-family);
        }
        .btn-danger{
            text-transform: uppercase;
            font-size: 13px;
            font-family: var(--font-family);
        }
        .inputs-field{
    
        }
        .footer{
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
        }
        .copyright{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: var(--font-family);
            font-size: 13px;
            color: #898080;
            padding: 0.3rem;
            width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
    
            span{
                a {
                    color: red;
                    text-decoration: none;
                }
            }
        }
        @media screen and (max-width: 768px) {
    
            .header{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
        
                .logo{
                    img{
                        height: 50px;
                        width: auto;
                    }
                }
            }
            .login-form-inner{
                width: 100%;
            }
    
            .get-started{
                font-size: 20px;
            }
    
            .google{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                text-transform: capitalize;
                background: #4478f2;
                color:#fff;
                font-size: 7px;
           
                img{
                    width:10px
                }
            }
            .facebook{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                text-transform: capitalize;
                background: #4478f2;
                color:#fff;
                font-size: 7px;
                
                img{
                    width:10px
                }
            }
            .apple{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                text-transform: capitalize;
                background: #000;
                color:#fff;
                font-size: 7px;
        
                img{
                    width:10px
                }
            }
        }
    </style>