<template>
    <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <router-link to="/mba-apps">
            <div>
                &nbsp;&nbsp;<i class="fa fa-arrow-left" aria-hidden="true"></i> BACK
            </div>
        </router-link>
        <div class="app-container">
          <p>&nbsp;</p>
            <h6>Awards Records</h6>
            <div class="app-row">
                <table class="data-detail table table-bordered">
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>Title</th>
                        <th>Organisation</th>
                        <th>Year</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in application_list" :key="index">
                        <td>{{ index + 1}}</td>
                        <td> 
                            <span class="data-name">{{ data.title }}</span>
                        </td>
                        <td> 
                            <span class="data-name">{{ data.organisation }}</span>
                        </td>
                        <td> 
                            <span class="data-name">{{ data.year}}</span>
                        </td>  
                        <td> 
                            <span class="data-name">{{ data.description }}</span>
                        </td>         
                      </tr>
                      </tbody>
                  </table>
            </div>
        </div>
        <page-loader :is-loading="isLoading"></page-loader>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "ViewAwards",
    components: {
        PageLoader,
    },

    data(){
        return{
            isLoading: false,
            application_list: '',
            user_id: '',
            studentsLists: [],
            courseTitle: [],
            moduleTitle: [],
            student_data: '',
            studentData: [],
            programmeYears: [],
            programme_year: '',
            loading: false,
            img_url: 'http://localhost:3000/assets/professional_memb/'
        }
    },
    mounted() {
        this.user_id = localStorage.getItem("userid");
        this.fetch_record(this.$route.params.appid)
    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
    
        async fetch_record(appid){
        this.isLoading = true;
        const response = await this.$http.post('/fetch-awards', {
            appid: appid,
        })
        this.isLoading = false;
        this.application_list = response.data.applications;

        },
    }
}
</script>
<style lang="scss" scoped>
:root {
  --font-family: 'Poppins', 'DM Sans';
}

    a{
        text-decoration: none;
        color: #ff4d63;
    }

    .app-container{
        display: flex;
        flex-direction: column;
    }
    .app-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:100%;
        height:auto;
    }
    .data-detail{
        font-size: 10px;
        font-family: var(--font-family);
    }
    .data-name{
        font-size: 10px;
        font-family: var(--font-family);
    }
    .btn-other{
        background: #ccc;
        color: #000;
        width: auto;
        height: auto;
        padding: 0.2rem;
        margin: 4px;
        border-radius: 4px;
        font-size: 10px;
    }
    .btn-adm{
       // background: #742020;
        color: #000;
        width: auto;
        height: auto;
        padding: 0.2rem;
        margin: 4px;
        border-radius: 4px;
        font-size: 10px;
    }
   
</style>