<template>
    <div>
        <div class="container-top">
            <div class="box1">
                <p class="heading">Application Process</p>
                <p class="subtitle">{{ programme_name }}</p>
                <router-link to="/dashboard"><button><i class="fa fa-arrow-left"></i> Back</button></router-link>
            </div>
        </div>
        
        <div class="container-middle">
            
            <div class="progress-track">
                <div class="progress bar-width">
                    <div class="progress-bar bg-danger  progress-bar-striped" role="progressbar" :style="{ width: step_value2 }"
                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" >
                      {{ step_value2 }} Complete
                    </div>
                </div>
                <ul id="progressbar" v-if="is_step_1">
                    <li class="step0 active" id="step1">Personal Information</li>
                    <li class="step0 text-center" id="step2">Aptitude Test</li>
                    <li class="step0 text-right" id="step3">Essay Writing</li>
                    <li class="step0 text-right" id="step4">Application Fee</li>
                </ul>
                <ul id="progressbar" v-if="is_step_2">
                    <li class="step0 active" id="step1">Personal Information</li>
                    <li class="step0 text-center active" id="step2">Aptitude Test</li>
                    <li class="step0 text-right" id="step3">Essay Writing</li>
                    <li class="step0 text-right" id="step4">Application Fee</li>
                </ul>
                <ul id="progressbar" v-if="is_step_3">
                    <li class="step0 active" id="step1">Personal Information</li>
                    <li class="step0 text-center active" id="step2">Aptitude Test</li>
                    <li class="step0 text-right active" id="step3">Essay Writing</li>
                    <li class="step0 text-right" id="step4">Application Fee</li>
                </ul>
                <ul id="progressbar" v-if="is_step_4">
                    <li class="step0 active" id="step1">Personal Information</li>
                    <li class="step0 text-center active" id="step2">Aptitude Test</li>
                    <li class="step0 text-right active" id="step3">Essay Writing</li>
                    <li class="step0 text-right active" id="step4">Application Fee</li>
                </ul>
            </div>
            
            <div class="personal-information" v-if="is_personal_information">
                <div class="progress" style="height: 4px;">
                    <div class="progress-bar bg-info" role="progressbar" :style="{ width: step_value }" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="counter">
                    <p class="step-title">{{ step_title }}</p>
                    <p>{{ step_number }} <span>of 7 Steps</span></p>
                </div>
                <form @submit.prevent="submit1" enctype="multipart/form-data">
                <center>
                    <div class="img-profile" v-if="previewImage == '' && step_1==true" style="margin-bottom:10px;">
                        <input type="file" ref="fileInput" id="image_input"  class="file-input"  @change="pickFile"/>
                    </div>
                    <span v-if="previewImage != '' && step_1==true" style="margin-bottom:10px;">
                        <div class="img-profile" :style="{ 'background-image': `url(${previewImage})` }">
                            <input type="file" ref="fileInput" id="image_input"  class="file-input"  @change="pickFile"/>
                        </div>
                    </span>
                </center>
                <div class="user-data" v-if="step_1">
                    <label for="">Firstname: </label>
                    <input type="hidden" class="form-control user-input" v-model="application_requestid" readonly>
                    <input type="text" class="form-control user-input" v-model="firstname" readonly>

                    <label for="">Lastname: </label>
                    <input type="text" class="form-control user-input" v-model="lastname" readonly>
                </div>
                <div class="user-data" v-if="step_1">
                    <label for="">Email: </label>
                    <input type="text" class="form-control user-input" v-model="email_address" readonly>
                    <label for="">Phone: </label>
                    <input type="text" class="form-control user-input" v-model="phone_number" readonly>
                </div>
                <div class="user-data" v-if="step_1">
                    <label for="">Address:<span style="color:red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="address" placeholder="Enter address">
                </div>
                <div class="user-data" v-if="step_1">
                    <label for="">Date of birth:<span style="color:red">*</span></label>
                    <input type="date" class="form-control user-input" v-model="dob">
                    <label for="">Founder?: </label>
                    <select class="form-control user-input" v-model="founder_cofounder" @change="toggle_founder">
                        <option value="">Select</option>
                        <option value="founder">Founder</option>
                        <option value="cofounder">Co-founder</option>
                        <option value="None">None</option>
                    </select>
                </div>
                <div class="user-data" v-if="step_1 && is_founder">
                    <label for="">Company: </label>
                    <input type="text" class="form-control user-input" v-model="company" placeholder="Enter company name">
                    <label for="">Description: </label>
                    <textarea name="" id="" cols="30" rows="10" class="form-control user-input" v-model="company_description" placeholder="Enter company description"></textarea>
  
                </div>
                <div class="user-data" v-if="step_1  && is_founder">
                    <label for="">Year founded: </label>
                    <select class="form-control user-input" v-model="year_founded">
                        <option value="">Select</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                    </select>
                    <label for="">Website: </label>
                    <input type="text" class="form-control user-input" v-model="company_website" placeholder="Enter company website">
                </div>
                <div class="user-data" v-if="step_1">
                    <div class="socials">
                        <label for="">Online presence: </label> &nbsp;&nbsp;<i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp;<input type="text" class="social-input" placeholder="facebook url" v-model="facebook"> &nbsp;<i class="fa fa-linkedin-square"></i> &nbsp;<input type="text" class="social-input" placeholder="linkedin url" v-model="linkedin"> &nbsp;<i class="fa fa-twitter-square"></i> &nbsp;<input type="text" class="social-input" placeholder="twitter url" v-model="twitter"> 
                    </div>
                </div>
                <div class="user-data" v-if="step_1">
                    <label for="">Your goal:<span style="color:red">*</span> </label>
                    <select class="form-control user-input" v-model="your_goal">
                        <option value="">Select</option>
                        <option value="Professional & career advancement">Professional & career advancement</option>
                        <option value="Start my own business">Start my own business</option>
                        <option value="Grow my current business">Grow my current business</option>
                        <option value="Career Change">Career Change</option>
                        <option value="Boost my CV for better job">Boost my CV for better job</option>
                    </select>
                    <label for="">Upload resume (Optional): </label>
                    <input type="file" ref="resumeInput" id="image_input"  class="form-control user-input"  @change="pickResume" />
                </div>
                </form>

                <!--ACADEMIC RECORD-->
                <form @submit.prevent="submit2" enctype="multipart/form-data">
                    <div class="user-data" v-if="step_2">
                        <label for="">School:<span style="color: red">*</span></label>
                        <input type="text" class="form-control user-input" v-model="academic_school" placeholder="Enter school graduated">
                        
                        <label for="">Course title:<span style="color: red">*</span></label>
                        <input type="text" class="form-control user-input" v-model="academic_course_title" placeholder="Enter course title">
                    
                    </div>
                    <div class="user-data" v-if="step_2">
                        <label for="">Year obtained:<span style="color: red">*</span></label>
                        <select class="form-control user-input" v-model="academic_year" id="year2x">
                            <option value="">Select</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                            <option value="2003">2003</option>
                            <option value="2002">2002</option>
                            <option value="2001">2001</option>
                            <option value="2000">2000</option>
                            <option value="1999">1999</option>
                            <option value="1998">1998</option>
                            <option value="1997">1997</option>
                            <option value="1996">1996</option>
                            <option value="1995">1995</option>
                            <option value="1994">1994</option>
                            <option value="1993">1993</option>
                            <option value="1992">1992</option>
                            <option value="1991">1991</option>
                            <option value="1990">1990</option>
                        </select>

                        <label for="">Upload(Academic transcript): <span style="color: red">*</span></label>
                        <input type="file" ref="academicInput" id="image_input"  class="form-control user-input" @change="pickAcademic" />
                    </div>
                </form>

                <!--PROFESSIONAL CERTIFICATE-->
                <form @submit.prevent="submit3" enctype="multipart/form-data">
                <div class="user-data" v-if="step_3">
                    <label for="">Organisation: </label>
                    <input type="text" class="form-control user-input" v-model="professional_school" placeholder="Enter organisation">
                    
                    <label for="">Course title: </label>
                    <input type="text" class="form-control user-input" v-model="professional_course_title" placeholder="Enter course title">
                </div>
                <div class="user-data" v-if="step_3">
                    <label for="">Year obtained: </label>
                    <select class="form-control user-input" v-model="professional_year" id="year3x">
                        <option value="">Select</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                    </select>

                    <label for="">Upload (Professional cert.): </label>
                    <input type="file" ref="profCertInput" id="image_input"  class="form-control user-input" @change="pickProfCert" />
                </div>
                </form>

                <!--PROFESSIONAL MEMBERSHIP-->
                <form @submit.prevent="submit4" enctype="multipart/form-data">
                <div class="user-data" v-if="step_4">
                    <label for="">Organisation:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="membership_school" placeholder="Enter organisation">
                    
                    <label for="">Course title:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="membership_course_title" placeholder="Enter course title">
                </div>
                <div class="user-data" v-if="step_4">
                    <label for="">Year obtained:<span style="color: red">*</span> </label>
                    <select class="form-control user-input" v-model="membership_year" id="year4x">
                        <option value="">Select</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                    </select>

                    <label for="">Upload(Membership cert.): </label>
                    <input type="file" ref="profMembInput" id="image_input"  class="form-control user-input" @change="pickProfMemb"  />
                </div>
                </form>

                <!--WORK EXPERIENCE-->
                <div class="user-data" v-if="step_5">
                    <label for="">Job Title:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="work_title" placeholder="Enter job title">
                    
                    <label for="">Organisation:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="work_organisation" placeholder="Enter organistion">

                    <label for="">Role:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="work_role" placeholder="Enter your job role">

                </div>
                <div class="user-data" v-if="step_5">
                    <label for="">Description:<span style="color: red">*</span> </label>
                    <textarea name="" id="" cols="30" rows="10" class="form-control user-input" v-model="work_description" placeholder="Enter job description"></textarea>

                    <label for="">Currently work here:<span style="color: red">*</span> </label>
                    <select class="form-control user-input" style="width: 50px!important" v-model="work_here" @change="workHere()">
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    <div v-if="is_work_year" style="display: flex; flex-direction: row; align-items: center">
                        <label for="">Since:<span style="color: red">*</span> </label>
                        <input type="date" class="form-control user-input" v-model="work_year">
                    </div>
                </div>
               
                <!--LEADERSHIP EXPERIENCE-->
                <div class="user-data" v-if="step_6">
                    <label for="">Title:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="leadership_title" placeholder="Enter leadership title">
                    
                    <label for="">Organisation:<span style="color: red">*</span> </label>
                    <input type="text" class="form-control user-input" v-model="leadership_organisation" placeholder="Enter organistion">
                </div>
                <div class="user-data" v-if="step_6">
                    <label for="">Year:<span style="color: red">*</span> </label>
                    <select class="form-control user-input" v-model="leadership_year" id="year6x">
                        <option value="">Select</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                    </select>

                    <label for="">Role: </label>
                    <input type="text" class="form-control user-input" v-model="leadership_role" placeholder="Enter your responsibility">
                </div>
                

                 <!--AWARDS-->
                <div class="user-data" v-if="step_7">
                    <label for="">Title:</label>
                    <input type="text" class="form-control user-input" v-model="award_title" placeholder="Enter award title">
                    
                    <label for="">Organisation: </label>
                    <input type="text" class="form-control user-input" v-model="award_organisation" placeholder="Enter organistion">
                </div>
                <div class="user-data" v-if="step_7">
                    <label for="">Year obtained: </label>
                    <select class="form-control user-input" v-model="award_year" id="year7x">
                        <option value="">Select</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>
                        <option value="1999">1999</option>
                        <option value="1998">1998</option>
                        <option value="1997">1997</option>
                        <option value="1996">1996</option>
                        <option value="1995">1995</option>
                        <option value="1994">1994</option>
                        <option value="1993">1993</option>
                        <option value="1992">1992</option>
                        <option value="1991">1991</option>
                        <option value="1990">1990</option>
                    </select>

                    <label for="">Description: </label>
                    <textarea name="" id="" cols="30" rows="10" class="form-control user-input" v-model="award_description" placeholder="Enter description"></textarea>
                   
                </div>
                
                <div class="user-data" v-if="step_8">
                    Thank you for completing your personal record. Please proceed to submit your application.
                </div>

                <div class="inputs" v-if="submit_1">
                    <button id="submit1" class="btn btn-danger" :disabled="personalData"   @click="submit1()">{{ stepTitle[0].step2 }} <i class="fa fa-arrow-right"></i></button>
                </div>
                <div class="inputs" v-if="submit_2">
                    <span style="cursor: pointer" @click="backtoPersonalData"><i class="fa fa-arrow-left"></i> Back  &nbsp; | &nbsp; </span> 
                  <button id="submit2" class="btn btn-danger" :disabled="academicRecord"  @click="submit2()">{{ stepTitle[0].step3 }} <i class="fa fa-arrow-right"></i></button>
                </div>
                <div class="inputs" v-if="submit_3">
                    <span style="cursor: pointer" @click="backtoAcademicRecord"><i class="fa fa-arrow-left"></i> Back  &nbsp; | &nbsp; </span> 
                     <button id="submit3" class="btn btn-danger" :disabled="professionalCertificate"   @click="submit3()">{{ stepTitle[0].step4 }} <i class="fa fa-arrow-right"></i></button> <span @click="submit3_skip()" class="skip"> &nbsp;Skip >> </span>
                </div>
                <div class="inputs" v-if="submit_4">
                    <span style="cursor: pointer" @click="backtoProfCert"><i class="fa fa-arrow-left"></i> Back  &nbsp; | &nbsp; </span> 
                    <button id="submit4" class="btn btn-danger" :disabled="professionalMembership"  @click="submit4()">{{ stepTitle[0].step5 }} <i class="fa fa-arrow-right"></i></button> <span @click="submit4_skip()" class="skip"> &nbsp;Skip >> </span>
                </div>
                <div class="inputs" v-if="submit_5">
                    <span style="cursor: pointer" @click="backtoProfMem"><i class="fa fa-arrow-left"></i> Back  &nbsp; | &nbsp; </span> 
                    <button id="submit5" class="btn btn-danger" :disabled="workExperience"   @click="submit5()">{{ stepTitle[0].step6 }} <i class="fa fa-arrow-right"></i></button>
                </div>
                <div class="inputs" v-if="submit_6">
                    <span style="cursor: pointer" @click="backtoWorkExp"><i class="fa fa-arrow-left"></i> Back  &nbsp; | &nbsp; </span> 
                    <button id="submit5" class="btn btn-danger" :disabled="leadershipExperience"  @click="submit6()">{{ stepTitle[0].step7 }} <i class="fa fa-arrow-right"></i></button> <span @click="submit6_skip()" class="skip"> &nbsp;Skip >> </span>
                </div>
                <div class="inputs" v-if="submit_7">
                    <span style="cursor: pointer" @click="backtoLeadershipExp"><i class="fa fa-arrow-left"></i> Back  &nbsp; | &nbsp; </span> 
                    <button id="submit5" class="btn btn-danger" :disabled="awardDisable"  @click="submit7()">{{ stepTitle[0].step8 }} <i class="fa fa-arrow-right"></i></button> <span @click="submit7_skip()" class="skip"> &nbsp;Skip >> </span>
                </div>
                <div class="inputs" v-if="submit_button">
                    <button id="submit4" class="btn btn-danger btn-block"  @click="submit_form()" v-if="!loading">Submit</button>
                    <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
            <div class="apptitude-test" v-if="is_apptitude_test">
                <div class="apptitude-test-instruction" v-if="is_apptitude_instruction">
                    <h5><u>Aptitude Test Instruction</u></h5>
                    <span class="instruction">
                        Welcome to the Aptitude Test. This test is designed to assess your problem-solving, critical thinking, and numerical reasoning skills. Please read and follow these instructions carefully:
                        <ol>
                            <li>
                                Test Format: This aptitude test consists of multiple-choice questions. You will be presented with a series of questions, and you must select the most appropriate answer from the options provided.
                            </li>
                            <li>
                                Time Limit: You will have a specific time limit to complete the test. Please manage your time wisely, as unanswered questions will not be scored.
                            </li>
                            <li>
                                Question Difficulty: Questions may vary in difficulty. You do not have to answer the questions in order, so if you find a question too challenging, feel free to move on and return to it later if time allows.
                            </li>
                            <li>
                                Answering: To answer a question, click on the corresponding radio button next to your chosen option. Make sure to review your selections before submitting your final answers.
                            </li>
                            <li>
                                Navigating the Test: You can navigate between questions using the "Next" and "Previous" buttons. However, once you move to the next question, you cannot return to previous questions. Please be attentive.
                            </li>
                            <li>
                                Submission: Once you have answered all the questions to your satisfaction, click the "Submit" button to finalize your test. You will not be able to make any changes after submission.
                            </li>
                            <li>
                                Honesty: Please answer all questions honestly and to the best of your ability. This test is designed to evaluate your skills and aptitude accurately.
                            </li>
                            <li>
                                External Resources: Do not use any external resources such as calculators, notes, or the internet while taking the test. This test should reflect your individual abilities.
                            </li>
                            <li>
                                Distractions: Find a quiet and comfortable place to take the test to minimize distractions.
                            </li>
                            <li>
                                Technical Issues: In case of any technical issues, please contact the test administrator immediately for assistance.
                            </li>
                            <li>
                                Accessibility: If you require any special accommodations due to a disability or other reasons, please inform the test administrator in advance.
                            </li>
                            <li>
                                Scoring: Your test results will be evaluated after completion, and you will receive feedback on your performance at a later time, as determined by the test administrator.
                            </li>
                        </ol>
                            Thank you for participating in the Aptitude Test. We wish you the best of luck!
                            <p>&nbsp;</p>
                    </span>
                    <button id="payment-button" class="btn btn-danger get-started" @click="start_test" >start test <i class="fa fa-arrow-right"></i></button>
                </div>
                <div class="apptitude-test-board" v-if="is_apptitude_board">
                    <div class="timer"><i class="fas fa-clock"></i> <span class="badge badge-danger">{{ formattedTime }}</span>
                    | You have <span class="badge badge-danger">{{ total_questions }}</span> questions to answer
                    </div>
                    <p>&nbsp;</p>
                    <!-- <div class="apptitude-test-progress" style="height: 4px;">
                        <div class="progress-bar bg-info" role="progressbar" :style="{ width: question_value }" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div> -->
                    <div v-for="(data, index) in all_questions" :key="index">
                        <div class="apptitude-test-counter">
                          <p class="step-title">
                            <span class="badge badge-danger">{{ index + 1 }}</span> - {{ data.questions }}
                          </p>
                        </div>
                        <div class="apptitude-test-option">
                          <div class="form-check-inline" v-for="answer in [data.answer1, data.answer2, data.answer3, data.answer4]" :key="answer">
                            
                              <input type="radio" class="form-check-input" :name="`optradio_${index}`" :value="{ questionId: data.questionsid, answerId: answer, correctAnswer: data.correct_answer, scoreId: data.score }" v-model="selectedAnswers[index]">{{ answer }}
                            
                          </div>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                      <button id="payment-button" class="btn btn-danger get-started" :disabled="!areAllQuestionsAnswered" @click="submit_test">submit <i class="fa fa-arrow-right"></i></button>
                                      
                </div>
                <div class="apptitude-test-instruction" v-if="is_apptitude_score">
                    <h5><u>Aptitude Test Result</u></h5>
                    <span class="instruction">
                        Thank you for participating in the apptitude test. Find below your result.
                        <ol>
                            <li v-for="(data, index) in testResults" :key="index">
                                Question: {{ data.questions }} <br> 
                                Attempted Answer: <span class="badge badge-primary">{{ data.answers_id }}</span> <br> 
                                Correct Answer: <span class="badge badge-success">{{ data.correct_answer }}</span> <br>
                                Score: <span class="badge badge-info">{{ data.totalScore}}</span>
                            </li>
                        </ol>
                        Total Score: <span class="badge badge-warning">{{ calculateTotalScore }}</span>
                            
                            <p>&nbsp;</p>
                    </span>
                    <button id="payment-button" class="btn btn-danger get-started" @click="test_continue" >continue <i class="fa fa-arrow-right"></i></button>
                </div>
            </div>
            <div class="essay-test" v-if="is_essay_test">
                <div class="essay-test-instruction" v-if="is_essay_instruction">
                    <h5><u>Essay Writing Instruction</u></h5>
                    <span class="instruction">
                        Welcome to the Essay Test. This test is designed to assess your problem-solving, critical thinking, and numerical reasoning skills. Please read and follow these instructions carefully:
                        <ol>
                            <li>
                                Test Format: This aptitude test consists of multiple-choice questions. You will be presented with a series of questions, and you must select the most appropriate answer from the options provided.
                            </li>
                            <li>
                                Time Limit: You will have a specific time limit to complete the test. Please manage your time wisely, as unanswered questions will not be scored.
                            </li>
                            <li>
                                Question Difficulty: Questions may vary in difficulty. You do not have to answer the questions in order, so if you find a question too challenging, feel free to move on and return to it later if time allows.
                            </li>
                            <li>
                                Answering: To answer a question, click on the corresponding radio button next to your chosen option. Make sure to review your selections before submitting your final answers.
                            </li>
                            
                        </ol>
                            Thank you for participating in the Essay Writing. We wish you the best of luck!
                            <p>&nbsp;</p>
                    </span>
                    <button id="payment-button" class="btn btn-danger get-started" @click="start_essay" >see essay questions <i class="fa fa-arrow-right"></i></button>
                </div>
                <div class="essay-test-board" v-if="is_essay_board">                   
                    <div class="essay-test-option" v-if="is_essay_questions">
                        <h5>Choose one essay to write (60 marks) </h5>
                        <div class="form-check-inline" v-for="(data, index) in all_essay_questions" :key="index">
                            <label class="form-check-label">
                              <input type="radio" class="form-check-input" v-model="submittedEssay" name="essayradio" :value="data.questions"> {{ data.questions }}
                            </label>
                          </div>
                         
                          <p>&nbsp;</p>
                          <button id="payment-button" class="btn btn-danger get-started" :disabled="disableEssay" @click="answer_essay" >start essay <i class="fa fa-arrow-right"></i></button>
                    </div>
                    <div class="essay-test-option" v-if="is_answer_essay">
                        <div class="timer"><i class="fas fa-clock"></i> {{formattedTime}}</div>
                        <span class="title">Essay Topic: {{ submittedEssay}}</span> 
                        <div class="form-group">
                            <label for="comment">Essay answer:</label>
                            <textarea class="form-control" rows="10" id="comment" placeholder="Write your essay answer..." v-model="essayAnswer" @input="checkWordCount"></textarea>
                            <p>{{ wordCount }}/600 words</p>
                        </div> 
                        <button id="payment-button" class="btn btn-danger get-started" :disabled="submitEssay" @click="submit_essay" >submit essay <i class="fa fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="application-fee" v-if="is_application_fee">
                <p class="fee-header"></p>
                <p class="fee">The application fee cost $10</p>
                <p class="fee-header">Terms</p>
                <p class="payment-instruction">The application fee covers the administrative costs associated with processing and reviewing applications, such as evaluating transcripts, test scores, letters of recommendation, and other application materials. It is therefore, non-refundable</p>
                <div class="form-check-inline">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" v-model="accept_terms" @change="isSubmitButtonDisabled"> <span class="accept">Accept the terms and conditions</span>
                    </label>
                </div>
                <p>&nbsp;</p>
                <span v-if="is_terms_accept">
                    <div class="btn btn-danger get-started">
                        <flutterwave-pay-button 
                          :tx_ref="generateReference()"
                          :amount=amount
                          currency="USD"
                          payment_options="card,ussd"
                          redirect_url="https://app.fordaxbschool.com/fee-payment-successful"
                          class="class-name"
                          style=""
                          :meta="{
                            counsumer_id: '7898',
                            consumer_mac: 'kjs9s8ss7dd'
                          }"
                          :customer="{
                            name: firstname,
                            email: email_address,
                            phone_number: phone_number
                          }"
                          :customizations="{
                            title: 'Fordax Business School',
                            description: 'Application Fee Payments',
                            logo : 'https://app.fordaxbschool.com/logox.png'
                          }"
                          :callback="makePaymentCallback"
                          :onclose="closedPaymentModal"
                        >
                          Pay
                        </flutterwave-pay-button>
                    </div>  
                    <!-- <flutterwave-pay-button class="btn btn-danger get-started"  v-bind="paymentData">Click To Pay</flutterwave-pay-button> -->
                </span>
                <span v-else>

                </span>               
            </div>
        </div>
        <div class="container-down">
           
        </div>
        <page-loader :is-loading="isLoading"></page-loader>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
       
    </div>
</template>
<script>
import swal from 'sweetalert2';
import {FlutterwavePayButton} from "flutterwave-vue-v3";
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "MbaApplication",
    components: {
        PageLoader,
        FlutterwavePayButton
    },

    data(){
        return {

            // personal data
            isLoading: false,
            loading: false,
            loading1: false, 
            is_personal_information: false,
            email_address: '',
            phone_number: '',
            user_id: '',
            firstname: '',
            lastname: '',
            address:'',
            dob: '',
            founder_cofounder: '',
            company: '',
            company_description: '',
            your_goal: '',
            year_founded: '',
            company_website: '',
            selectedFile: '',
            selectedResume: '',
            
            // academic record
            academic_course_title: '',
            academic_year: '',
            academic_school: '',
            selectedAcademic: '',

            // profesional certificate
            professional_course_title: '',
            professional_year: '',
            professional_school: '',
            selectedProfCert: '',

            // professional memberships
            membership_course_title: '',
            membership_school: '',
            membership_year: '',
            selectedProfMemb: '',

            // work experience
            work_title: '',
            work_organisation: '',
            work_role: '',
            work_year: '',
            work_description: '',
            work_here: 'Yes',
            is_work_year: true,

            // leadership experience
            leadership_organisation: '',
            leadership_role: '',
            leadership_title: '',
            leadership_year: '',

            // award
            award_title: '',
            award_organisation: '',
            award_year: '',
            award_description: '',

            // online presence
            facebook: '',
            linkedin: '',
            twitter: '',

            is_founder: true,
            previewImage: '',

            // personal information
            stepTitle: [
                {
                    step1: "Personal Data",
                    step2: "Academic Record",
                    step3: "Professional Certificates",
                    step4: "Professional Memberships",
                    step5: "Work Experience",
                    step6: "Leadership Experience",
                    step7: "Awards",
                    step8: "Submit",
                }
            ],
            stepValues: [
                {
                    value1: "15%",
                    value2: "30%",
                    value3: "45%",
                    value4: "60%",
                    value5: "75%",
                    value6: "90%",
                    value7: "100%",
                }
            ],
            stepValues2: [
                {
                    value1: "25%",
                    value2: "50%",
                    value3: "75%",
                    value4: "100%",
                }
            ],

            is_step_1: true,
            is_step_2: false,
            is_step_3: false,
            is_step_4: false,
            
            step_1: true,
            step_2: false,
            step_3: false,
            step_4: false,
            step_5: false,
            step_6: false,
            step_7: false,
            step_8: false,

            step_number: 1,
            step_title: '',
            step_value: '',
            step_value2: '',
            submit_1: true,
            submit_2: false,
            submit_3: false,
            submit_4: false,
            submit_5: false,
            submit_6: false,
            submit_7: false,
            submit_button: false,

            // apptitude test
            is_apptitude_test: true,
            is_apptitude_instruction: true,
            is_apptitude_board: false,
            is_apptitude_score: false,
            all_questions: [],
            selectedAnswers: [],
            total_questions: '',
            question_number: 1,
            question_value: '',
            question_title: 'What is the meaning of Journal',
            testOptions: '',

            totalSeconds: 0, // Initialize the total seconds
            timer: null,
            //selectedAnswers: [],
            appStatus: '',
            testResults: null,
            totalScore: 0,

            // essay
            is_essay_test: false,
            is_essay_instruction: false,
            is_essay_board: false,
            is_answer_essay: false,
            is_essay_questions: true,
            essayAnswer: '',
            submittedEssay: '',
            wordCount: 0,
            maxWordCount: 600,
            all_essay_questions: '',

            // application fee
            is_application_fee: false,
            accept_terms: '',

            appid: '',

            // local storage
            app_progress_status: '',
            app_progress_status2: '',
            programme_name: '',
            app_status: '',
            app_test_status: '',
            essay_status: '',
            app_fee_status: '',
            sch_fee_status: '',
            App_ID: '',

            // APPLICATION REQUEST
            application_request: '',
            application_requestid: '',

            // TEST TYPE
            test_typeid: '',

            is_terms_accept: false,
            recordid: '',
            amount: '10',

        };
    },

    beforeMount(){
        this.step_title = this.stepTitle[0].step1
        this.step_value = this.stepValues[0].value1
        this.step_value2 = this.stepValues2[0].value1
        this.get_test_typeid()
    },
    mounted(){
      
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        
        this.app_status = localStorage.getItem("app_status");
        this.app_test_status = localStorage.getItem("app_test_status");
        this.essay_status = localStorage.getItem("essay_status");
        this.app_fee_status = localStorage.getItem("app_fee_status");
        this.sch_fee_status = localStorage.getItem("sch_fee_status");
        this.appid = localStorage.getItem("appid");

        this.fetch_app_progress(this.appid, this.user_id)
        this.check_application_request(this.$route.params.title)

        // Update the paymentData with dynamic values
        this.paymentData.customer.name = `${this.firstname} ${this.lastname}`;
        this.paymentData.customer.email = this.email_address;
        this.paymentData.customer.phone_number = this.phone_number;
  

    },
    created(){

    },
    computed:{

        
        formattedTime() {
            const displayHours = Math.floor(this.totalSeconds / 3600);
            const displayMinutes = Math.floor((this.totalSeconds % 3600) / 60);
            const displaySeconds = this.totalSeconds % 60;
            return `${displayHours.toString().padStart(2, '0')} : ${displayMinutes.toString().padStart(2, '0')} : ${displaySeconds.toString().padStart(2, '0')}`;
        },

        personalData(){
            return (
                this.address === '' ||
                this.dob === '' ||
                this.your_goal === ''
            );
        },
        academicRecord() {
            return (
            this.academic_course_title === '' ||
            this.academic_year === '' ||
            this.academic_school === '' ||
            this.selectedAcademic === ''
            );
        },
        professionalCertificate() {
            return (
                this.professional_course_title === '' ||
                this.professional_year === '' ||
                this.professional_school === ''
            );
        },
        professionalMembership() {
            return (
                this.membership_course_title === '' ||
                this.membership_school === '' ||
                this.membership_year === ''
            );
        },
        workExperience(){
            //if(this.work_here == 'Yes'){
                return (
                this.work_title === '' ||
                this.work_organisation === '' ||
                this.work_role === '' ||
                this.work_here === '' ||
                //this.work_year === '' ||
                this.work_description === ''
                );
            //}
            // else if(this.work_here == 'No'){
            //     return (
            //     this.work_title === '' ||
            //     this.work_organisation === '' ||
            //     this.work_role === '' ||
            //     this.work_here === '' ||
            //     this.work_description === ''
            //     );
            // }
        },
        leadershipExperience(){
            return (
                this.leadership_organisation === '' ||
                this.leadership_role === '' ||
                this.leadership_title === '' ||
                this.leadership_year === '' 
            );
        },
        awardDisable(){
            return (
                this.award_title === '' ||
                this.award_organisation === '' ||
                this.award_year === ''
            );
        },
        areAllQuestionsAnswered() {
            // Check if all questions have been answered
            return this.selectedAnswers.every(answer => answer !== undefined && answer !== null);
        },
        disableEssay(){
            return (
                this.submittedEssay === ''
            );
        },
        submitEssay(){
            return (
                this.essayAnswer === ''
            );
        },
        calculateTotalScore() {
            // Sum up the scores from all the data items
            return this.testResults.reduce((total, data) => total + (data.totalScore || 0), 0);
        },
    },
   
    methods:{

        async get_test_typeid(){
           const response = await this.$http.get('/fetch-apptitude-testtype', {

          })
          this.test_typeid = response.data.test_type
        },

        async check_application_request(application_id){
           const response = await this.$http.post('/fetch-application-request-bycategoryid', {
            categoryid: application_id,
          })

          this.application_request = response.data.applicationRequest
          this.application_requestid = this.application_request[0].application_requestid
        },

        async fetch_app_progress(app_id, user_id){
            this.isLoading = true;
            const response = await this.$http.post('/fetch-app-progress', {
                appid: app_id,
                user_id: user_id
            })
            this.isLoading = false;
            this.app_progress_status = response.data.appProgress
            this.app_progress_status2 = response.data.appProgress2
            this.programme_name = response.data.programme_name
            this.App_ID = response.data.appID;

           //console.log(this.app_progress_status2)

            if (this.app_progress_status == 1){
                this.is_personal_information = true
                this.is_apptitude_test = false,
                this.is_apptitude_instruction = false,
                this.is_essay_test = false,
                this.is_essay_instruction = false,
                this.is_application_fee = false
                this.step_value2 = this.stepValues2[0].value1
                this.is_step_1 = true
                this.is_step_3 = false
                this.is_step_4 = false
                this.is_step_2 = false
            }
            if (this.app_progress_status == 2) {
                this.is_personal_information = false
                this.is_apptitude_test = true,
                this.is_apptitude_instruction = true
                this.is_essay_test = false,
                this.is_essay_instruction = false,
                this.is_application_fee = false
                this.step_value2 = this.stepValues2[0].value2
                this.is_step_1 = false
                this.is_step_3 = false
                this.is_step_4 = false
                this.is_step_2 = true
            }
            if (this.app_progress_status == 3) {
                this.is_personal_information = false
                this.is_apptitude_test = false,
                this.is_apptitude_instruction = false,
                this.is_essay_test = true,
                this.is_essay_instruction = true
                this.is_application_fee = false
                this.step_value2 = this.stepValues2[0].value3
                this.is_step_1 = false
                this.is_step_3 = true
                this.is_step_4 = false
                this.is_step_2 = false
            }
            if (this.app_progress_status == 4) {
                this.is_personal_information = false
                this.is_apptitude_test = false,
                this.is_apptitude_instruction = false,
                this.is_essay_test = false,
                this.is_essay_instruction = false,
                this.is_application_fee = true
                this.step_value2 = this.stepValues2[0].value4
                this.is_step_1 = false
                this.is_step_3 = false
                this.is_step_4 = true
                this.is_step_2 = false
            }
           

            // APP PROGRESS STATUS 2
            if (this.app_progress_status2 == 1){
                this.step_1 = true
                this.step_2 = false
                this.step_3 = false
                this.submit_1 = true
                this.submit_2 = false
                this.step_number = 1
                this.step_title = this.stepTitle[0].step1
                this.step_value = this.stepValues[0].value1
            }
            if (this.app_progress_status2 == 2){
                this.step_2 = true
                this.step_1 = false
                this.step_3 = false
                this.submit_1 = false
                this.submit_2 = true
                this.step_number = 2
                this.step_title = this.stepTitle[0].step2
                this.step_value = this.stepValues[0].value2
            }
            if (this.app_progress_status2 == 3){
                this.step_2 = false
                this.step_3 = true
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = true
                this.step_number = 3
                this.step_title = this.stepTitle[0].step3
                this.step_value = this.stepValues[0].value3
            }
            if (this.app_progress_status2 == 4){
                this.step_4 = true
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = true
                this.step_number = 4
                this.step_title = this.stepTitle[0].step4
                this.step_value = this.stepValues[0].value4
            }
            if (this.app_progress_status2 == 5){
                this.step_5 = true
                this.step_4 = false
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = false
                this.submit_5 = true
                this.step_number = 5
                this.step_title = this.stepTitle[0].step5
                this.step_value = this.stepValues[0].value5
            }
            if (this.app_progress_status2 == 6){
                this.step_6 = true
                this.step_5 = false
                this.step_4 = false
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = false
                this.submit_5 = false
                this.submit_6 = true
                this.step_number = 6
                this.step_title = this.stepTitle[0].step6
                this.step_value = this.stepValues[0].value6
            }
            if (this.app_progress_status2 == 7){
                this.step_7 = true
                this.step_6 = false
                this.step_5 = false
                this.step_4 = false
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = false
                this.submit_5 = false
                this.submit_6 = false
                this.submit_7 = true
                this.step_number = 7
                this.step_title = this.stepTitle[0].step7
                this.step_value = this.stepValues[0].value7
            }
            if (this.app_progress_status2 == 8){
                this.step_8 = true
                this.step_7 = false
                this.step_6 = false
                this.step_5 = false
                this.step_4 = false
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = false
                this.submit_5 = false
                this.submit_6 = false
                this.submit_7 = false
                this.submit_button = true
                this.step_number = 8
                this.step_title = this.stepTitle[0].step8
                this.step_value = this.stepValues[0].value8
            }
        },

        years() {
       
            var year_satart = 1940;
            var year_end = (new Date).getFullYear(); // current year
            //var year_selected = 1992;

            var option = '';
            option = '<option value="">Select</option>'; // first option

                    for (var i = year_satart; i <= year_end; i++) {
                        // var selected = (i === year_selected ? ' selected' : '');
                        //var arabic_number = to_arabic(i); // number convert to arabic digit
                        option += '<option value="' + i + '"' + i + '>' + i + '</option>';
                    }

                    document.getElementById("year").innerHTML = option;
        },

        years2() {
       
            var year_satart = 1940;
            var year_end = (new Date).getFullYear(); // current year
            //var year_selected = 1992;

            var option = '';
            option = '<option value="">Select</option>'; // first option

                    for (var i = year_satart; i <= year_end; i++) {
                        // var selected = (i === year_selected ? ' selected' : '');
                        //var arabic_number = to_arabic(i); // number convert to arabic digit
                        option += '<option value="' + i + '"' + i + '>' + i + '</option>';
                    }

                    document.getElementById("year2").innerHTML = option;
        },

        toggle_founder(){
            if (this.founder_cofounder == 'None') {
                this.is_founder = false
            }
            else{
                this.years()
                this.is_founder = true
            }
        },
        async isSubmitButtonDisabled(){
            if( this.is_terms_accept == true){
                this.is_terms_accept = false
            }else {
                this.is_terms_accept = true

                this.payment_payload = {
                userid: this.user_id,
                appid: this.appid,
                amount: this.amount,
                transaction_ref: this.generateReference()
            }

                // trigger callback
                this.updateFeePayment()
            }
           
        },
        workHere(){
            if(this.work_here == 'Yes'){
                this.is_work_year = true
            }
            else if(this.work_here == 'No'){
                this.is_work_year = false
            }
        },
        pickFile() {
                this.selectedFile = this.$refs.fileInput.files[0];
                let input = this.$refs.fileInput;
                let file = input.files;
                
                if (file && file[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.previewImage = e.target.result;
                };
                reader.readAsDataURL(file[0]);
                this.$emit("input", file[0]);
                }
        },
        pickResume() {
            this.selectedResume = this.$refs.resumeInput.files[0];                     
        },
        pickAcademic() {
            this.selectedAcademic = this.$refs.academicInput.files[0];                     
        },
        pickProfCert() {
            this.selectedProfCert = this.$refs.profCertInput.files[0];                     
        },
        pickProfMemb() {
            this.selectedProfMemb = this.$refs.profMembInput.files[0];                     
        },
        async submit1() {
            if (this.step_1 == true) {

                this.loading1 = true

                const formData = new FormData();
                    formData.append('image1', this.selectedFile),
                    formData.append('application_requestid', this.application_requestid),
                    formData.append('firstname', this.firstname),
                    formData.append('lastname', this.lastname),
                    formData.append('email_address', this.email_address),
                    formData.append('phone_number', this.phone_number),
                    formData.append('address', this.address),
                    formData.append('dob', this.dob),
                    formData.append('founder_cofounder', this.founder_cofounder),
                    formData.append('company', this.company),
                    formData.append('company_description', this.company_description),
                    formData.append('year_founded', this.year_founded),
                    formData.append('company_website', this.company_website),
                    formData.append('facebook', this.facebook),
                    formData.append('linkedin', this.linkedin),
                    formData.append('twitter', this.twitter),
                    formData.append('your_goal', this.your_goal),
                    formData.append('user_id',  this.user_id),

                    this.response = await this.$http.post('/personal-data', formData
    
                    );
    
                    this.loading1 = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                    } else {
    
                        this.step_2 = true
                        this.step_1 = false
                        this.step_3 = false
                        this.submit_1 = false
                        this.submit_2 = true
                        this.step_number = 2
                        this.step_title = this.stepTitle[0].step2
                        this.step_value = this.stepValues[0].value2
                        this.appid = this.response.data.appid
                        await localStorage.setItem("appid", this.appid)
                    }
                      
            }
        },
        async submit2() {
            if (this.step_2 == true) {

                this.loading2 = true
                this.appid = localStorage.getItem("appid");

                const formData = new FormData();
                    formData.append('image2', this.selectedAcademic),
                    formData.append('academic_school', this.academic_school),
                    formData.append('academic_course_title', this.academic_course_title),
                    formData.append('academic_year', this.academic_year),
                    formData.append('appid', this.appid),
                    formData.append('user_id',  this.user_id),

                    this.response = await this.$http.post('/academic-record', formData

                    );

                    this.loading2 = false;

                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;

                    } else {

                        this.step_2 = false
                        this.step_3 = true
                        this.step_1 = false
                        this.submit_1 = false
                        this.submit_2 = false
                        this.submit_3 = true
                        this.step_number = 3
                        this.step_title = this.stepTitle[0].step3
                        this.step_value = this.stepValues[0].value3
                        this.appid = this.response.data.appid
                    }
                    
                }
               
        },
        async submit3() {
            if (this.step_3 == true) {

                    this.appid = localStorage.getItem("appid");

                    const formData = new FormData();
                    formData.append('image3', this.selectedProfCert),
                    formData.append('professional_school', this.professional_school),
                    formData.append('professional_course_title', this.professional_course_title),
                    formData.append('professional_year', this.professional_year),
                    formData.append('appid', this.appid),
                    formData.append('user_id',  this.user_id)

                    const response = await this.$http.post('/professional-certificate', formData

                    );

                    if (!response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            return;

                    } else {

                        this.step_4 = true
                        this.step_2 = false
                        this.step_3 = false
                        this.step_1 = false
                        this.submit_1 = false
                        this.submit_2 = false
                        this.submit_3 = false
                        this.submit_4 = true
                        this.step_number = 4
                        this.step_title = this.stepTitle[0].step4
                        this.step_value = this.stepValues[0].value4
                        this.appid = response.data.appid
                    }
                    
                }
                
        },
        async submit4() {
            if (this.step_4 == true) {

                const formData = new FormData();
                    formData.append('image4', this.selectedProfMemb),
                    formData.append('membership_school', this.membership_school),
                    formData.append('membership_course_title', this.membership_course_title),
                    formData.append('membership_year', this.membership_year),
                    formData.append('appid', this.appid),
                    formData.append('user_id',  this.user_id)

                    const response = await this.$http.post('/professional-membership', formData

                    );

                    if (!response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            return;

                    } else {

                        this.step_5 = true
                        this.step_4 = false
                        this.step_2 = false
                        this.step_3 = false
                        this.step_1 = false
                        this.submit_1 = false
                        this.submit_2 = false
                        this.submit_3 = false
                        this.submit_4 = false
                        this.submit_5 = true
                        this.step_number = 5
                        this.step_title = this.stepTitle[0].step5
                        this.step_value = this.stepValues[0].value5
                        this.appid = response.data.appid
                    }
            }
        },
        async submit5() {
            if (this.step_5 == true) {

                if(this.work_here == 'Yes'){
                    if(this.work_year == ''){
                        swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: 'Please specify work date',
                        });
                        return;
                    }
                }
                

                this.appid = localStorage.getItem("appid");

                const response = await this.$http.post('/work-experience', {
                    work_title: this.work_title,
                    work_organisation: this.work_organisation,
                    work_role: this.work_role,
                    work_description: this.work_description,
                    work_year: this.work_year,
                    appid: this.appid,
                    user_id: this.user_id,
                })

                    if (!response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            return;

                    } else {

                        this.step_6 = true
                        this.step_5 = false
                        this.step_4 = false
                        this.step_2 = false
                        this.step_3 = false
                        this.step_1 = false
                        this.submit_1 = false
                        this.submit_2 = false
                        this.submit_3 = false
                        this.submit_4 = false
                        this.submit_5 = false
                        this.submit_6 = true
                        this.step_number = 6
                        this.step_title = this.stepTitle[0].step6
                        this.step_value = this.stepValues[0].value6
                        this.appid = response.data.appid
                    }
            }
        },
        async submit6() {
            if (this.step_6 == true) {

                this.appid = localStorage.getItem("appid");

                const response = await this.$http.post('/leadership-experience', {
                    leadership_title: this.leadership_title,
                    leadership_organisation: this.leadership_organisation,
                    leadership_year: this.leadership_year,
                    leadership_role: this.leadership_role,
                    appid: this.appid,
                    user_id: this.user_id,
                })

                    if (!response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            return;

                    } else {

                        this.step_7 = true
                        this.step_6 = false
                        this.step_5 = false
                        this.step_4 = false
                        this.step_2 = false
                        this.step_3 = false
                        this.step_1 = false
                        this.submit_1 = false
                        this.submit_2 = false
                        this.submit_3 = false
                        this.submit_4 = false
                        this.submit_5 = false
                        this.submit_6 = false
                        this.submit_7 = true
                        this.step_number = 7
                        this.step_title = this.stepTitle[0].step7
                        this.step_value = this.stepValues[0].value7
                        this.appid = response.data.appid
                    }
                
            }
        },
        async submit7() {
            if (this.step_7 == true) {

                this.appid = localStorage.getItem("appid");

                const response = await this.$http.post('/award', {
                    award_title: this.award_title,
                    award_organisation: this.award_organisation,
                    award_year: this.award_year,
                    award_description: this.award_description,
                    appid: this.appid,
                    user_id: this.user_id,
                })

                    if (!response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: response.data.message,
                            });
                            return;

                    } else {

                        this.step_8 = true
                        this.step_7 = false
                        this.step_6 = false
                        this.step_5 = false
                        this.step_4 = false
                        this.step_2 = false
                        this.step_3 = false
                        this.step_1 = false
                        this.submit_1 = false
                        this.submit_2 = false
                        this.submit_3 = false
                        this.submit_4 = false
                        this.submit_5 = false
                        this.submit_6 = false
                        this.submit_7 = false
                        this.submit_button = true
                        this.step_number = 8
                        this.step_title = this.stepTitle[0].step8
                        this.step_value = this.stepValues[0].value8
                        this.appid = response.data.appid
                    }
            }
        },
        backtoPersonalData(){
            this.step_2 = false
            this.step_1 = true
            this.step_3 = false
            this.submit_1 = true
            this.submit_2 = false
            this.step_number = 1
            this.step_title = this.stepTitle[0].step1
            this.step_value = this.stepValues[0].value1
        },
        backtoAcademicRecord(){
            this.step_2 = true
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = true
            this.submit_3 = false
            this.step_number = 2
            this.step_title = this.stepTitle[0].step2
            this.step_value = this.stepValues[0].value2
        },
        backtoProfCert(){
            this.step_4 = false
            this.step_2 = false
            this.step_3 = true
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = true
            this.submit_4 = false
            this.step_number = 3
            this.step_title = this.stepTitle[0].step3
            this.step_value = this.stepValues[0].value3
        },
        backtoProfMem(){
            this.step_5 = false
            this.step_4 = true
            this.step_2 = false
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = false
            this.submit_4 = true
            this.submit_5 = false
            this.step_number = 4
            this.step_title = this.stepTitle[0].step4
            this.step_value = this.stepValues[0].value4
        },
        backtoWorkExp(){
            this.step_6 = false
            this.step_5 = true
            this.step_4 = false
            this.step_2 = false
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = false
            this.submit_4 = false
            this.submit_5 = true
            this.submit_6 = false
            this.step_number = 5
            this.step_title = this.stepTitle[0].step5
            this.step_value = this.stepValues[0].value5
        },
        backtoLeadershipExp(){
            this.step_7 = false
            this.step_6 = true
            this.step_5 = false
            this.step_4 = false
            this.step_2 = false
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = false
            this.submit_4 = false
            this.submit_5 = false
            this.submit_6 = true
            this.submit_7 = false
            this.step_number = 6
            this.step_title = this.stepTitle[0].step6
            this.step_value = this.stepValues[0].value6
        },

        async submit_form(){

            this.loading = true
            this.appid = localStorage.getItem("appid");

            const response = await this.$http.post('/submit-form', {
                  appid: this.appid,
                  user_id: this.user_id,
                })

            this.loading = false

                    if (!response.data.success) {
                        swal.fire({
                            icon: 'error',
                            width:450,
                            height:5,
                            title: "Error",
                            text: response.data.message,
                        });
                        return;

                    } else {

                        swal.fire({
                                icon: 'success',
                                width:450,
                                height:5,
                                title: "Success",
                                text: response.data.message,
                            });

                            this.is_personal_information = false
                            this.is_apptitude_test = true
                            this.is_apptitude_instruction = true
                            this.step_value2 = this.stepValues2[0].value2
                            this.is_step_1 = false
                            this.is_step_3 = false
                            this.is_step_4 = false
                            this.is_step_2 = true
                            return;
                }
        },

        submit3_skip(){
            this.step_4 = true
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = true
                this.step_number = 4
                this.step_title = this.stepTitle[0].step4
                this.step_value = this.stepValues[0].value4
        },
        submit4_skip() {
                this.step_5 = true
                this.step_4 = false
                this.step_2 = false
                this.step_3 = false
                this.step_1 = false
                this.submit_1 = false
                this.submit_2 = false
                this.submit_3 = false
                this.submit_4 = false
                this.submit_5 = true
                this.step_number = 5
                this.step_title = this.stepTitle[0].step5
                this.step_value = this.stepValues[0].value5
        },

        submit6_skip() {
            this.step_7 = true
            this.step_6 = false
            this.step_5 = false
            this.step_4 = false
            this.step_2 = false
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = false
            this.submit_4 = false
            this.submit_5 = false
            this.submit_6 = false
            this.submit_7 = true
            this.step_number = 7
            this.step_title = this.stepTitle[0].step7
            this.step_value = this.stepValues[0].value7
        },

        submit7_skip() {
            this.step_8 = true
            this.step_7 = false
            this.step_6 = false
            this.step_5 = false
            this.step_4 = false
            this.step_2 = false
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = false
            this.submit_4 = false
            this.submit_5 = false
            this.submit_6 = false
            this.submit_7 = false
            this.submit_button = true
            this.step_number = 7
            this.step_title = this.stepTitle[0].step7
            this.step_value = this.stepValues[0].value7
        },

        async start_test(){
            const response = await this.$http.post('/fetchall-questions', {
                app_typeid: this.test_typeid,
                appid: this.appid,
                userid: this.user_id
            })

            if (!response.data.success) {
                    swal.fire({
                        icon: 'error',
                        width:450,
                        height:5,
                        title: "Error",
                        text: response.data.message,
                    });
                    return;

                } else {

                    this.is_apptitude_instruction = false
                    this.is_apptitude_board = true
                    this.startCountdown(response.data.hour, response.data.mins)
                    this.all_questions = response.data.questions 
                    this.total_questions = this.all_questions.length
                    
                    this.selectedAnswers = new Array(this.all_questions.length) // Initialize an array to store selected answers

            }
        },

        areAllQuestionsAnswereds() {
            return this.selectedAnswers.every(answer => answer !== undefined && answer !== null);
        },

        async submit_test(){
        // if (this.areAllQuestionsAnswereds()) {
           const response = await this.$http.post('/submit-test', {
                user_id: this.user_id,
                appid: this.App_ID,
                submittedTests: this.selectedAnswers
            })

            this.is_apptitude_board = false
            this.is_apptitude_score = true
            this.testResults = response.data.testResults
        // }else {
        //     // Display an alert to the user if not all questions are answered
        //     alert('Please answer all questions before submitting.');
        // }
        },

        async test_continue(){
            
            this.is_apptitude_test = false
            this.is_apptitude_instruction = false
            this.is_apptitude_score = false
            this.is_apptitude_board = false
            this.is_essay_test = true,
            this.is_essay_instruction = true
            this.step_value2 = this.stepValues2[0].value3
            this.is_step_1 = false
            this.is_step_3 = true
            this.is_step_4 = false
            this.is_step_2 = false

        },

        async start_essay(){

             const response = await this.$http.post('/fetch-essay-questions', {
                app_typeid: this.test_typeid,
                appid: this.appid,
                userid: this.user_id

            })
            this.is_essay_board = true
            this.is_essay_questions = true
            this.is_essay_instruction = false
            this.startCountdown(response.data.hour, response.data.mins)
            this.all_essay_questions = response.data.questions 
        },

        answer_essay(){
            let msg = confirm("     You are about to answer the selected essay question, are you sure?");
            if (msg == true) {
                this.is_answer_essay = true
                this.is_essay_questions = false
            }
           
        },

        async submit_essay(){
            await this.$http.post('/submit-essay', {
                user_id: this.user_id,
                appid: this.App_ID,
                submittedEssay: this.submittedEssay,
                essayAnswer: this.essayAnswer
            })

            this.is_answer_essay = false
            this.is_essay_test = false
            this.is_essay_questions = false
            this.is_application_fee = true
            this.step_value2 = this.stepValues2[0].value4
            this.is_step_1 = false
            this.is_step_3 = false
            this.is_step_4 = true
            this.is_step_2 = false

        },

        startCountdown(hours, minutes) {
            this.totalSeconds = (hours * 3600) + (minutes * 60);
            this.updateTimer();
        },
        updateTimer() {
            if (this.totalSeconds > 0) {
                this.totalSeconds--;
                this.timer = setTimeout(this.updateTimer, 1000);
            }
        },

        checkWordCount() {
        const words = this.essayAnswer.split(/\s+/);
        this.wordCount = words.length;
        
        if (this.wordCount > this.maxWordCount) {
            this.essayAnswer = this.essayAnswer
            .split(/\s+/)
            .slice(0, this.maxWordCount)
            .join(" ");
            this.wordCount = this.maxWordCount;
            }
        },

        makePaymentCallback(response) {
            console.log(response)
        },
        closedPaymentModal() {
            console.log('payment is closed');
        },
        generateReference(){
            let date = new Date();
            return date.getTime().toString();
        },

        // update fee payment
        async updateFeePayment(){
            const response = await this.$http.post('/app-fee-payment', {
                payment_data: this.payment_payload
           })

            if (!response.data.success) {
                swal.fire({
                    icon: 'error',
                    width:450,
                    height:5,
                    title: "Error",
                    text: response.data.message,
                });
                return;
            }
            else {
                this.recordid = response.data.record_id
                await localStorage.setItem("recordid", this.recordid)
            }
        }

    }

}
</script>

<style lang="scss" scoped>
:root {
    //--font-family: 'Poppins', 'DM Sans';
    --font-family: 'DM Sans';
  }
.img-profile {
    width: 120px;
    height: 120px;
    background: url("../assets/photo_add.png");
    background-size: cover;
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
}
.file-input{
    opacity: 0;
    cursor: pointer;
}
.container-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: auto;
}
.box1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
    border-radius: 4px;
    font-family: var(--font-family);

}

.heading{
  padding-right: 0.4rem;
  font-size: 40px;
  font-weight: 700;
  color: #ff4d63;
}
.subtitle{
    padding-right: 0.4rem;
    font-size: 14px;
    color: #ff4d63;
    line-height: 1.3px;
  }

.container-middle{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    //width: 100%;
}
.progress{
    width: 100%;
}

.container-down{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 10px;
}

.get-started{
    width: 250px;
    height: 50px;
    background: #ff4d63;
    font-family: var(--font-family);
    text-transform: uppercase;
    color: #fff;
}

#progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: rgb(252, 103, 49);
    padding-left: 0px;
    margin-top: 0vh
}

#progressbar li {
    list-style-type: none;
    font-size: x-small;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: rgb(160, 159, 159)
}

#progressbar #step1:before {
    content: "";
    color: rgb(252, 103, 49);
    width: 5px;
    height: 5px;
    margin-left: 0px !important
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-left: 32%
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 32%
}

#progressbar #step4:before {
    content: "";
    color: #fff;
    width: 5px;
    height: 5px;
    margin-right: 0px !important
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: #ddd;
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh
}

#progressbar li:after {
    content: '';
    height: 2px;
    background: #ddd;
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 1px;
    z-index: 1
}
.progress-track {
    padding: 0 8%;
    width:100%
}
.bar-width{
    width:100%
}

#progressbar li:nth-child(2):after {
    margin-right: auto
}

#progressbar li:nth-child(1):after {
    margin: auto
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%
}

#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%
}

#progressbar li.active {
    color: black
}
#progressbar li.active:before,
#progressbar li.active:after {
    background: rgb(252, 103, 49)
}
.application-fee{
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 1.5rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(to bottom, #ff4d626a, rgba(237, 221, 221, 0.044));
    width: 100%;
    font-family: var(--font-family);
    font-size: 16px;
}
.fee-header{
    text-transform: capitalize;
    width: 25%;
    font-size: 24px;
    font-weight: 550;
    font-family: var(--font-family);
}
.payment-instruction{
    width: 50%;
    text-align: justify;
    font-family: var(--font-family);
}
.accept{
    font-size: 12px;
    font-family: var(--font-family);
}
.personal-information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(to bottom, #cccccc28, #cccccc35);
    width: 70%;
    font-family: var(--font-family);
    font-size: 14px;
    margin: 10px;
}
.user-data{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 450;
}
.user-input{
    height: 40px;
    border: 1px solid #fff;
    font-family: var(--font-family);
    font-size: 13px;
    margin: 10px;
}
.counter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 4px;
    height: 25px;
    border-radius: 3px;
    margin-bottom: 15px;
    font-size: 10px;
    font-family: var(--font-family);
    text-transform: uppercase;

    span{
        color: #ccc;
    }
    .step-title{
        font-weight: 550;
    }
}
.inputs{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
    border-radius: 3px;
}
.inputs-field{
    font-size: 13px;
    font-family: var(--font-family);
}
.btn-danger{
    text-transform: uppercase;
    font-size: 13px;
    font-family: var(--font-family);
}
.skip{
    font-size: 12px;
    font-weight: 550;
    margin-right: 10px;
    cursor: pointer;
}
.socials{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.social-input{
    width: 50%;
    height: 20px;
    background: #fff;
}
.timer{
    height: auto;
    width: auto;
    padding: 0.4rem;
}
.apptitude-test{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(to bottom, #cccccc28, #cccccc35);
    width: 70%;
    font-family: var(--font-family);
    font-size: 14px;
    margin: 10px;
}
.apptitude-test-counter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 4px;
    height: 35px;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: var(--font-family);

    span{
        color: #ccc;
    }
    .step-title{
        font-weight: 550;
        font-size: 16px;
        text-transform: capitalize;
    }
}
.apptitude-test-option{
    display: flex;
    flex-direction: column;
    font-size: 14px;
}
.apptitude-test-progress{
    width: 100%;
}
.instruction{
    text-align: justify;
    margin-bottom: 15px;
}
.form-check-inline{
    padding: 0.5rem;
}
.form-check-input{
    height: 15px;
    width: 15px;
    background: #ff4d63;
}
.essay-test{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5rem;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 20px 0 rgba(0, 0, 0, 0.19);
    background: linear-gradient(to bottom, #cccccc28, #cccccc35);
    width: 70%;
    font-family: var(--font-family);
    font-size: 14px;
    margin: 10px;
}
.essay-test-counter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    padding: 4px;
    height: 35px;
    border-radius: 3px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: var(--font-family);

    span{
        color: #ccc;
    }
    .step-title{
        font-weight: 550;
        font-size: 16px;
        text-transform: capitalize;
    }
}
.essay-test-option{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    text-align: justify;
}
.essay-test-progress{
    width: 100%;
}

@media screen and (max-width: 768px) {
    .box1 {
        margin-top: 50px;
    }
    .heading{
        font-size: 20px;
    }
    .subtitle{
        font-size: 14px;
    }
    .personal-information{
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 100%;
    }
    .user-data{
        display: flex;
        flex-direction: column;
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: 450;
    }
    .apptitude-test{
        width: 100%;
        font-family: var(--font-family);
        font-size: 12px;
    }
    .counter{
        font-size: 8px;
        .step-title{
            font-weight: 450;
        }
    }
    .apptitude-test-counter{
        font-size: 8px;
        .step-title{
            font-weight: 450;
            font-size: 9px;
        }
    }
    .essay-test{
        font-size: 12px;
        width: 100%;
    }
    .essay-test-option{
        font-size: 12px;
        text-align: justify;
    }
}
</style>