import Vue from 'vue'
import App from './App.vue'
import {newAxios} from './AxiosInstance'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Flutterwave from  'flutterwave-vue-v3'

Vue.prototype.$http =newAxios;
Vue.config.productionTip = false
Vue.use(Flutterwave, { publicKey: 'FLWPUBK_TEST-512e73fbe507f41f6ebbdd13dcc57537-X' } )

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
