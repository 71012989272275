<template>
    <div>
        <p>&nbsp;</p>
        <p class="welcome"> <router-link to="/dashboard"> <i class="fa fa-arrow-left"></i> Back </router-link> | <i class="fa fa-book"></i> Test Questions</p>
        <div class="row account-details">
          <div class="col-sm-1">  
          </div>
          <div class="col-sm-10">
            <div class="inputs">
                <v-autocomplete
                  required
                  v-model="category_name"
                  label="Programme category *"
                  :items="categorys"
                  variant="outlined"
                ></v-autocomplete>
            </div>
            <div class="inputs">
                <v-autocomplete
                  required
                  v-model="test_types"
                  label="Test type *"
                  :items="testType"
                  variant="outlined"
                ></v-autocomplete>
            </div>
            <div class="inputs">
                <input type="text" class="form-control inputs-field" placeholder="Enter test questions" v-model="questions_name">
            </div>
            <div class="inputs">
                <input type="text" class="form-control inputs-field" placeholder="Enter test score" v-model="score">
            </div>
                    
            <div class="inputs">
                <button id="submit2" class="btn btn-danger btn-block" :disabled="isCategoryEnabled"  @click="createCategory()" v-if="!loading">save</button>
                <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            </div>

            <div class="inputs table table-responsive">
              <h6>All Record</h6>
              <table class="shop-detail table table-bordered">
                <thead>
                  <tr>
                    <th>Sn</th>
                    <th>Programme</th>
                    <th>Test Type</th>
                    <th>Question</th>
                    <th>Score</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in questions" :key="index">
                    <td>{{ index + 1}}</td>
                    <td> 
                        <span class="shop-name">{{ data.category_name}}</span>
                    </td>
                    <td> 
                        <span class="shop-name">{{ data.test_type}}</span>
                    </td>
                    <td> 
                        <span class="shop-name">{{ data.questions}}</span>
                    </td>
                    <td> 
                        <span class="shop-name">{{ data.score}}</span>
                    </td>
                    <!-- <td> 
                        <div v-if="data.application_request_status == 1" class="badge badge-success">Open</div>
                        <div v-if="data.application_request_status == 0" class="badge badge-danger">Closed</div>
                    </td>
                    <td> 
                        <div v-if="data.programme_status == 1" class="badge badge-success">Open</div>
                        <div v-if="data.programme_status == 0" class="badge badge-danger">Closed</div>
                    </td> -->
                    <td style="cursor: pointer; font-size: 10px !important"><router-link :to="'/test-answers/' + data.questionsid "><i class="fa fa-plus"></i>Add Answers</router-link></td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
          <div class="col-sm-1">
          </div>
        </div>
       
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
  </template>
  <script>
  import swal from 'sweetalert2';
  //import 'quill/dist/quill.snow.css';
  //import { quillEditor } from 'vue-quill-editor';
  export default {
    
    name: "TestQuestions",
    components: {
        //quillEditor,
    },
  
    data(){
      return {
        
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
          category_name:'',
          programme_year: '',
          app_startdate: '',
          app_enddate: '',
          programme_startdate:'',
          programme_enddate: '',
          categorys:[],
          programmeYears: [],
          app_requests:[],
          response: '',
          editorOptions: {
            placeholder: 'Write something...',
        },
        years: [],
        apptitude_request: '',
        months: [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ],
        test_month: '',
        testMonth: [],
        hours: '',
        mins: '',
        testType:[],
        test_types:'',
        questions: [],
        questions_name: '',
      };
    },
  
    beforeMount(){
     
    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.fetchcategorys()
        this.fetchTestType()
        this.fetchTestQuestions()
    },
  
    created(){
          
    },
  
    computed: {
      isCategoryEnabled() {
        return (
            this.test_types === '' ||
            this.questions_name === '' ||
            this.category_name === '' ||
            this.score === '' 
        );
      },
    },
  
    methods: {

        generateYearList() { 
            const currentYear = new Date().getFullYear();
            const startYear = 1970;

            for (let year = currentYear; year >= startYear; year--) {
               // years.push(year);
                this.programmeYears.push({
                    text: year,
                    value: year
                })
            }
        },

        async createCategory() {
            try {

                    this.loading = true

                    const response =  await this.$http.post('/create-questions', {
                        programme_category_id: this.category_name,
                        aptitude_test_typeid: this.test_types,
                        questions: this.questions_name,
                        score: this.score,
                        addedby: this.user_id,
                    })
                    this.loading = false

                    if( !response.data.success ){
                        swal.fire({
                            icon: 'error',
                            title: 'Error',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
                        return;
                    }
                    else{
                        swal.fire({
                            icon: 'success',
                            title: 'Success',
                            width: 450,
                            height: 5,
                            text: response.data.message,
                        })
     
                        this.fetchTestQuestions()

                        return;
                    }
            } catch (err) {
              console.log(err);
        }
        },

        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })

                response.data.categories.forEach(el => {
                    this.categorys.push({
                        text: el.category_name,
                        value: el.programme_categoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchTestType() {
            try {
               const response =  await this.$http.get('/fetch-all-testtype', {
            })

            response.data.tests.forEach(el => {
                this.testType.push({
                    text: el.test_type,
                    value: el.aptitude_test_typeid
                })
            });
                
            } catch (err) {
              console.log(err);
          }
        },

        async fetchTestQuestions() {
            try {
               const response =  await this.$http.get('/fetch-questions', {
            })

            this.questions = response.data.questions
                
            } catch (err) {
              console.log(err);
          }
        },

        loadMonths(){
            this.months.forEach(el => {
                this.testMonth.push({
                    text: el,
                    value: el
                })
            });
        },

        async removeCategory(categoryid){
        let con = confirm("          You're about to remove this category. Are you sure?")
          if (con == true) {
            try {

              const response =  await this.$http.post('/delete-category', {
                category_id: categoryid,
              })

              if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                swal.fire({
                    icon: 'success',
                    title: 'Success',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                this.fetchcategorys()
                return;

              } catch (err) {
              console.log(err);
              }
          }
        },
    }
  
  }
  </script>
  <style lang="scss" scoped>
  :root {
    --font-family: 'Poppins', 'DM Sans';
  }
  .quill-container {
    height: 200px; /* Set the desired height here */
  }
.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
}
.shop-detail{
    font-size: 12px;
    font-family: var(--font-family);
}
.btn-danger{
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--font-family);
  background: #ff4d63;
  border: 0px;
  height: 45px;
}
  .welcome{
    font-family: var(--font-family);
    font-size: 17px;
    font-weight: 700;
    color: #170e0fac;
  }
  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.9rem;
    margin: 4px;
  }
  .account-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: var(--font-family);
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .wallet-detail{
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;
    h4{
      font-weight: 650;
    }
    .acct-d{
      line-height: 0.8;
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wallet-icon{
    font-size: 60px
  }
  .fund-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: var(--font-family);
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .inputs-field{
    font-size: 14px;
    font-family: var(--font-family);
    height: 45px;
}
  .content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box-icon{
    padding-left: 0.4rem;
    font-size: 35px;
    color:#637807;
  }
  .box-details{
    font-size: 14px;
    color:#170e0fac;
  }
  .box1:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box2:hover{
     transform: scale(1.1); 
     transition: transform 0.3s ease; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
   }
  .box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box3:hover{
      transform: scale(1.1); 
      transition: transform 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
      cursor: pointer;
   }
   .box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box4:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box5:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box6:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: var(--font-family);
      font-weight: 550;
      font-size: 10px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: var(--font-family);
      font-size: 12px;
    }
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: var(--font-family);
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: var(--font-family);
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  .add-fund{
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .btn-secondary{
    text-transform: uppercase;
  }
  
  .modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  }
  
  .modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  
    display: none;
  }
  
  a {
    text-decoration: none;
  }
  .shop-name{
   // font-weight: 650;
   // color: #637807;
  }
  @media screen and (max-width: 768px) {
    .counter{
      font-size: 13px;
      font-weight: 500 !important;
    }
    .welcome{
      font-family: var(--font-family);
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #170e0fac;
    }
    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.9rem;
    }
    .account-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px #170e0fac;
      border-radius: 4px;
      padding: 0.9rem;
      margin: 4px;
    }
    .account-balance{
      margin-bottom: 10px;
    }
  
    .box-details{
      padding: 0.4rem;
      line-height: 1.4px;
      .title{
        font-family: var(--font-family);
        font-weight: 550;
        font-size: 8px;
        text-transform: capitalize;
        color: #ff4d63;
      }
      .description{
        font-family: var(--font-family);
        font-size: 12px;
      }
    }
    
    .inputs{
      font-size: 12px;
    }
    .box1 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box2 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box3 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box4 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box5 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .continue{
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-right: 0.4rem;
      font-size: 30px;
      margin-top: -10px;
      color: rgb(237, 138, 108);
    }
  
    .modal-close-mobile {
      display: block;
    }
    .modal-close {
      display: none;
    }
  
    .account-content{ 
      margin: 10px;
      margin-right: 30px;
    }
  
  }
  
  </style>