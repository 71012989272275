<template>
    <div class="container-background">    
        <p>&nbsp;</p>   
        <div class="row plan-board">
            <div class="col-sm-2">
            </div>
            <div class="col-sm-8">
                <div class="plan2">
                    <div class="plan-text">
                        <p class="pay-type">payment successful</p>
                        <div class="deadline text-danger"><i class="fa fa-check-circle"></i></div>
                        <p class="dollar btn btn-outline-danger text-white"><router-link to="/dashboard">Continue <i class="fa fa-arrow-right"></i></router-link></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
            </div>
        </div>
        <p>&nbsp;</p>
        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
//import swal from 'sweetalert2';
import PageLoader from '../components/PageLoader.vue'
export default {

    name: "PaymentSuccessful2",
    components: {
        PageLoader,
    },

    data(){
        return {
            isLoading: false,
            email_address: '',
            phone_number: '',
            user_id: '',
            firstname: '',
            lastname: '',
            appid: '',
            app_details: '',
            offer_deadline: '',
            reject_offers: '',
            offer_deadline_status: '',
            admission_status: '',
            recordid: '',
        }
    },
    beforeMount(){
       
    },
    mounted(){
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.appid = localStorage.getItem("appid");
        this.recordid = localStorage.getItem("recordid");
        this.update_user_payment()
    },
    computed: {

    },

    methods:{

        async update_user_payment(){

          this.isLoading = true;
          await this.$http.post('/update-fee-payment', {
            recordid: this.recordid,
            userid: this.user_id,
            appid: this.appid,
          })
          this.isLoading = false;
        },

    }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }
.container-background{
    margin: 2px;
    background: #cccccc2d;
    height: auto;
}
.plan-title{
    background: #fff;
    padding: 1rem;
    margin: 10px;
    border-radius: 10px;
}
.title{
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: bold !important;
    color: #ff4d63;
}
.choose-plan{
    font-family: var(--font-family);
    font-size: 14px;
    text-transform: capitalize;
}
.plan-board{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    height: auto;
    background: #fff;
    padding: 1rem;
    margin: 10px;
    border-radius: 10px;
}
.plan2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    padding: 1.5rem;
    color: #000;
    border: solid 1px #ff4d63;

}
.plan-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p{
        line-height: 0.8px
    }
    .pay-type{
        font-size: 16px;
        text-decoration: underline;
        text-transform: capitalize;
    }
    .amount{
        font-family: var(--font-family);
        font-size: 13px;
        font-weight: bold;
        border-radius: 4px;
        padding: 0.3rem;
        border: solid 1px #ff4d63;
    }
    .bg{
        cursor: pointer;
        background: #fff;
    }
    .deadline{
        font-size: 80px;
        text-align: center;
        font-weight: bold;
    }
    .next-due-payment{
        font-size: 18px;
        text-align: left;
        font-weight: bold;
    }
    span{
        font-family: var(--font-family);
        font-size: 10px;
        font-weight: 400;
    }
    .dollar{
        font-family: var(--font-family);
        font-size: 12px;
        font-weight: 400;
        margin-top: 10px;
        padding: 0.7rem;
    }
}
.plan-button{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  
}
.pay-now{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:30px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    padding:10px;
    color: #fff !important;
}
.reject{
    cursor: pointer;
}
.next-payment{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}
.next-payment-date{
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 400;
    font-weight: bold;
}
.btn-secondary{
    background: #ff4d63;
    border: solid 0px;
}

.payment-history-section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-family);
    height: auto;
    background: #fff;
    padding: 1.5rem;
    border-radius: 10px;
}
.payment-history{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    height: 100px;
    overflow-y: auto;
}
.history-head{
    font-size: 14px;
    font-weight: bold;
    background: #ff4d63;
    padding: 0.4rem;
    border-radius: 5px;
}
.history-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
}
.history-content{
    font-family: var(--font-family);
    font-size: 12px;
    padding: 0.3rem;
}
.payment-receipts{
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    height: 100px;
    overflow-y: auto;
}
.receipts-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #ccc;
}
.receipts-content{
    font-family: var(--font-family);
    font-size: 13px;
    padding: 0.3rem;
}
.fa-calendar-alt{
    color: #ccc;
}
.fa-receipt{
    color: #ccc;
}
.print{
    color: #ff4d63;
    cursor: pointer
}

@media screen and (max-width: 768px) {
    .choose-plan{
        font-family: var(--font-family);
        font-size: 12px;
        text-transform: capitalize;
        text-align: center;
    }
    .plan2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 300px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 10px;
        padding: 1.5rem;
        margin-bottom: 10px;
    }

    .plan-text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p{
            line-height: 1px !important
        }
        .pay-type{
            font-size: 12px;
        }
        .amount{
            font-family: var(--font-family);
            font-size: 12px;
            font-weight: 500;
            border-radius: 4px;
            padding: 0.3rem;
            text-align: center;
        }
        span{
            font-family: var(--font-family);
            font-size: 10px;
            font-weight: 400;
        }
       
    }
    .history-head{
        margin: 10px;
        font-size: 14px;
        font-weight: bold;
        background: #ff4d63;
        padding: 0.4rem;
        border-radius: 5px;
    }
    .pay-now{
        display: flex;
        flex-direction: row;
        width: 100%;
        height:30px;
        border-radius: 5px;
        font-size: 10px;
        font-weight: 500;
        padding:10px;
        color: #fff !important;
        margin-left: 5px;
        text-align: center;
    }

   
}
</style>