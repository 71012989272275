<template>
    <div>
        <p>&nbsp;</p>
        <p class="welcome"> <router-link to="/dashboard"> <i class="fa fa-arrow-left"></i> Back </router-link> | <i class="fa fa-book"></i> Essay Writing</p>
        <div class="row account-details" v-if="isAddLessons">
            <div class="col-sm-0">  
            </div>
            <div class="col-sm-12">
              <div class="inputs table table-responsive">
                <div class="search-input">
                
                  <div class="inputs">
                    
                    <v-autocomplete
                      required
                      v-model="programme_year"
                      label="Programme year *"
                      :items="programmeYears"
                      variant="outlined"
                    ></v-autocomplete>
                  </div>
                  <div class="inputs">
                    <v-autocomplete
                      required
                      v-model="category_name"
                      label="Programme category *"
                      :items="categorys"
                      variant="outlined"
                    ></v-autocomplete>
                  </div>
                  <div class="inputs">
                    <v-autocomplete
                      required
                      v-model="test_type"
                      label="Test type *"
                      :items="testTypes"
                      variant="outlined"
                    ></v-autocomplete>
                  </div>
                  <div class="inputs">
                    <v-autocomplete
                      required
                      v-model="student_data"
                      label="Student name *"
                      :items="studentData"
                      variant="outlined"
                    ></v-autocomplete>
                  </div>
                 
                 
                <!-- <div class="inputs">
                    <v-autocomplete
                      required
                      v-model="course_title"
                      label="Course title *"
                      :items="courseTitle"
                      @change="getModule()"
                      variant="outlined"
                    ></v-autocomplete>
                </div> -->
                <!-- <div class="inputs">
                    <v-autocomplete
                      required
                      v-model="module_title"
                      label="Module title *"
                      :items="moduleTitle"
                      variant="outlined"
                    ></v-autocomplete>
                </div> -->
                
                </div>
                <div class="search-input">
                  <button id="submit2" class="btn btn-danger"  @click="fetchAllStudentsEssays()" v-if="!loading">Search</button>
                  <button class="btn btn-danger" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                </div>
                <p>&nbsp;</p>
                <h6>Current Students</h6>
                <table class="shop-detail table table-bordered">
                  <thead>
                    <tr>
                      <th>Sn</th>
                      <th>Programme Year</th>
                      <th>Students</th>
                      <th>Programme</th>
                      <th>Course</th>
                      <th>Module</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in essayLists" :key="index">
                      <td>{{ index + 1}}</td>
                      <td> 
                        <span class="shop-name">{{ data.programme_year}}</span>
                     </td>
                      <td> 
                        <span class="shop-name">{{ data.firstname}} {{ data.lastname}}</span>
                     </td>
                      <td> 
                          <span class="shop-name">{{ data.category_name}}</span>
                      </td>
                      <td> 
                          <span class="shop-name">{{ data.course_category_title}}</span>
                      </td>
                      <td>  
                          <span class="shop-name">{{ data.course_module_title}}</span>
                      </td>
                     
                      <td> 
                            <button class="btn btn-light btn-sm" @click="view_lessons(data.course_module_id)">View Lessons</button>
                      </td>

                    </tr>
                    </tbody>
                </table>
              </div>
               
            </div>
            <div class="col-sm-0">
            </div>
        </div>
        <div class="row account-details" v-if="lesson_lists">
            <div class="col-sm-1">  
            </div>
            <div class="col-sm-10">
                <button @click="addLesson()"><i class="fa fa-long-arrow-left"></i> <u>Back</u></button>
              <div class="inputs table table-responsive">
                <h6>Students Lessons</h6>
                <table class="shop-detail table table-bordered">
                  <thead>
                    <tr>
                      <th>Sn</th>
                      <th>Programme</th>
                      <th>Course</th>
                      <th>Lessons</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in studentsLessonLists" :key="index">
                      <td>{{ index + 1}}</td>
                      <td> 
                          <span class="shop-name">{{ data.category_name}}</span>
                      </td>
                      <td> 
                          <span class="shop-name">{{ data.course_category_title }}</span>
                      </td>
                      <td>  
                          <span class="shop-name">{{ data.course_lesson_title}}</span>
                      </td>
                      <td> 
                            <span v-if="data.start_course == 0" class="badge badge-info">Not Started</span>
                            <span v-if="data.start_course == 1" class="badge badge-primary">In-Progress</span>
                            <span v-if="data.start_course == 2" class="badge badge-success">Completed</span>
                      </td>
                    </tr>
                    </tbody>
                </table>
              </div>
            </div>
            <div class="col-sm-1">
            </div>
        </div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
    </div>
  </template>
  <script>
  //import swal from 'sweetalert2';
  //import 'quill/dist/quill.snow.css';
  //import { quillEditor } from 'vue-quill-editor';
  export default {
    name: "ViewEssayWriting",
    components: {
       // quillEditor,
    },
  
    data(){
      return {
        
          user_id: '',
          firstname: '',
          lastname: '',
          bank_name:'',
          account_name:'',
          account_number: '',
          
          loading: false,
          category_name: '',
          course_title: '',
          module_title: '',
          lesson_title: '',
          objective_title: '',
          description: '',
          categorys:[],
          response: '',
          editorOptions: {
            placeholder: 'Write something...',
        },
        essayLists: [],
        courseTitle: [],
        moduleTitle: [],
        lessonTitle: [],
        isAddLessons: true,
        lesson_lists: false,
        studentsLessonLists: [],
        student_data: '',
        studentData: [],
        programmeYears: [],
        programme_year: '',
        testTypes: [],
        test_type: '',
       
      };
    },
  
    beforeMount(){

    },
  
    mounted(){

        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.phone_number = localStorage.getItem("phone");
        this.email_address = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
  
        this.fetchAllstudents()
        this.fetchcategorys()
        this.generateYearList()
        this.fetchTesttypes()
      
    },
  
    created(){
          
    },
  
    computed: {
      isCategoryEnabled() {
        return (
            this.category_name === '' ||
            this.course_title === '' ||
            this.module_title === ''||
            this.objective_title === '' ||
            this.lesson_title === ''
            //this.description === ''
        );
      },
    },
  
    methods: {
      generateYearList() { 
            const currentYear = new Date().getFullYear();
            const startYear = 1970;

            for (let year = currentYear; year >= startYear; year--) {
               // years.push(year);
                this.programmeYears.push({
                    text: year,
                    value: year
                })
            }
        },
        async fetchTesttypes() {
            try {
               const response =  await this.$http.get('/fetch-all-testtype', {
            })

                response.data.tests.forEach(el => {
                    this.testTypes.push({
                        text: el.test_type,
                        value: el.aptitude_test_typeid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },
        async fetchcategorys() {
            try {
               const response =  await this.$http.get('/fetch-all-categories', {
            })

                response.data.categories.forEach(el => {
                    this.categorys.push({
                        text: el.category_name,
                        value: el.programme_categoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async getCourse() {
            try {
                this.courseTitle = []
               const response =  await this.$http.post('/get-courses', {
                    programmeid: this.category_name
                })

                response.data.categories.forEach(el => {
                    this.courseTitle.push({
                        text: el.course_category_title,
                        value: el.course_categoryid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async getModule() {
            try {
                this.moduleTitle = []
               const response =  await this.$http.post('/get-modules', {
                    courseid: this.course_title
                })

                response.data.categories.forEach(el => {
                    this.moduleTitle.push({
                        text: el.course_module_title,
                        value: el.course_moduleid
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchAllStudentsEssays() {
            try {
               this.loading = true
               const response =  await this.$http.post('/fetch-student-essay', {
                programme_year: this.programme_year,
                user_id: this.student_data,
                programme_id: this.category_name,
                test_type: this.test_type,
              })
              this.loading = false
              this.essayLists = response.data.questions
                    
            } catch (err) {
              console.log(err);
          }
        },

        async fetchAllstudents() {
              try {
                const response =  await this.$http.get('/fetch-students', {
              })
                response.data.students.forEach(el => {
                    this.studentData.push({
                        text: el.firstname + ' ' + el.lastname,
                        value: el.user_id
                    })
                });
                    
            } catch (err) {
              console.log(err);
          }
        },

        // async view_lessons(take_courseid) {
        //     try {

        //         this.viewLesson()

        //         const response =  await this.$http.post('/fetch-student-coursebyid', {
        //         take_courseid: take_courseid
        //     })

        //         this.studentsLessonLists = response.data.courses

                    
        //     } catch (err) {
        //       console.log(err);
        //   }
        // },

      

        viewLesson(){
            this.isAddLessons = false,
            this.lesson_lists = true
        },
        addLesson(){
            this.isAddLessons = true,
            this.lesson_lists = false
        }


    }
  
  }
  </script>
  <style lang="scss" scoped>
  :root {
    --font-family: 'Poppins', 'DM Sans';
  }
 .search-input{
    display: flex; 
    flex-direction: row; 
    justify-content: 
    space-between; 
    align-items: center;
 }
  .quill-container {
    height: 200px; /* Set the desired height here */
  }
.inputs{
  margin-top: 20px;
  border-radius: 3px;
  font-size: 12px;
}
.shop-detail{
    font-size: 12px;
    font-family: var(--font-family);
}
.btn-danger{
  text-transform: uppercase;
  font-size: 13px;
  font-family: var(--font-family);
  background: #ff4d63;
  border: 0px;
  height: 45px;
}
  .welcome{
    font-family: var(--font-family);
    font-size: 17px;
    font-weight: 700;
    color: #170e0fac;
  }
  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.9rem;
    margin: 4px;
  }
  .account-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #780712;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: var(--font-family);
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .wallet-detail{
    padding-top: 1rem;
    line-height: 1.4px;
    font-size: 15px;
    h4{
      font-weight: 650;
    }
    .acct-d{
      line-height: 0.8;
      cursor:pointer;
      text-decoration: underline;
    }
  }
  .wallet-icon{
    font-size: 60px
  }
  .fund-account{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #076378;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-family: var(--font-family);
    padding: 0.4rem;
    padding-left: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .inputs-field{
    font-size: 14px;
    font-family: var(--font-family);
    height: 45px;
}
  .content-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 1px #170e0fac;
    border-radius: 4px;
    padding: 0.5rem;
    margin: 4px;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box-icon{
    padding-left: 0.4rem;
    font-size: 35px;
    color:#637807;
  }
  .box-details{
    font-size: 14px;
    color:#170e0fac;
  }
  .box1:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box2:hover{
     transform: scale(1.1); 
     transition: transform 0.3s ease; 
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
     cursor: pointer;
   }
  .box3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box3:hover{
      transform: scale(1.1); 
      transition: transform 0.3s ease;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
      cursor: pointer;
   }
   .box4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box4:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box5:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .box6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: var(--font-family);
  
    a{
      text-decoration: none;
      color: #000;
    }
  }
  .box6:hover{
    transform: scale(1.1); 
    transition: transform 0.3s ease;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
  }
  .img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .box-details{
    padding: 0.4rem;
    line-height: 1.4px;
    .title{
      font-family: var(--font-family);
      font-weight: 550;
      font-size: 10px;
      text-transform: capitalize;
      color: #ff4d63;
    }
    .description{
      font-family: var(--font-family);
      font-size: 12px;
    }
  }
  .continue{
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 0.4rem;
    font-size: 30px;
    color: rgb(237, 138, 108);
    margin-right: 10px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .4s ease;
  
    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: var(--font-family);
    }
  
    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: var(--font-family);
    }
  }
  .account-content{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-right: 8px;
  }
  .account-content-h{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: 50px;   
    margin: 10px;
    padding-top: 1.3rem;
    text-transform: uppercase;
    text-align: center;
    //text-decoration: underline;
  }
  .add-fund{
    font-size: 12px;
    text-decoration: none !important;
    text-transform: capitalize;
  }
  
  .account-content-d{
    padding-top: 1.3rem;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .btn-secondary{
    text-transform: uppercase;
  }
  
  .modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  }
  
  .modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;
  
    &:hover {
        color: black;
    }
  
    display: none;
  }
  
  a {
    text-decoration: none;
  }
  .shop-name{
    
  }
  @media screen and (max-width: 768px) {
    .counter{
      font-size: 13px;
      font-weight: 500 !important;
    }
    .welcome{
      font-family: var(--font-family);
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #170e0fac;
    }
    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.9rem;
    }
    .account-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: solid 1px #170e0fac;
      border-radius: 4px;
      padding: 0.9rem;
      margin: 4px;
    }
    .account-balance{
      margin-bottom: 10px;
    }
  
    .box-details{
      padding: 0.4rem;
      line-height: 1.4px;
      .title{
        font-family: var(--font-family);
        font-weight: 550;
        font-size: 8px;
        text-transform: capitalize;
        color: #ff4d63;
      }
      .description{
        font-family: var(--font-family);
        font-size: 12px;
      }
    }
    
    .inputs{
      font-size: 12px;
    }
    .box1 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box2 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box3 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box4 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .box5 {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .continue{
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-right: 0.4rem;
      font-size: 30px;
      margin-top: -10px;
      color: rgb(237, 138, 108);
    }
  
    .modal-close-mobile {
      display: block;
    }
    .modal-close {
      display: none;
    }
  
    .account-content{ 
      margin: 10px;
      margin-right: 30px;
    }
  
  }
  
  </style>