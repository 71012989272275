<template>
<div class="backgound">
    <div class="container">
        <p>&nbsp;</p>
        <div class="header">
            <div class="logo"><img src="logo.png" alt=""></div>
            <div class="login"><span class="account">Already have an account?</span> <router-link to="/">log in</router-link></div>
        </div>
        <div class="get-started">Access business and financial education,<br> without struggle.</div>
        <div class="login-form">
            <div class="login-form-inner">
                <p>Getting Started</p>
                <div class="progress" style="height: 4px;">
                    <div class="progress-bar bg-danger" role="progressbar" :style="{ width: step_value }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="counter">
                    <p class="step-title">{{ step_title }}</p>
                    <p>{{ step_number }} <span>of 4 Steps</span></p>
                </div>
                <div class="inputs" v-if="step_1">
                    <label for="">What is your highest level of COMPLETED education?</label>
                    <select class="form-control inputs-field" @input="select1" v-model="education_level">
                        <option value="">Select</option>
                        <option value="Doctorate/PhD">Doctorate/PhD</option>
                        <option value="Masters Degree">Masters Degree</option>
                        <option value="Post Graduate Degree">Post Graduate Degree</option>
                        <option value="Bachelor’s Degree">Bachelor’s Degree</option>
                        <!-- <option value="Undergraduate/Degree">Undergraduate/Degree</option> -->
                        <option value="High School Diploma">High School Diploma</option>
                        <option value="Senior Secondary Certificate">Senior Secondary Certificate</option>
                    </select>
                </div>
                <div class="inputs" v-if="step_2">
                    <label for="">How many years of PROVEN work experience do you have?</label>
                    <select class="form-control inputs-field" @input="select2" v-model="work_experience">
                        <option value="">Select</option>
                        <option value="Less than 1 year">Less than 1 year</option>
                        <option value="2-5 years">2-5 years</option>
                        <option value="6-10 years">6-10 years</option>
                        <option value="11+">11+</option>
                    </select>
                    <label style="margin-top:10px" for="">In what areas or field of work?</label>
                    <select id="select_field" class="form-control inputs-field" disabled @input="select4" v-model="work_field">
                        <option value="">Select</option>
                        <option value="Professional Services">Professional Services</option>
                        <option value="Security/force/operations">Security/force/operations</option>
                        <option value="Government Agency">Government Agency</option>
                        <option value="Religious/social organizations">Religious/social organizations</option>
                        <option value="Education/Teaching">Education/Teaching </option>
                        <option value="Trading/Commerce">Trading/Commerce </option>
                        <option value="Technology/Science">Technology/Science </option>
                        <option value="Arts/Humanities">Arts/Humanities  </option>
                    </select>
                </div>
                <div class="inputs" v-if="step_3">
                    <label for="">Why are you interested in Fordax Business School? </label>
                    <select class="form-control inputs-field" @input="select3" v-model="interests">
                        <option value="">Select</option>
                        <option value="To start my own business">To start my own business </option>
                        <option value="To advance my career">To advance my career</option>
                        <option value="To expand my network">To expand my network</option>
                        <option value="Self improvement">Self improvement</option>
                    </select>
                </div>
                <div class="inputs" v-if="step_4"> 
                    <div class="socials">
                        <div class="google form-control"><img src="../assets/google.png" alt=""> &nbsp; google signup</div>&nbsp;
                        <div class="facebook form-control"> <img src="../assets/facebook.png" alt=""> &nbsp; facebook signup</div>&nbsp;
                        <div class="apple form-control"><img src="../assets/apple.png" alt=""> &nbsp; apple signup</div>
                    </div>
                   
                    <div style="display: flex; flex-direction:row; font-size: 13px; align-items: center;"><hr width="200px"> OR  <hr width="200px"></div>
                    <label for="">Firstname *</label>
                    <input type="text" id="frstname" class="form-control inputs-field" placeholder="Enter Firstname" v-model="firstname" required>
                    <label for="">Lastname *</label>
                    <input type="text" id="lastname" class="form-control inputs-field" placeholder="Enter Lastname" v-model="lastname" required>
                    <label for="">Email *</label>
                    <input type="email" id="email" class="form-control inputs-field" placeholder="Enter Email address" v-model="email_address" required>
                    <label for="">WhatsApp number</label>
                    <div style="display: flex; flex-direction: row">
                        <vue-country-code
                            @onSelect="onSelect"
                           >
                        </vue-country-code> &nbsp;
                        <input type="text" id="phone" class="form-control inputs-field" placeholder="Enter Phone number" v-model="phone_number" required>
                    </div>
                    <label for="">Password *</label>
                    <div>
                        <input type="password" id="show_text" class="form-control inputs-field" placeholder="Enter password" v-model="password" required>
                    </div>
                    <span class="p-viewer" @click="showText">
                        <i class="fa fa-eye" aria-hidden="true" id="eye_close"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" id="eye_open" style="display:none"></i>
                    </span>
                </div>
                <div class="inputs" v-if="submit_1">
                    <button id="submit1" class="btn btn-danger btn-block" disabled  @click="submit1()">next step</button>
                </div>
                <div class="inputs" v-if="submit_2">
                    <button id="submit2" class="btn btn-danger btn-block" disabled @click="submit2()">next step</button>
                    <button id="submit1" class="btn btn-dark btn-block"  @click="previous1()"><i class="fa fa-arrow-left"></i> prevous</button>
                </div>
                <div class="inputs" v-if="submit_3">
                    <button id="submit3" class="btn btn-danger btn-block" disabled @click="submit3()">next step</button>
                    <button id="submit1" class="btn btn-dark btn-block"  @click="previous2()"><i class="fa fa-arrow-left"></i> prevous</button>
                </div>
                <div class="inputs" v-if="submit_4">
                    <button id="submit4" class="btn btn-danger btn-block" :disabled="isSubmitButtonDisabled" @click="submit_form()" v-if="!loading">Submit</button>
                    <button class="btn btn-danger btn-block" type="button" disabled v-if="loading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>
                    <button id="submit1" class="btn btn-dark btn-block"  @click="previous3()"><i class="fa fa-arrow-left"></i> prevous</button>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="copyright">
            <span>©2023 Fordax Business School. All rights reserved.</span>
            <span>Terms· Privacy· Cookies· Regulatory Information</span>
        </div>
    </div>
</div>
</template>
<script>
import Vue from 'vue'
import VueCountryCode from "vue-country-code";
import swal from 'sweetalert2';

Vue.use(VueCountryCode);

export default {
    
    name: 'SignupPage',
    components: {

    },
    
    data() {
    return {
        appTitle: "Fordax Business School",
     
      stepTitle: [
        {
            step1: "Educational Level",
            step2: "Work Experience",
            step3: "Your Motivation",
            step4: "Sign Up",
        }
      ],
      stepValues: [
        {
            value1: "25%",
            value2: "50%",
            value3: "75%",
            value4: "100%",
        }
      ],
      step_1: true,
      step_2: false,
      step_3: false,
      step_4: false,
      step_number: 1,
      step_title: '',
      step_value: '',
      submit_1: true,
      submit_2: false,
      submit_3: false,
      submit_4: false,
      education_level: "",
      work_experience: "",
      work_field: "",
      interests: "",
      firstname: '',
      lastname: '',
      email_address: '',
      phone_number: '',
      password: '',
      phone_number_code: '',
      loading: false,
    };
  },

  beforeMount(){
    document.title = this.appTitle
    this.step_title = this.stepTitle[0].step1
    this.step_value = this.stepValues[0].value1
  },
  mounted() {
   
  },
  created(){
    
  },
  computed: {
    isSubmitButtonDisabled() {
    return (
      this.firstname === '' ||
      this.lastname === '' ||
      this.email_address === '' ||
      this.password === ''
    );
  }
  },

  methods:{

    showText(){
            const input =  document.getElementById('show_text');
            if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
                  document.getElementById('eye_close').style.display="none";
                  document.getElementById('eye_open').style.display="block";
                } else {
                input.setAttribute("type", "password");
                  document.getElementById('eye_close').style.display="block";
                  document.getElementById('eye_open').style.display="none";
                }
    },
    onSelect({name, iso2, dialCode}) {
       console.log(name, iso2, dialCode);
       this.phone_number_code = dialCode
     },

    select1(){
        if (this.education_level == "") {
            document.getElementById("submit1").disabled = false
            return;
        }

        document.getElementById("submit1").disabled = true
    },

    select2(){
        if (this.work_experience == "") {
            document.getElementById("select_field").disabled = false
            return;
        }
        document.getElementById("select_field").disabled = true
    },

    select3(){
        if (this.interests == "") {
            document.getElementById("submit3").disabled = false
            return;
        }
        document.getElementById("submit3").disabled = true
    },

    select4(){
        if (this.work_field == "") {
            document.getElementById("submit2").disabled = false
            return;
        }
        document.getElementById("submit2").disabled = true
    },

    submit1() {
        if (this.step_1 == true) {
            this.step_2 = true
            this.step_1 = false
            this.step_3 = false
            this.submit_1 = false
            this.submit_2 = true
            this.step_number = 2
            this.step_title = this.stepTitle[0].step2
            this.step_value = this.stepValues[0].value2
        }
    },

    submit2() {
        if (this.step_2 == true) {
            this.step_2 = false
            this.step_3 = true
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = true
            this.step_number = 3
            this.step_title = this.stepTitle[0].step3
            this.step_value = this.stepValues[0].value3
        }
    },
     previous1() {
        if (this.step_2 == true) {
            this.step_2 = false
            this.step_3 = false
            this.step_1 = true
            this.submit_1 = true
            this.submit_2 = false
            this.submit_3 = false
            this.step_number = 1
            this.step_title = this.stepTitle[0].step1
            this.step_value = this.stepValues[0].value1
            this.education_level = ""
        }
    },

    submit3() {
        if (this.step_3 == true) {
            this.step_4 = true
            this.step_2 = false
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = false
            this.submit_4 = true
            this.step_number = 4
            this.step_title = this.stepTitle[0].step4
            this.step_value = this.stepValues[0].value4
        }
    },
    previous2() {
        if (this.step_3 == true) {
            this.step_4 = false
            this.step_2 = true
            this.step_3 = false
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = true
            this.submit_3 = false
            this.submit_4 = false
            this.step_number = 2
            this.step_title = this.stepTitle[0].step2
            this.step_value = this.stepValues[0].value2
            this.work_experience = ""
            this.work_field = ""
        }
    },

    previous3() {
        if (this.step_4 == true) {
            this.step_4 = false
            this.step_2 = false
            this.step_3 = true
            this.step_1 = false
            this.submit_1 = false
            this.submit_2 = false
            this.submit_3 = true
            this.submit_4 = false
            this.step_number = 3
            this.step_title = this.stepTitle[0].step3
            this.step_value = this.stepValues[0].value3
            this.interests = ""
        }
    },

    async submit_form() {
            try {

                this.loading = true

               const response = await this.$http.post('/create-user', {
                  education_level: this.education_level,
                  work_experience: this.work_experience,
                  work_field: this.work_field,
                  interests: this.interests,
                  firstname: this.firstname,
                  lastname: this.lastname,
                  email_address: this.email_address,
                  phone_number: this.phone_number,
                  phone_number_code: this.phone_number_code,
                  password: this.password
                })

                this.loading = false

                if( !response.data.success ){
                    swal.fire({
                        icon: 'error',
                        title: 'Error',
                        width: 450,
                        height: 5,
                        text: response.data.message,
                    })
                    return;
                }

                this.$router.push('/dashboard')
                await localStorage.setItem("user_role", response.data.profile[0].user_role)
                swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    width: 450,
                    height: 5,
                    text: response.data.message,
                })
                return;
               
            } catch (err) {
              console.log(err);
        }
    },

  }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }

  .p-viewer, .p-viewer2{
    float: right;
    margin-top: -26px;
    margin-right: 10px;
    position: relative;
    z-index: 1;
    cursor:pointer;
  }
    .backgound{
        //background: url(../assets/background.jpeg) no-repeat;
        background-image: linear-gradient(to bottom, #ff4d63, rgba(237, 221, 221, 0.044));
        background-size: cover;
        font-family: var(--font-family) !important;
    }
  
    .container{
        display: flex;
        flex-direction: column;
    }
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        padding: 10px;
        text-transform:uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        font-family: var(--font-family);
        .logo{
            img{
                height: 50px;
                width: auto;
            }
        }

        a{
            color: #504e4e;
            text-decoration: none;
            font-weight: 700;
        }
    }

    .get-started{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 20px;
        font-family: var(--font-family);
        font-size: 25px;
        font-weight: 600;
        word-wrap: break-word;
        text-align: center;
        color: #fff;
    }
    .login-form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .socials{
        display: flex;
        flex-direction: row;
    }
    .google{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 60%;
        text-transform: capitalize;
        background: #4478f2;
        color:#fff;
        font-size: 9px;
        font-weight: 620;
        margin-bottom: 4px;

        img{
            width:20px
        }
    }
    .facebook{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 60%;
        text-transform: capitalize;
        background: #4478f2;
        color:#fff;
        font-size: 9px;
        font-weight: 620;
        margin-bottom: 4px;
        
        img{
            width:20px
        }
    }
    .apple{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 60%;
        text-transform: capitalize;
        background: #000;
        color:#fff;
        font-size: 9px;
        font-weight: 620;
        margin-bottom: 10px;

        img{
            width:20px
        }
    }
    .login-form-inner{
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
        padding: 2rem;
        width: 40%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: var(--font-family);
        font-size: 13px;
        font-weight: 500;
        color: #504e4e !important;
    }

    .counter{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        padding: 4px;
        height: 30px;
        border-radius: 3px;

        span{
            color: #ccc;
        }
        .step-title{
            font-weight: 700;
        }
    }
    .inputs{
        margin-top: 20px;
        border-radius: 3px;
    }
    .inputs-field{
        font-size: 13px;
        font-family: var(--font-family);
    }
    .btn-danger{
        text-transform: uppercase;
        font-size: 13px;
        font-family: var(--font-family);
    }
    .btn-dark{
        text-transform: uppercase;
        font-size: 13px;
        font-family: var(--font-family);
    }

    .footer{
        display: flex;
        flex-direction: column;
        background: #cccccc3b;
       // padding: 1.5rem;
    }
    .copyright{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: var(--font-family);
        font-size: 13px;
        color: #171616ec;
       // background: #cccccc3b;
        padding: 0.3rem;
        width: 100%;
        margin-top: 40px;
    }

    @media screen and (max-width: 768px) {

        .header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            font-size: 10px;
            width: 100%;
    
            .logo{
                img{
                    height: 35px;
                    width: auto;
                }
            }
        }
        .login{
            font-weight: 800;
            color: #000 !important;
        }
        .account{
            display: none;
        }
        .login-form-inner{
            width: 100%;
            font-size: 10px;
        }

        .get-started{
            font-size: 14px;
        }

        .google{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            text-transform: capitalize;
            background: #4478f2;
            color:#fff;
            font-size: 7px;
       
            img{
                width:10px
            }
        }
        .facebook{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            text-transform: capitalize;
            background: #4478f2;
            color:#fff;
            font-size: 7px;
            
            img{
                width:10px
            }
        }
        .apple{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            text-transform: capitalize;
            background: #000;
            color:#fff;
            font-size: 7px;
    
            img{
                width:10px
            }
        }
        .copyright{
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: var(--font-family);
            font-size: 10px;
            color: #171616ec;
            background: #cccccc3b;
            padding: 0.3rem;
            width: 100%;
            margin-top: 40px;
        }
    }
</style>