<template>
    <div>
        <!-- top navbar -->
        <nav class="navbar navbar-expand-lg
        navbar-light navbar-bgcolor">
            <a class="navbar-brand" href="#"><img src="logox.png" alt="">Fordax Business School</a>
            <!-- hamburger button that toggles the navbar-->
            <button class="navbar-toggler" type="button"
                data-toggle="collapse" data-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">
                </span>
            </button>
            <!-- navbar links -->
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div class="navbar-nav">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Programs
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="#">MBA Fast Track</a>
                        <a class="dropdown-item" href="#">Certificates Courses</a>
                        <a class="dropdown-item" href="#">Advance Courses</a>
                    </div>
                    <a class="nav-item nav-link" href="#">Network</a>
                    <a class="nav-item nav-link" href="#">Resources</a>
                    <a class="nav-item nav-link" href="#">Events</a>
                    <!-- <a class="nav-item nav-link" href="#">Notifications</a> -->
                    <a class="nav-item nav-link" href="#">Help</a>
                    <!-- <a class="nav-item nav-link" href="#">Settings</a> -->
                </div>
            </div>
            <div class="dropdown">
                <button class="dropbtn avater"> {{ firstname }} {{ lastname }} <span>{{ name_initial }}</span></button>
                <div class="dropdown-content" v-if="user_role == 1">
                  <router-link to="/app-request">Application Request</router-link> 
                  <router-link to="/view-applications">Applications</router-link> 
                  <router-link to="/programme-category">Programme Category</router-link>
                  <router-link to="/test-type">Test Type</router-link> 
                  <router-link to="/test-questions">Test Questions</router-link> 
                  <router-link to="/essay-questions">Essay Questions</router-link> 
                  <router-link to="/create-course-category">Add Course Category</router-link> 
                  <router-link to="/create-course-module">Add Course Module</router-link> 
                  <router-link to="/add-lessons">Add Lessons</router-link>
                  <router-link to="/add-lessons-objective">Add Lessons Objective</router-link>  
                  <router-link to="/students-courses">Student Courses</router-link>  
                  <router-link to="/essay-writing">Essay Writing</router-link>  
                  <a @click="log_out">Logout</a>
                </div>
                <div class="dropdown-content" v-if="user_role == 2">
                    <router-link  to="/fees">Fees</router-link >
                    <router-link  to="#">Notifications</router-link >
                    <router-link  to="#">Settings</router-link >
                    <a @click="log_out">Logout</a>
                  </div>
              </div>
        </nav>
       
        <div class="container">
            <router-view />
        </div>

        <div class="footer">
            <!-- Preference | Home | Account | Page | E-Library | Events -->
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br> 
            &copy;2023 Fordax Business School
        </div>
    </div>
</template>
<script>
export default {

    name: "Dashboard",
    components: {

    },

    data(){
        return {
            appTitle: 'Fordax Business School ',
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_id: '',
            admission_status: '',
            user_role: '',
        };
    },
    
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");
        this.user_role = localStorage.getItem("user_role");

        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }

    },
    created(){
        
    },
    computed: {
        
    },
    methods:{

        async log_out(){
            let con = confirm("         You're about to logout, do you want to continue?")
            if (con == true) {
                localStorage.clear();

                this.$router.push('/')
                
            }
        }
       
    }
}
</script>
<style lang="scss" scoped>
:root {
    //--font-family: 'Poppins', 'DM Sans';
    --font-family: 'DM Sans';
}

/* Style The Dropdown Button */
.dropbtn {
   // background-color: #4CAF50;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000;
    color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(40, 37, 37, 0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: rgba(113, 109, 109, 0.281);
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    border-bottom: solid 1px #ccc;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ff4d6285}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
   // background-color: #3e8e41;
  }
#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: var(--font-family);
}
.dropdown-menu {
    margin-left: 250px;
    font-family: var(--font-family) !important;
    font-size: 18px;
    background: #170e0fac;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}
.navbar-bgcolor{
    background: #ff4d63;
    color: #fff;
    font-family: var(--font-family);
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);

    a{
        color: #fff;
        text-decoration: none;
        font-size: 13px;

        img{
            width: 45px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}
.avater{

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}
.footer{
    flex-shrink: 0;
    background: #f6f1f2;
    font-family: var(--font-family);
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #0d0d0d;
    padding: 10px;
    text-align: center;
    //position: absolute;
    //bottom: 0;
    height: 50px;
    width: 100%;
    font-size: 12px;
}

@media screen and (max-width: 768px) { 
    .dropdown-menu {
        margin-left: 0;
        font-family: var(--font-family) !important;
        font-size: 16px;

    }
    .dropdown {
        position: relative;
        display: inline-block;
      }
    .dropdown:hover .dropdown-content {
    display: block;
    }
    .avater{
        display: flex;
    }
}

</style>