<template>
    <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
               
        <div style="display: flex; flex-direction:row; font-size: 20px; font-weight:800; color:#ccc; align-items: center; justify-content: center"><hr width="90%"> COURSES  <hr width="90%"></div>
        <router-link to="/dashboard">
            <div>
                &nbsp;&nbsp;<i class="fa fa-arrow-left" aria-hidden="true"></i> BACK
            </div>
        </router-link>
        <div class="row course-module">
           
            <div class="col-sm-6" v-for="(data, index) in programme_modules" :key="index">
                <div class="modules">
                    <div class="module-title"><span><i class="fa fa-lock"></i></span> {{ data.course_module_title}}</div>
                    <hr>
                    <p class="module-content">
                        {{ data.course_module_desc }}
                    </p>
                    <div class="module-footer"> 
                        <div class="lesson-count">
                           <div class="lessons-number"> {{ data.lessonCount }} </div>
                           <div class="module-lessons"> 
                            &nbsp;&nbsp;LESSONS
                           </div>
                        </div>
                        <div v-if="data.is_payment_success == null">
                            <router-link :to="'/course-detail/' + data.course_moduleid">
                                <div class="start-course">
                                    <button class="start price">{{ new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      }).format(data.course_price) }} </button>   <button class="start">Enrol</button>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="data.is_payment_success == 1">
                            <router-link :to="'/course/' + data.course_moduleid">
                                <div class="start-course">
                                    <button class="start">start </button>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <page-loader :is-loading="isLoading"></page-loader>
    </div>
</template>
<script>
import PageLoader from '../components/PageLoader.vue'
export default {
    name: "CertificateCourse",
    components: {
        PageLoader,
    },

    data(){
        return{
            isLoading: false,
            programme_modules: '',
            programmeId: '',
            user_id: '',
        }
    },
    mounted() {
        this.user_id = localStorage.getItem("userid");
        this.programmeId = this.$route.params.title
        this.fetch_programme_courses(this.user_id, this.$route.params.title)
    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
        async fetch_programme_courses(userid, programmeid){
        this.isLoading = true;
        const response = await this.$http.post('/fetch-certificate-modules-byid', {
            userid: userid,
            programme_id: programmeid
        })
        this.isLoading = false;
        this.programme_modules = response.data.modules;

        },
    }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }

    a{
        text-decoration: none;
        color: #ff4d63;
    }
    .course-title{
        padding: 0.9rem;
        font-family: var(--font-family);
        font-size: 22px;
        font-weight: bold;
        margin-top: 20px;
        text-transform: uppercase;
    }
    .course-module{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        padding: 0.9rem;
    }
    .modules{
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: var(--font-family);
        margin-bottom: 10px;
    }
  
    .module-title{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        font-family: var(--font-family);
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        color: #ff4d63;

        span{
            //font-size: 50px;
            color: white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            width: 30px;
            height: 30px;
            background: #ff4d63;
            border-radius: 47px;
            margin: 10px;

        }

    }
    .module-content{
        text-align: justify;
        font-family: var(--font-family);
        font-size: 13px;
        margin: 10px;
    }
    .module-footer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: var(--font-family);
        background: #ff4d63;
        width: 100%;
        height: 65px;
        padding: 0.4rem;
        a{
            color: #fff;
        }
    }
    .lesson-count{ 
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .module-lessons{
        display: flex;
        flex-direction: row;
        justify-content: left;
        font-size: 12px;
        color: #fff;
        font-weight: 800;
    }
    .module-footer-blue{
        display: flex;
        flex-direction: column;
        justify-content: left;
        font-family: var(--font-family);
        background: #4d59ff;
        width: 100%;
        height: 65px;
        padding: 0.4rem;
        .module-lessons{
            font-size: 12px;
            color: #fff;
            font-weight: 800;
        }
    }
    .start-course{
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
       
    }
    .start{
        height: 30px;
        width: auto;
        border-radius: 4px;
        border: 1px #fff solid;
        text-transform: capitalize;
        margin: 4px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .price{
        background: #fff;
        color: #ff4d63;
    }
    .lessons-number{
        color: #ff4d63;
        font-weight: 800;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width:40px;
        height: 40px;
        background: #fff;
        border-radius: 47px;
        font-size: 20px;
    }
</style>