<template>
    <div>
        <!-- top navbar -->
        <!-- <nav class="navbar navbar-expand-lg
        navbar-light navbar-bgcolor">
          
           
        </nav> -->
       
        <div class="container">
            <router-view />
        </div>

        <!-- <div class="footer">
            Preference | Home | Account | Page | E-Library | Events
            <hr>
            Follow us on: &nbsp; <i class="fa fa-linkedin-square"></i> &nbsp; <i class="fa fa-facebook-square" aria-hidden="true"></i> &nbsp; <i class="fa fa-youtube-square"></i>
            <br> 
            &copy;2023 Fordax Business School
        </div> -->
    </div>
</template>
<script>
export default {

    name: "LessonDashboard",
    components: {

    },

    data(){
        return {
            appTitle: 'Fordax Business School ',
            firstname: '',
            lastname: '',
            email: '',
            name_initial: '',
            user_id: '',
            admission_status: '',
        };
    },
    
    beforeMount(){
        document.title = this.appTitle
    },
    mounted() {
        this.firstname = localStorage.getItem("firstname");
        this.lastname = localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");
        this.user_id = localStorage.getItem("userid");

        if (this.firstname) {
        this.name_initial = this.firstname.charAt(0);
        } else {
        this.name_initial = ''; // Handle the case where firstname is empty
        }

    },
    created(){
        
    },
    computed: {
        
    },
    methods:{
       
    }
}
</script>
<style lang="scss" scoped>
:root {
    --font-family: 'Poppins', 'DM Sans';
  }

#green {
    height: 100%;
    background: #fff;
    text-align: center;
    color: black;
    font-family: var(--font-family);
}
.dropdown-menu {
    margin-left: 250px;
    background: #170e0fac;

    .dropdown-item:hover{
        color: #ff4d63;
        font-weight: 650;
    }
}
.navbar-bgcolor{
    background: #ff4d63;
    color: #fff;
    font-family: var(--font-family);
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    height: 40px;

    a{
        color: #fff;
        text-decoration: none;
        font-size: 13px;

        img{
            width: 45px;
        }
    }
    a:hover{
        color: #fff;
    }
}
.navbar-brand{
    font-weight: 700;
    font-size: 14px !important;
}
.nav-link:hover{
    background: #f1b3ac;
    border-radius: 4px;
}
.avater{

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;

    span{
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        background: #312F33;
        border-radius: 47px;
        margin: 10px;
    }
}
.footer{
    flex-shrink: 0;
    background: #f6f1f2;
    font-family: var(--font-family);
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #0d0d0d;
    padding: 10px;
    text-align: center;
    //position: absolute;
    //bottom: 0;
    height: 120px;
    width: 100%;
    font-size: 12px;
}

@media screen and (max-width: 768px) { 
    .dropdown-menu {
        margin-left: 0;

    }
    .avater{
        display: none;
    }
    
    .footer{
        flex-shrink: 0;
        background: #f6f1f2;
        font-family: var(--font-family);
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
        color: #0d0d0d;
        padding: 10px;
        text-align: center;
        position: absolute;
        bottom: 0;
        height: 120px;
        width: 100%;
        font-size: 12px;
    }
}

</style>